import Actions from 'campaigns/views/alerts/actions';

import template from 'templates/campaign_alerts/header_items.pug';

class Header extends Marionette.LayoutView {
  static initClass() {
    this.prototype.template = template;

    this.prototype.regions = { actions_region: '.actions' };

    this.prototype.tagName = 'a';

    this.prototype.modelEvents = { 'remove change': 'render' };
  }

  templateHelpers() {
    return {
      isCollapsed: () => this.is_collapsed,
      alertsCount: () => this.model.length
    };
  }

  initialize(options = {}) {
    return (this.end_date = options.end_date);
  }

  onRender() {
    return this.actions_region.show(
      new Actions({
        model: this.model,
        end_date: this.end_date,
        titles: {
          delete: I18n.t('campaigns.form.alerts.delete_all'),
          snooze: I18n.t('campaigns.form.alerts.snooze_all'),
          unsnooze: I18n.t('campaigns.form.alerts.unsnooze_all'),
          delete_confirm: I18n.t('campaigns.form.alerts.delete_all_confirm')
        }
      })
    );
  }
}
Header.initClass();

export default Header;
