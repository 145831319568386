var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, distribution, exposure_type, offer_ids, offer_ids_disabled) {var is_distributed = distribution != null
var is_standard = exposure_type === 'STANDARD'
var groups = [0]
if (is_distributed) {
groups = distribution.groups
}
if (is_standard) {
pug_html = pug_html + "\u003Cdiv class=\"offer-selector-row distributed standard\"\u003E";
if (is_distributed) {
pug_html = pug_html + "\u003Clabel\u003E" + (pug.escape(null == (pug_interp = I18n.t('campaigns.form.distributed_offer_selector', { letter: 'A', size: groups[0] })) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"standard-offers\"\u003E";
// iterate offer_ids
;(function(){
  var $$obj = offer_ids;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var offer = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"standard-offer\"\u003E\u003Cdiv class=\"offer-selector\"\u003E\u003Cinput" + (" class=\"offer-selector\""+pug.attr("value", offer, true, true)+pug.attr("disabled", offer_ids_disabled, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var offer = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"standard-offer\"\u003E\u003Cdiv class=\"offer-selector\"\u003E\u003Cinput" + (" class=\"offer-selector\""+pug.attr("value", offer, true, true)+pug.attr("disabled", offer_ids_disabled, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
// iterate groups
;(function(){
  var $$obj = groups;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var group = $$obj[index];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["offer-selector-row",{distributed: is_distributed}], [false,true]), false, true)) + "\u003E";
if (is_distributed) {
var letter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index]
pug_html = pug_html + "\u003Clabel\u003E" + (pug.escape(null == (pug_interp = I18n.t('campaigns.form.distributed_offer_selector', { letter: letter, size: group })) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"offer-selector\"\u003E\u003Cinput" + (" class=\"offer-selector\""+pug.attr("value", offer_ids[index], true, true)+pug.attr("disabled", offer_ids_disabled, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var group = $$obj[index];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["offer-selector-row",{distributed: is_distributed}], [false,true]), false, true)) + "\u003E";
if (is_distributed) {
var letter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index]
pug_html = pug_html + "\u003Clabel\u003E" + (pug.escape(null == (pug_interp = I18n.t('campaigns.form.distributed_offer_selector', { letter: letter, size: group })) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"offer-selector\"\u003E\u003Cinput" + (" class=\"offer-selector\""+pug.attr("value", offer_ids[index], true, true)+pug.attr("disabled", offer_ids_disabled, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

}
if (is_distributed && distribution.control_group > 0) {
pug_html = pug_html + "\u003Cdiv class=\"offer-selector-row distributed\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('campaigns.form.unexposed', { size: distribution.control_group })) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"distribution" in locals_for_with?locals_for_with.distribution:typeof distribution!=="undefined"?distribution:undefined,"exposure_type" in locals_for_with?locals_for_with.exposure_type:typeof exposure_type!=="undefined"?exposure_type:undefined,"offer_ids" in locals_for_with?locals_for_with.offer_ids:typeof offer_ids!=="undefined"?offer_ids:undefined,"offer_ids_disabled" in locals_for_with?locals_for_with.offer_ids_disabled:typeof offer_ids_disabled!=="undefined"?offer_ids_disabled:undefined));;return pug_html;};
module.exports = template;