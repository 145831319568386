import Routes from '../../helpers/routes';
import BudgetPlan from '../../models/budget_plan';
import Campaign from '../../models/campaign';
import ProgressView from './spent/progress';

import loadingTemplate from '../../templates/spent/loading.pug';

class CampaignSpentView extends Marionette.ItemView<BudgetPlan> {
  private view?: Backbone.View<BudgetPlan>;
  private campaign?: Campaign;
  private options: { campaign?: Campaign } = {};

  getTemplate() {
    return loadingTemplate;
  }

  initialize() {
    this.campaign = this.options['campaign'];

    this.render();
  }

  async onRender() {
    const spent = (await this.fetchModelAndStats()).spent;

    this.view = new ProgressView({
      model: this.model,
      el: this.el,
      activeCampaignId: this.campaign!.get('id'),
      activeCampaignSpent: spent
    });

    this.view!.render();
  }

  private fetchModelAndStats() {
    const statsPromise = this.fetchSpendStats();
    const modelPromise = this.fetchModel();

    return $.when(statsPromise, modelPromise);
  }

  // Fetch the budget plan model
  private fetchModel() {
    if (this.model.get('was_live')) {
      const url = Routes.provider_budget_plan_path({
        id: this.model.get('id'),
        provider_id: this.campaign!.get('provider_id')
      });

      return this.model.fetch({ url });
    }

    return $.Deferred().resolve().promise();
  }

  // Fetch the spent stats for the current campaign
  private async fetchSpendStats(): Promise<{ spent: number }> {
    const campaignId = this.campaign!.get('id');
    const providerId = this.campaign!.get('provider_id');

    return $.get(
      `/api/providers/${providerId}/campaigns/${campaignId}/spend-stats`
    ).promise();
  }
}
export default CampaignSpentView;
