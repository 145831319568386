import 'Select2';
import _ from 'underscore';
import template from '../../../templates/campaign_tags/item.pug';

const Item = Marionette.ItemView.extend({
  template,
  tagName: 'tr',
  className: 'tag-item row',

  ui: {
    remove_button: '.remove-item a',
    tag_id_input: '.tag-id',
    tag_values_input: '.tag-values'
  },

  events: {
    'click  @ui.remove_button': 'onRemove',
    'change @ui.tag_id_input': 'onIdChange',
    'change @ui.tag_values_input': 'onValuesChange'
  },

  serializeData() {
    return _.extend(this.model.toJSON(), {
      available_tags: this.options.available_tags,
      values_csv: this.model.get('values').join(',')
    });
  },

  onRender() {
    this._initTagValuesInput(this.model.get('tag'));
  },

  _initTagValuesInput(tagId) {
    const tagForId = this.options.available_tags.find(tag => tag.id == tagId);
    const values = tagForId ? tagForId.values : [];
    this.ui.tag_values_input.select2({
      data: values.map(value => ({ id: value, text: value })),
      multiple: true,
      closeOnSelect: false
    });
    // Clean out invalid values selected for a previous tag.
    const oldValues = this.ui.tag_values_input.select2('val');
    this.ui.tag_values_input.select2(
      'val',
      oldValues.filter(value => values.includes(value)),
      true
    );
  },

  onRemove(e) {
    e.preventDefault();
    this.model.collection.remove(this.model);
  },

  onIdChange(e) {
    const tagId = +this.ui.tag_id_input.val();
    this.model.set('tag', tagId);

    this.ui.tag_values_input.select2('destroy');
    this._initTagValuesInput(tagId);
  },

  onValuesChange(e) {
    this.model.set('values', this.ui.tag_values_input.select2('val'));
  }
});

export default Item;
