import _ from 'underscore';
import Campaign from 'models/campaign';
import BudgetPlan from 'models/budget_plan';

import FormLayout from 'campaigns/views/form_layout';

function parsePinsAssociationValue(campaignJSON) {
  if (campaignJSON.all_pins) {
    return 'all';
  } else if (campaignJSON.only_points) {
    return 'only_points';
  } else if (campaignJSON.only_polygons) {
    return 'only_polygons';
  } else if (_.isEmpty(campaignJSON.pins_by_location_tags)) {
    return 'by_ids';
  } else {
    return 'by_tags';
  }
}

function parseCampaignJSON(campaignJSON) {
  const extendedAttributes = {
    pins_association: parsePinsAssociationValue(campaignJSON),
    traffic_target: (campaignJSON.traffic_target || { levels: [] }).levels
  };

  return _.extend({}, campaignJSON, extendedAttributes);
}

class App {
  constructor(options) {
    this.campaign = new Campaign(parseCampaignJSON(options.data.campaign));

    this.layout = new FormLayout({
      el: options.el,
      model: this.campaign,
      budgetPlans: options.data.budgetPlans,
      emptyBudgetPlan: options.data.emptyBudgetPlan,
      currentBudgetPlan: new BudgetPlan(options.data.currentBudgetPlan, {
        parse: true
      }),
      distributions: options.data.distributions,
      channelsWithoutPrice: options.data.channelsWithoutPrice,
      earliestTimezoneOffsetMs: options.data.earliestTimezoneOffsetMs,
      latestTimezoneOffsetMs: options.data.latestTimezoneOffsetMs,
      firstLookCpm: options.data.firstLookCpm,
      providerAccountType: options.data.providerAccountType
    });

    this.layout.render();
  }
}

export default App;
