var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, Routes, model) {pug_html = pug_html + "\u003Ctable class=\"table table-condensed\"\u003E\u003Cthead\u003E\u003Ctr\u003E\u003C\u002Ftr\u003E\u003C\u002Fthead\u003E\u003Ctbody\u003E\u003Cth\u003E" + (pug.escape(null == (pug_interp = I18n.t('budget_plans.index.id')) ? "" : pug_interp)) + "\u003C\u002Fth\u003E\u003Cth\u003E" + (pug.escape(null == (pug_interp = I18n.t('budget_plans.index.name')) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
// iterate model.campaigns
;(function(){
  var $$obj = model.campaigns;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var campaign = $$obj[pug_index0];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", Routes.provider_campaign_path({ provider_id: model.provider_id, id: campaign.id }), true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = campaign.id) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = campaign.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var campaign = $$obj[pug_index0];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", Routes.provider_campaign_path({ provider_id: model.provider_id, id: campaign.id }), true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = campaign.id) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = campaign.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"Routes" in locals_for_with?locals_for_with.Routes:typeof Routes!=="undefined"?Routes:undefined,"model" in locals_for_with?locals_for_with.model:typeof model!=="undefined"?model:undefined));;return pug_html;};
module.exports = template;