import 'Select2';
import { Provider } from '../angular/store/entity/provider';

export type ItemSelectorOptions<T extends Backbone.Model> =
  Backbone.ViewOptions<T> &
    Select2Options & {
      data?: Array<{ text: string } & {}>;
      initialSelection?: T;
      provider?: Provider;
    };

class ItemSelector<T extends Backbone.Model> extends Marionette.ItemView<T> {
  template!: Function | boolean;
  options!: ItemSelectorOptions<T>;

  constructor(options: ItemSelectorOptions<T> = {}) {
    super(options);
  }

  static initClass() {
    this.prototype.template = false;

    this.prototype.triggers = {
      change: {
        event: 'change',
        stopPropagation: false
      }
    };
  }

  initialize(options: ItemSelectorOptions<T> = {}) {
    this.options = options;
    this.$el.select2(this.options);
    this.render();
  }

  getSelected() {
    return this.$el.select2('data');
  }

  onRender() {
    if (
      !this.options.allowClear &&
      this.options.data &&
      this.options.data.length === 1
    ) {
      return this.$el.select2('val', this.options.data[0].id);
    }
  }
}
ItemSelector.initClass();

export default ItemSelector;
