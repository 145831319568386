var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, explain, frequency, name, schedule, type) {pug_html = pug_html + "\u003Cdiv class=\"list-group-item-title\"\u003E\u003Ch5 class=\"list-group-item-heading\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fh5\u003E";
if (type == 'excel') {
pug_html = pug_html + "\u003Ci class=\"fa fa-file-excel-o fa-fw\"\u003E\u003C\u002Fi\u003E";
}
else {
pug_html = pug_html + "\u003Ci class=\"fa fa-file-powerpoint-o fa-fw\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"list-group-item-text\"\u003E\u003Cdiv class=\"schedule\"\u003E";
if (schedule) {
explain = I18n.t(frequency, { scope: 'reports.index.scheduled_reports.list.frequency' })
pug_html = pug_html + (pug.escape(null == (pug_interp = I18n.t('reports.index.scheduled_reports.list.schedule', { frequency: explain })) ? "" : pug_interp));
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"actions\"\u003E\u003Ca class=\"email\" href=\"#\"\u003E\u003Ci class=\"fa fa-envelope\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.index.scheduled_reports.list.email')) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
if (type == 'excel') {
pug_html = pug_html + "\u003Ca class=\"export\" href=\"#\"\u003E\u003Ci class=\"fa fa-file-excel-o\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.index.scheduled_reports.list.export_excel')) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Ca class=\"edit\" href=\"#\"\u003E\u003Ci class=\"fa fa-pencil\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.index.scheduled_reports.list.edit')) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Ca class=\"remove\" href=\"#\"\u003E\u003Ci class=\"fa fa-remove\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.index.scheduled_reports.list.remove')) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"explain" in locals_for_with?locals_for_with.explain:typeof explain!=="undefined"?explain:undefined,"frequency" in locals_for_with?locals_for_with.frequency:typeof frequency!=="undefined"?frequency:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"schedule" in locals_for_with?locals_for_with.schedule:typeof schedule!=="undefined"?schedule:undefined,"type" in locals_for_with?locals_for_with.type:typeof type!=="undefined"?type:undefined));;return pug_html;};
module.exports = template;