import FormApp from 'campaigns/form';
import ListApp from 'campaigns/list';

$(function () {
  $('body.campaigns.form').each(() => {
    new FormApp({
      el: 'form#new_campaign, form#edit_campaign',
      data: {
        campaign: gon.campaign,
        budgetPlans: gon.budget_plan_data.collection,
        emptyBudgetPlan: gon.budget_plan_data.empty,
        currentBudgetPlan: gon.budget_plan_data.current,
        distributions: gon.distributions,
        channelsWithoutPrice: gon.channels_without_price,
        earliestTimezoneOffsetMs: gon.earliest_timezone_offset_ms,
        latestTimezoneOffsetMs: gon.latest_timezone_offset_ms,
        firstLookCpm: gon.config.prices.first_look_cpm,
        providerAccountType: gon.provider.account_type
      }
    });
  });

  $('body.campaigns-index').each(function () {
    new ListApp({ el: this });
  });
});
