import Backbone from 'backbone';
import 'backbone-relational';
import moment from 'moment';
import FlightsCollection from '../collections/flights';

export const BUDGET_TO_HUNDREDTHS = 100.0;

interface FlightAttributes {
  start_date?: Date;
  end_date?: Date;
  budget: number;
  budget_hundredths: number;
  name: string;
  spent: number;
  overflow: number;
}

class Flight extends Backbone.RelationalModel {
  collection!: FlightsCollection;
  private budgetChanged = false;

  defaults(): FlightAttributes {
    return {
      start_date: undefined,
      end_date: undefined,
      budget: 0,
      budget_hundredths: 0,
      name: '',
      spent: 0,
      overflow: 0
    };
  }

  initialize() {
    super.initialize(...arguments);

    this.set(
      'budget_hundredths',
      Math.round(this.get('budget') * BUDGET_TO_HUNDREDTHS)
    );
    this.on('change:budget', this._onBudgetChanged);
  }

  validate(attributes: FlightAttributes) {
    const effectiveBudget =
      Math.round(attributes.budget * BUDGET_TO_HUNDREDTHS) +
      attributes.overflow;
    if (this.budgetChanged && effectiveBudget < attributes.spent) {
      return 'min_spent';
    }

    if (!this.isEditable()) {
      return;
    }

    if (!this.collection.budget_plan.isSearch() && attributes.budget <= 0) {
      return 'min_budget';
    }

    const start_date = +this.get('start_date');
    const end_date = +this.get('end_date');

    const budget_start = this.collection.getStartTime();
    const budget_end = this.collection.getEndTime();

    const start_date_valid =
      budget_start &&
      budget_end &&
      moment
        .utc(start_date)
        .isBetween(budget_start, budget_end, undefined, '[]');

    const offset_budget_start = budget_start
      ? moment(budget_start).add(24, 'h')
      : null;
    const offset_budget_end = budget_end
      ? moment(budget_end).add(24, 'h')
      : null;

    const end_date_valid =
      offset_budget_start &&
      offset_budget_end &&
      moment
        .utc(end_date)
        .isBetween(offset_budget_start, offset_budget_end, undefined, '[]');

    if (!start_date_valid || !end_date_valid) {
      return 'invalid_dates';
    }

    const other_flight_overlap = this.collection.any(other_flight => {
      if (other_flight === this) {
        return false;
      }

      const start_date_overlap = moment
        .utc(+other_flight.get('start_date'))
        .isBetween(start_date, end_date, undefined, '[)');

      const end_date_overlap = moment
        .utc(+other_flight.get('end_date'))
        .isBetween(start_date, end_date, undefined, '(]');

      return start_date_overlap || end_date_overlap;
    });

    if (other_flight_overlap) {
      return 'overlap';
    }
  }

  isEndDateInPast() {
    return moment().isSameOrAfter(moment.utc(this.get('end_date'), 'x'));
  }

  isStartDateInPast() {
    return moment()
      .endOf('d')
      .isSameOrAfter(moment.utc(this.get('start_date'), 'x'));
  }

  isCurrent() {
    return !this.isEndDateInPast() && this.isStartDateInPast();
  }

  _isStartDateSameAsBudget() {
    return this.get('start_date') === this.collection.getStartTime();
  }

  isEditable(field?: string) {
    if (field === 'budget') {
      return true;
    }

    if (!this.collection.budget_plan.canAddFlights()) {
      return false;
    }

    if (this.isEndDateInPast()) {
      return false;
    }

    if (!this.collection.budget_plan.get('was_live')) {
      return true;
    }

    if (field === 'start_date') {
      return this.isNew() || !this.isStartDateInPast();
    }

    return true;
  }

  isDeletable() {
    if (!this.collection.budget_plan.canAddFlights()) {
      return false;
    }
    if (this.isNew()) {
      return true;
    }

    if (this.collection.length === 1) {
      return !this.isStartDateInPast() || this._isStartDateSameAsBudget();
    } else {
      return !this.isStartDateInPast();
    }
  }

  _onBudgetChanged() {
    const budget = this.previous('budget');
    if (budget !== undefined && budget !== this.get('budget')) {
      this.budgetChanged = true;
      this.set(
        'budget_hundredths',
        Math.round(this.get('budget') * BUDGET_TO_HUNDREDTHS)
      );
    }
  }
}
Flight.setup();

export default Flight;
