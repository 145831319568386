import categorySelect from 'ui/form/category_select';

class App {
  constructor(options) {
    $(options.brandEl).select2({ allowClear: true });
    categorySelect($(options.categoryEl));
  }
}

export default App;
