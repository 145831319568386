import Backgrid from 'backgrid';

class EnumFormatter extends Backgrid.CellFormatter {
  constructor(private scope: string) {
    super();
  }

  fromRaw(value: string | null, _model: Backbone.Model) {
    if (value == null) {
      return I18n.t('na');
    }

    return I18n.t(value, {
      scope: this.scope,
      defaultValue: `${value}`
    });
  }
}

class EnumCell extends Backgrid.Cell {
  column!: Backbone.Model;

  static initClass() {
    this.prototype.className = 'enum-cell';
  }

  initialize(options?: Backbone.ViewOptions<Backbone.Model>) {
    super.initialize(options);
    const enumScope = this.column.get('enumScope');
    this.formatter = new EnumFormatter(
      `${enumScope}.${this.column.get('name')}`
    );
  }
}
EnumCell.initClass();

Object.assign(Backgrid, { EnumCell });
