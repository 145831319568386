import _ from 'underscore';
import reportPage from 'providers/views/report_page';

class App {
  constructor(options) {
    reportPage(options.el);

    $('td.budget_spent').each(() => {
      const values = $(options.el).text().split('/');
      if (values.length === 2) {
        const [spent, budget] = _.map(
          values,
          n => parseInt(n.replace(/[^\d]/g, ''), 10) / 100.0
        );

        const $progress = $('<div>').addClass('progress progress-striped');
        const $bar = $('<div>').addClass('progress-bar').appendTo($progress);
        $bar.width(`${(100 * spent) / budget}%`);

        $(options.el).prepend($progress);
      }
    });
  }
}

export default App;
