import d3 from 'd3';
import nv from 'nvd3';

interface Point {
  x: Date;
  y: number;
}

interface TooltipData {
  color: string;
  data: {
    key: string;
    series: number;
    x: Date;
    y: number;
  };
  index: number;
  series: {
    color: string;
    key: string;
    value: number;
  };
  value: Date;
}

interface ChartData {
  key: string;
  values: {
    x: Date;
    y: number;
  }[];
  color?: string;
  bar: boolean;
  disabled: boolean;
}

export default function () {
  const margin = { top: 30, right: 60, bottom: 50, left: 60 };
  const width = null;
  const height = null;

  const getX = (d: Point) => d.x;
  const getY = (d: Point) => d.y;

  const color = d3.scale.category20().range();
  const tooltip = nv.models.tooltip();
  const lines = nv.models.line();
  // @ts-ignore: TODO(b/126236616) missing typing for nv.models.multiBar()
  const bars = nv.models.multiBar();

  // needs to be both line and historicalBar x Axis
  const x = d3.scale.linear<Date>();

  const y1 = bars.yScale();
  const y2 = lines.yScale();
  const xAxis = nv.models
    // @ts-ignore: TODO(b/126236616) missing typing for nv.models.axis()
    .axis()
    .scale(x)
    .orient('bottom')
    .tickPadding(5);
  const yAxis1 = nv.models
    // @ts-ignore: TODO(b/126236616) missing typing for nv.models.axis()
    .axis()
    .scale(y1)
    .orient('left');
  const yAxis2 = nv.models
    // @ts-ignore: TODO(b/126236616) missing typing for nv.models.axis()
    .axis()
    .scale(y2)
    .orient('right');
  const dispatch = d3.dispatch('tooltipShow', 'tooltipHide');

  const showTooltip = (data: TooltipData) => {
    tooltip
      .duration(0)
      .headerFormatter((d: Date) => xAxis.tickFormat()(d))
      .valueFormatter((d: number) => d3.format(',.2')(d))
      .data(data)
      .hidden(false);
  };

  const hideTooltip = () => {
    tooltip.hidden(true);
  };

  function chart(selection: d3.Selection<ChartData[]>) {
    selection.each(function (this: EventTarget, data) {
      const container = d3.select(this);

      const availableWidth =
        (width || parseInt(container.style('width'), 10) || 960) -
        margin.left -
        margin.right;

      const availableHeight =
        (height || parseInt(container.style('height'), 10) || 400) -
        margin.top -
        margin.bottom;

      const dataBars = data.filter(d => !d.disabled && d.bar);

      const dataLines = data.filter(d => !d.disabled && !d.bar);

      // TODO(b/130036632): try to remove x scale computation from this layer

      const series1 = data
        .filter(d => !d.disabled && d.bar)
        .map(d =>
          d.values.map((dValue, _i) => ({
            x: getX(dValue),
            y: getY(dValue)
          }))
        );

      const series2 = data
        .filter(d => !d.disabled && !d.bar)
        .map(d =>
          d.values.map((dValue, _i) => ({
            x: getX(dValue),
            y: getY(dValue)
          }))
        );

      x
        // @ts-ignore: TODO(b/126232204) wrong d3 typing for Linear<T>.domain(T[])
        .domain(d3.extent(d3.merge(series1.concat(series2)), d => d.x))
        // @ts-ignore: wrong typing, expecting date, but these are numbers
        .range([0, availableWidth]);

      const wrap = d3.select(this).selectAll('g.wrap.linePlusBar').data([data]);
      const gEnter = wrap
        .enter()
        .append('g')
        .attr('class', 'wrap nvd3 linePlusBar')
        .append('g');

      gEnter.append('g').attr('class', 'x axis');
      gEnter.append('g').attr('class', 'y1 axis');
      gEnter.append('g').attr('class', 'y2 axis');
      gEnter.append('g').attr('class', 'barsWrap');
      gEnter.append('g').attr('class', 'linesWrap');
      gEnter.append('g').attr('class', 'legendWrap');

      const g = wrap.select('g');

      lines
        .width(availableWidth)
        .height(availableHeight)
        .color(
          data
            .map((d, i) => d.color || color[i % color.length])
            .filter((_d, i) => !data[i].disabled && !data[i].bar)
        );

      bars
        .width(availableWidth)
        .height(availableHeight)
        .color(
          data
            .map((d, i) => d.color || color[i % color.length])
            .filter((_d, i) => !data[i].disabled && data[i].bar)
        );

      const barsWrap = g
        .select('.barsWrap')
        .datum(dataBars.length ? dataBars : [{ values: [] }]);

      const linesWrap = g
        .select('.linesWrap')
        .datum(dataLines.length ? dataLines : [{ values: [] }]);

      barsWrap.transition().call(bars);
      linesWrap.transition().call(lines);

      // our Y axes are always positive, always start from 0 (TODO stupid hack)
      let d;
      d = y2.domain();
      y2.domain([0, d[1]]);
      d = y1.domain();
      y1.domain([0, d[1]]);

      g.attr('transform', `translate(${margin.left},${margin.top})`);

      xAxis
        // @ts-ignore: TODO(b/126236616) missing typing for nv.utils.calcTicksX
        .ticks(nv.utils.calcTicksX(availableWidth / 100, data))
        .tickSize(-availableHeight, 0);

      g.select('.x.axis').attr('transform', `translate(0,${y1.range()[0]})`);
      g.select('.x.axis').transition().call(xAxis);

      yAxis1.ticks(availableHeight / 36).tickSize(-availableWidth, 0);

      g.select('.y1.axis')
        .transition()
        .style('opacity', dataBars.length ? 1 : 0)
        .call(yAxis1);

      yAxis2
        .ticks(availableHeight / 36)
        .tickSize(dataBars.length ? 0 : -availableWidth, 0); // Show the y2 rules only if y1 has none

      g.select('.y2.axis')
        .style('opacity', dataLines.length ? 1 : 0)
        .attr('transform', `translate(${x.range()[1]},0)`);

      g.select('.y2.axis').transition().call(yAxis2);

      lines.dispatch.on('elementMouseover.tooltip', evt => {
        evt.value = evt.point.x;
        evt.series = {
          value: evt.point.y,
          color: evt.point.color,
          key: evt.series.key
        };
        showTooltip(evt);
      });

      lines.dispatch.on('elementMouseout.tooltip', () => {
        hideTooltip();
      });

      bars.dispatch.on('elementMouseover.tooltip', (evt: TooltipData) => {
        evt.value = getX(evt.data);
        evt['series'] = {
          key: evt.data.key,
          value: getY(evt.data),
          color: evt.color
        };
        showTooltip(evt);
      });

      bars.dispatch.on('elementMouseout.tooltip', () => {
        hideTooltip();
      });
    });

    return chart;
  }

  chart.dispatch = dispatch;
  chart.lines = lines;
  chart.bars = bars;
  chart.xAxis = xAxis;
  chart.yAxis1 = yAxis1;
  chart.yAxis2 = yAxis2;

  d3.rebind(chart, lines, 'defined', 'size', 'clipVoronoi', 'interpolate');

  return chart;
}
