var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, fieldGroups, groupByGroups) {pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Csection class=\"group-by col-md-4\"\u003E\u003Cdiv class=\"panel panel-info\"\u003E\u003Cdiv class=\"panel-heading\"\u003E\u003Ch3 class=\"panel-title\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.advanced.fields_selector.group_by')) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cul class=\"list-group\"\u003E";
// iterate groupByGroups
;(function(){
  var $$obj = groupByGroups;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var group = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli" + (pug.attr("class", pug.classes(["list-group-item",'group-by-' + group], [false,true]), false, true)) + "\u003E\u003Ch5 class=\"list-group-item-heading\"\u003E" + (pug.escape(null == (pug_interp = I18n.t(group, { scope: 'reports.advanced.fields_selector.groups', defaultValue: group })) ? "" : pug_interp)) + "\u003C\u002Fh5\u003E\u003C\u002Fli\u003E\u003Cli" + (pug.attr("class", pug.classes(["group-by-region",'group-by-' + group], [false,true]), false, true)) + "\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var group = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli" + (pug.attr("class", pug.classes(["list-group-item",'group-by-' + group], [false,true]), false, true)) + "\u003E\u003Ch5 class=\"list-group-item-heading\"\u003E" + (pug.escape(null == (pug_interp = I18n.t(group, { scope: 'reports.advanced.fields_selector.groups', defaultValue: group })) ? "" : pug_interp)) + "\u003C\u002Fh5\u003E\u003C\u002Fli\u003E\u003Cli" + (pug.attr("class", pug.classes(["group-by-region",'group-by-' + group], [false,true]), false, true)) + "\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003C\u002Fsection\u003E\u003Csection class=\"fields col-md-4\"\u003E\u003Cdiv class=\"panel panel-info\"\u003E\u003Cdiv class=\"panel-heading\"\u003E\u003Ch3 class=\"panel-title\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.advanced.fields_selector.available')) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cul class=\"list-group\"\u003E";
// iterate fieldGroups
;(function(){
  var $$obj = fieldGroups;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var group = $$obj[pug_index1];
pug_html = pug_html + "\u003Cli" + (pug.attr("class", pug.classes(["list-group-item",'fields-' + group], [false,true]), false, true)) + "\u003E\u003Ch5 class=\"list-group-item-heading\"\u003E" + (pug.escape(null == (pug_interp = I18n.t(group, { scope: 'reports.advanced.fields_selector.groups', defaultValue: group })) ? "" : pug_interp)) + "\u003C\u002Fh5\u003E\u003C\u002Fli\u003E\u003Cli" + (pug.attr("class", pug.classes(["fields-region",'fields-' + group], [false,true]), false, true)) + "\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var group = $$obj[pug_index1];
pug_html = pug_html + "\u003Cli" + (pug.attr("class", pug.classes(["list-group-item",'fields-' + group], [false,true]), false, true)) + "\u003E\u003Ch5 class=\"list-group-item-heading\"\u003E" + (pug.escape(null == (pug_interp = I18n.t(group, { scope: 'reports.advanced.fields_selector.groups', defaultValue: group })) ? "" : pug_interp)) + "\u003C\u002Fh5\u003E\u003C\u002Fli\u003E\u003Cli" + (pug.attr("class", pug.classes(["fields-region",'fields-' + group], [false,true]), false, true)) + "\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003C\u002Fsection\u003E\u003Csection class=\"selected col-md-4\"\u003E\u003Cdiv class=\"panel panel-info\"\u003E\u003Cdiv class=\"panel-heading\"\u003E\u003Ch3 class=\"panel-title\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.advanced.fields_selector.selected')) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"list-group\"\u003E\u003Cdiv class=\"selected-region\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fsection\u003E\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"fieldGroups" in locals_for_with?locals_for_with.fieldGroups:typeof fieldGroups!=="undefined"?fieldGroups:undefined,"groupByGroups" in locals_for_with?locals_for_with.groupByGroups:typeof groupByGroups!=="undefined"?groupByGroups:undefined));;return pug_html;};
module.exports = template;