import moment from 'moment';
import Alert from '../models/alert';
import Report from '../models/report';

interface AlertsCollectionOptions {
  url?: string;
}

class AlertsCollection extends Backbone.Collection<Alert> {
  private campaigns?: Backbone.Collection<Report>;
  private grouped?: { [campaignId: string]: Alert[] };

  static initClass() {
    this.prototype.model = Alert;
  }

  comparator = (model: Alert) => {
    const snoozed_until = model.get('snoozed_until');
    if (snoozed_until) {
      return moment(snoozed_until).format('x');
    } else {
      return -Infinity;
    }
  };

  initialize(_models: Alert[], options: AlertsCollectionOptions = {}) {
    if (options.url) {
      this.url = options.url;
    }
  }

  fetchAndSet(campaigns: Backbone.Collection<Report>) {
    this.campaigns = campaigns;
    return this.fetch().then(() => {
      this.grouped = this.groupBy('campaign_id');
      this.campaigns!.each($.proxy(this.setCampaignAlerts, this));
      return this.campaigns!.on(
        'add change',
        $.proxy(this.setCampaignAlerts, this)
      );
    });
  }

  snooze(snoozed_until: Date | null) {
    const previous_snoozes = this.map(alert => alert.get('snoozed_until'));
    this.forEach(alert => alert.set('snoozed_until', snoozed_until));

    return this.sync('update', this, {
      url: `${this.url}/snooze`,
      attrs: {
        snoozed_until
      },
      error: () => {
        return this.forEach((alert, i) =>
          alert.set('snoozed_until', previous_snoozes[i])
        );
      }
    });
  }

  unsnooze() {
    return this.snooze(null);
  }

  destroy() {
    const alerts = this.models.slice();
    this.reset();
    return this.sync('delete', this, {
      error: () => {
        return this.reset(alerts);
      }
    });
  }

  canSnooze() {
    return !this.all(alert => alert.get('snoozed_until'));
  }
  canUnsnooze() {
    return this.any(alert => alert.get('snoozed_until'));
  }

  private setCampaignAlerts(campaign: Report) {
    if (this.grouped) {
      const alerts = this.grouped[campaign.id];
      if (alerts) {
        campaign.set('alerts', new Backbone.Collection(alerts));
      }
    }
  }
}
AlertsCollection.initClass();

export default AlertsCollection;
