import _ from 'underscore';

// tslint:disable-next-line:no-any
export function isDeepEmpty(data: any): boolean {
  return _.isObject(data)
    ? _.all(data, isDeepEmpty)
    : _.isNull(data) ||
        _.isUndefined(data) ||
        (!_.isNumber(data) && !_.isBoolean(data) && _.isEmpty(data));
}

export enum ConfigLocation {
  am = 'am',
  row = 'row',
  il = 'il'
}

export enum AdviseEnvironment {
  US = 'US',
  Israel = 'Israel',
  World = 'World'
}

const environmentMap = {
  [ConfigLocation.am]: AdviseEnvironment.US,
  [ConfigLocation.il]: AdviseEnvironment.Israel,
  [ConfigLocation.row]: AdviseEnvironment.World
};

export function mapLocationToAdviseEnv(
  location: ConfigLocation
): AdviseEnvironment {
  return environmentMap[location];
}

/**
 * Scroll current viewport to the top of page.
 *
 * Example use case: upon encountering server error, scroll
 * to top of page so that error message is visible. Scrolls with
 * behavior "smooth" by default.
 */
export function scrollToTop(isSmooth = true) {
  window.scrollTo({
    top: 0,
    behavior: isSmooth ? 'smooth' : 'auto'
  });
}

const warnOnLeaveListeners: ((e: BeforeUnloadEvent) => string | undefined)[] =
  [];

export function warnOnLeave(condition?: () => boolean) {
  const listener = (e: BeforeUnloadEvent) => {
    if (!condition || condition()) {
      e.preventDefault();
      e.returnValue = '';
      return '';
    }
  };

  warnOnLeaveListeners.push(listener);
  window.addEventListener('beforeunload', listener);
}

export function cancelWarnOnLeave() {
  warnOnLeaveListeners.forEach(listener =>
    window.removeEventListener('beforeunload', listener)
  );
}
