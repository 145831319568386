import Backbone from 'backbone';
import _ from 'underscore';
import DisableIf from '../../ui/form/disable_if';
import CampaignTagAssociation from '../../models/campaign_tag_association';
import RadioToggleButtons from '../../views/radio_toggle_buttons';
import CampaignTagsList from './tags/list';

const CampaignLocationsView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '[name="campaign[pins_association]"]': {
      observe: 'pins_association',
      afterUpdate($el) {
        $el.trigger('change');
      }
    },
    '[name="campaign[pin_ids]"]': 'pin_ids'
  },

  ui: {
    radioToggles: '.campaign_pins_association',
    pinAssociationRadioGroup: '[name="campaign[pins_association]"]',
    allPinsRadioButton: '[name="campaign[pins_association]"][value="all"]',
    onlyPointsRadioButton:
      '[name="campaign[pins_association]"][value="only_points"]',
    onlyPolygonsRadioButton:
      '[name="campaign[pins_association]"][value="only_polygons"]',
    byTagsRadioButton: '[name="campaign[pins_association]"][value="by_tags"]',
    locationTagsWrapper: '.campaign_pins_by_location_tags',
    pinIdsWrapper: '.campaign_pin_ids',
    tagsContainer: '.campaign-tags-container',
    campaignTagsInput: '.campaign-tags-input'
  },

  events: {
    'change @ui.pinAssociationRadioGroup': 'onPinsAssociationChange'
  },

  modelEvents: {
    'change:pins_by_location_tags': 'renderCampaignTagsList',
    'change:channel': 'updateLocationOptions'
  },

  initialize(options) {
    this.stickit();
    this.bindUIElements();
    this.renderViews();
    this.initDisableIf();

    this.updateLocationOptions();
  },

  renderViews() {
    this.renderCampaignTagsList();
    this.initRadioToggles();
  },

  initRadioToggles() {
    new RadioToggleButtons({ el: this.ui.radioToggles });
  },

  renderCampaignTagsList() {
    if (!this.ui.tagsContainer.length) {
      return;
    }

    const availableTags = this.ui.tagsContainer
      .data('available-tags')
      .filter(tag => tag.values.length);

    if (_.isEmpty(availableTags)) {
      this.ui.byTagsRadioButton.prop('disabled', _.isEmpty(availableTags));
      return;
    }

    this.campaignTagsCollection = new Backbone.Collection(
      this.model.get('pins_by_location_tags'),
      {
        model: CampaignTagAssociation
      }
    );
    if (this.campaignTagsCollection.isEmpty()) {
      this.campaignTagsCollection.add(new CampaignTagAssociation());
    }
    this._setCampaignTagsJSONInput();

    const tagList = new CampaignTagsList({
      el: this.ui.tagsContainer,
      collection: this.campaignTagsCollection,
      available_tags: availableTags
    });

    this.listenTo(this.campaignTagsCollection, 'update change', () => {
      this._setCampaignTagsJSONInput();
    });

    tagList.render();
  },

  _setCampaignTagsJSONInput() {
    this.ui.campaignTagsInput.val(
      JSON.stringify(this.campaignTagsCollection.toJSON())
    );
  },

  onPinsAssociationChange() {
    this.model.set('all_pins', this.ui.allPinsRadioButton.is(':checked'));
  },

  initDisableIf() {
    DisableIf(this.ui.locationTagsWrapper, 'hide-unless', {
      field: 'pins_association',
      value: 'by_tags'
    });

    DisableIf(this.ui.pinIdsWrapper, 'hide-unless', {
      field: 'pins_association',
      value: 'by_ids'
    });
  },

  updateLocationOptions() {
    // All Pins is now deprecated b/73579425
    this.ui.allPinsRadioButton.prop('disabled', true).trigger('change');

    if (this.model.isIntent() || this.model.isCategorySearch()) {
      // Change the checked option if the option is no longer valid.
      if (this.ui.allPinsRadioButton.is(':checked')) {
        this.ui.allPinsRadioButton.prop('checked', false).trigger('change');
        this.ui.onlyPointsRadioButton.prop('checked', true).trigger('change');
      } else if (this.ui.onlyPolygonsRadioButton.is(':checked')) {
        this.ui.onlyPolygonsRadioButton
          .prop('checked', false)
          .trigger('change');
        this.ui.onlyPointsRadioButton.prop('checked', true).trigger('change');
      }

      // Disable invalid options.
      this.ui.onlyPolygonsRadioButton.prop('disabled', true).trigger('change');
    } else {
      // Make sure all options are enabled.
      this.ui.onlyPolygonsRadioButton.prop('disabled', false).trigger('change');
    }
  }
});

export default CampaignLocationsView;
