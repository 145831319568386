import { AccountType } from '../angular/store/entity/provider';

/**
 * A wrapper for sending custom events to Google Analytics.
 */

export const CUSTOM_DIMENSION_EVENT = 'customDimension';
export const CUSTOM_TRACKING_EVENT = 'trackingEvent';
export const ACCOUNT_PAUSED_EVENT = 'accountPaused';

type UserType = 'staff' | 'reseller' | 'client';

type AnalyticsDimension = {
  event: string;
  providerId?: number;
  providerAccountType?: AccountType;
  userType?: UserType;
};

export type AnalyticsEvent = {
  category: string;
  action: string;
  label?: string;
  value?: number;
};

export interface ExperimentMetric {
  experimentName: string;
  experimentGroup: string;
  metricName: string;
  metricValue: number;
}

// Categories to use with AnalyticsEvent
export enum AnalyticsCategory {
  LOGIN_UNKNOWN_FLOW = 'Login (Unknown Flow)',
  LOGIN_WITH_GOOGLE = 'Login with Google',
  LOGIN_WITH_WAZE = 'Login with Waze'
}

/**
 * Push a custom dimension event with the user type and provider ID.
 */
export function sendCustomDimension(): void {
  let providerId: number | undefined;
  let providerAccountType: AccountType | undefined;

  if (gon.provider) {
    providerId = gon.provider.id;
    providerAccountType = gon.provider.account_type;
  }
  const userType = getUserType();

  if ('dataLayer' in window && canSend()) {
    const event: AnalyticsDimension = {
      event: CUSTOM_DIMENSION_EVENT
    };

    if (providerId != null) {
      event['providerId'] = providerId;
    }

    if (providerAccountType != null) {
      event['providerAccountType'] = providerAccountType;
    }

    if (userType) {
      event['userType'] = userType;
    }

    window.dataLayer.push(event);
  }
}

/**
 * Push a custom event of the specified name and value.
 */
export function sendEvent(name: string, value?: string | number) {
  if (!window.dataLayer) return;

  const event = {
    event: name,
    value
  };

  window.dataLayer.push(event);
}

/**
 * Push a data layer variable.
 */
export function sendVariable(name: string, value: string | number) {
  if (!window.dataLayer) return;

  const variables = { [name]: value };
  window.dataLayer.push(variables);
}

/**
 * Push a custom event to be tracked with Google Analytics
 */
export function trackEvent(analyticsEvent: AnalyticsEvent) {
  if (!window['dataLayer']) {
    return;
  }

  const event = {
    event: CUSTOM_TRACKING_EVENT,
    ...analyticsEvent
  };
  window.dataLayer.push(event);
}

/**
 *
 * Track data around a Dexter experiment
 */
export function trackExperimentMetric(metric: ExperimentMetric) {
  trackEvent({
    category: metric.experimentName,
    label: metric.experimentGroup,
    action: metric.metricName,
    value: metric.metricValue
  });
}

/**
 * The type of a logged-in user is one of 'staff', 'reseller', or 'client'.
 */
export function getUserType(): UserType | undefined {
  const user = gon.current_user;
  if (user === undefined) {
    return undefined;
  }

  if (user.staff) {
    return 'staff';
  } else if (user.reseller) {
    return 'reseller';
  } else {
    return 'client';
  }
}

function canSend(): boolean {
  return gon.current_user != null || gon.provider != null;
}
