// Colors from waze-soul, random order

const COLORS = [
  '#56cbe7',
  '#9400ff',
  '#08e07d',
  '#f68f23',
  '#d4e7ed',
  '#c290c6',
  '#2d5769',
  '#ff8383',
  '#599e5c',
  '#00ece3',
  '#93c4d3',
  '#f8bf16',
  '#59899e',
  '#5ccc79'
];

const UNSPENT_COLOR = '#dbdbdb';

const INACTIVE_COLOR = '#9a9a9a';

const getColor = (i, isActive) =>
  isActive ? COLORS[i % COLORS.length] : INACTIVE_COLOR;

export { COLORS, UNSPENT_COLOR, INACTIVE_COLOR, getColor };
