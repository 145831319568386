import _ from 'underscore';
import DisableIf from 'ui/form/disable_if';
import autonumeric from 'ui/form/autonumeric';

const CampaignBidView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '[name="campaign[price]"]': 'price',
    '#campaign_price_from_pin': 'price_from_pin'
  },

  ui: {
    campaignPrice: '[name="campaign[price]"]',
    usePriceFromPinWrapper: '.campaign_price_from_pin',
    usePriceFromPin: '[name="campaign[price_from_pin]"]',
    autonumeric: '[data-autonumeric]',
    priceLabel: 'label[for=campaign_price]'
  },

  modelEvents: {
    'change:channel': 'onChannelChange'
  },

  events: {
    'change @ui.usePriceFromPin': 'onUsePriceFromPinChange'
  },

  initialize(options) {
    this.mergeOptions(options, ['currentBudgetPlan']);
    this.bindUIElements();
    this.bindEvents();
    this.renderViews();
    this.initPriceFields();
    this.stickit();
    this.initDisableIf();
  },

  bindEvents() {
    this.listenTo(
      this.currentBudgetPlan,
      'change:first_look',
      this.onFirstLookChange
    );
  },

  renderViews() {
    this.originalPrice = this.model.get('price');
    this.onUsePriceFromPinChange();
    this.onFirstLookChange();
  },

  initPriceFields() {
    autonumeric(this.ui.autonumeric);
  },

  initDisableIf() {
    DisableIf(this.ui.campaignPrice, 'disable-if', {
      field: 'price_from_pin',
      value: 'true'
    });

    DisableIf(this.ui.usePriceFromPinWrapper, 'hide-unless', {
      field: 'channel',
      value: ['ADS_PIN_INFO', 'ADS_0SPEED_INFO']
    });
  },

  onUsePriceFromPinChange() {
    const $price = this.ui.campaignPrice;
    if (this.isCheckboxChecked(this.ui.usePriceFromPin)) {
      $price.val('').trigger('blur');
    } else {
      const val =
        parseInt(this.originalPrice, 10) < 0 ? '' : this.originalPrice;
      $price.val(val).trigger('blur');
    }
  },

  onChannelChange() {
    const $price = this.ui.campaignPrice;
    const channel = this.model.get('channel');

    const disabled =
      channel === 'ADS_LINE_SEARCH_INFO' ||
      channel === 'ADS_INTENT_INFO' ||
      (_.include(['ADS_PIN_INFO', 'ADS_0SPEED_INFO'], channel) &&
        this.model.get('price_from_pin'));

    $price.attr('disabled', disabled);

    this.ui.priceLabel.text(getTranslatedPriceLabel(this.model.priceType()));
  },

  isCheckboxChecked($el, event, options) {
    return $el.is(':checked');
  },

  onFirstLookChange() {
    this.$el.prop('disabled', this.currentBudgetPlan.isFirstLook());
  }
});

function getTranslatedPriceLabel(priceType) {
  switch (priceType) {
    case 'CPM':
      return I18n.t('campaigns.form.bid_cpm');
    case 'CPC':
      return I18n.t('campaigns.form.bid_cpc');
    default:
      return 'Price';
  }
}

export default CampaignBidView;
