const CampaignCustomTargetingView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '[name="campaign[custom_target]"]': 'custom_target'
  },

  initialize() {
    this.stickit();
  }
});

export default CampaignCustomTargetingView;
