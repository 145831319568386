import { togglePanelCollapse } from 'helpers/views';
import { isDeepEmpty } from 'helpers/utils';
const CampaignOpeningHoursTargetingView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '#campaign_filter_when_closed': 'filter_when_closed'
  },

  initialize() {
    this.stickit();
    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get('drive_targeting'))
    );
  }
});

export default CampaignOpeningHoursTargetingView;
