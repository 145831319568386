var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, cancelButton, duplicateButton, submitButton, title) {pug_html = pug_html + "\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E";
if (title) {
pug_html = pug_html + "\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\"\u003E&times;\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\"\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"modal-body\"\u003E\u003C\u002Fdiv\u003E";
if (submitButton || cancelButton || duplicateButton) {
pug_html = pug_html + "\u003Cdiv class=\"modal-footer\"\u003E";
if (duplicateButton) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-default duplicate\" type=\"button\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('duplicate')) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E";
}
if (cancelButton) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-default\" type=\"button\" data-dismiss=\"modal\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('cancel')) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E";
}
if (submitButton) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-primary\" type=\"submit\"\u003E\u003Ci class=\"fa fa-spinner fa-spin\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = I18n.t('submit')) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"cancelButton" in locals_for_with?locals_for_with.cancelButton:typeof cancelButton!=="undefined"?cancelButton:undefined,"duplicateButton" in locals_for_with?locals_for_with.duplicateButton:typeof duplicateButton!=="undefined"?duplicateButton:undefined,"submitButton" in locals_for_with?locals_for_with.submitButton:typeof submitButton!=="undefined"?submitButton:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return pug_html;};
module.exports = template;