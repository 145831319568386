import _ from 'underscore';
import { toCurrency } from '../../helpers';

class CurrencyCellFormatter extends Backgrid.CellFormatter {
  constructor(private precision: number, private magnitudeCorrection: number) {
    super();
  }

  fromRaw(value: number) {
    if (value === -1) {
      return '-'; // N/A value
    }

    return toCurrency(value * 10 ** this.magnitudeCorrection, {
      precision: this.precision
    });
  }

  toRaw(value: string) {
    return value;
  }
}

class CurrencyCellEditor extends Backgrid.InputCellEditor {
  column!: Backbone.Model;
  // @ts-ignore TODO(b/129067522): missing Backgrid typing
  private formatter: Backgrid.NumberFormatter;

  static initClass() {
    this.prototype.attributes = { type: 'number' };
  }

  initialize(options: {}) {
    super.initialize(options);
    this.formatter = new Backgrid.NumberFormatter();
    this.formatter.fromRaw = _.identity;
  }

  postRender(model: Backbone.Model, column: Backbone.Model) {
    super.postRender(model, column);

    const tooltipText = Backgrid.callByNeed(
      this.column.get('tooltip'),
      null,
      this.model
    );
    if (tooltipText) {
      this.$el.tooltip({
        title: tooltipText,
        trigger: 'manual',
        placement: 'bottom'
      });
      this.$el.tooltip('show');
    }

    return this.$el.select();
  }
}
CurrencyCellEditor.initClass();

class CurrencyCell extends Backgrid.Cell {
  column!: Backbone.Model;

  // @ts-ignore TODO(b/129067522): missing Backgrid typing
  editor = CurrencyCellEditor;

  static initClass() {
    this.prototype.className = 'currency-cell';
  }

  initialize(options?: Backbone.ViewOptions<Backbone.Model>) {
    super.initialize(options);
    const precision = this.column.get('precision');
    const magnitudeCorrection = this.column.get('magnitudeCorrection') || 0;
    this.formatter = new CurrencyCellFormatter(precision, magnitudeCorrection);
  }
}
CurrencyCell.initClass();

Object.assign(Backgrid, { CurrencyCell });
