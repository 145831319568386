import moment from 'moment';
import Actions from 'campaigns/views/alerts/actions';

import template from 'templates/campaign_alerts/item.pug';

class Item extends Marionette.LayoutView {
  static initClass() {
    this.prototype.template = template;

    this.prototype.regions = { actions_region: '.actions' };

    this.prototype.tagName = 'li';
    this.prototype.className = 'list-group-item';
  }

  initialize(options = {}) {
    return (this.end_date = options.end_date);
  }

  onRender() {
    return this.actions_region.show(
      new Actions({
        model: this.model,
        end_date: this.end_date,
        titles: {
          delete: I18n.t('campaigns.form.alerts.delete'),
          snooze: I18n.t('campaigns.form.alerts.snooze'),
          unsnooze: I18n.t('campaigns.form.alerts.unsnooze'),
          delete_confirm: I18n.t('campaigns.form.alerts.delete_confirm')
        }
      })
    );
  }

  templateHelpers() {
    const snoozed_until = moment(this.model.get('snoozed_until'));

    return {
      isSnoozed() {
        return snoozed_until.isValid();
      },
      formattedSnoozedUntil() {
        return snoozed_until.format(I18n.t('time.formats.momentjs'));
      }
    };
  }
}
Item.initClass();

export default Item;
