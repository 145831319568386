var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, campaigns_count, offers_count, pins_count) {if (pins_count || campaigns_count || offers_count) {
pug_html = pug_html + "\u003Cdiv class=\"item form-group\"\u003E\u003Cdiv class=\"controls\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = I18n.t('providers.reports_filter.items.prefix')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (pins_count) {
pug_html = pug_html + "\u003Cspan class=\"label items-pins\" data-type=\"pins\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('providers.reports_filter.items.pins', { count: pins_count })) ? "" : pug_interp)) + "\u003Ca class=\"close\" href=\"#\"\u003E&times;\u003C\u002Fa\u003E\u003C\u002Fspan\u003E";
}
if (pins_count && campaigns_count) {
pug_html = pug_html + "\u003Cspan\u003E";
if (!offers_count) {
pug_html = pug_html + (pug.escape(null == (pug_interp = I18n.t('providers.reports_filter.items.and')) ? "" : pug_interp));
}
else {
pug_html = pug_html + ",";
}
pug_html = pug_html + (pug.escape(null == (pug_interp = ' ') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if (campaigns_count) {
pug_html = pug_html + "\u003Cspan class=\"label items-campaigns\" data-type=\"campaigns\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('providers.reports_filter.items.campaigns', { count: campaigns_count })) ? "" : pug_interp)) + "\u003Ca class=\"close\" href=\"#\"\u003E&times;\u003C\u002Fa\u003E\u003C\u002Fspan\u003E";
}
if (campaigns_count && offers_count) {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = I18n.t('providers.reports_filter.items.and')) ? "" : pug_interp)) + (pug.escape(null == (pug_interp = ' ') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if (offers_count) {
pug_html = pug_html + "\u003Cspan class=\"label items-offers\" data-type=\"offers\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('providers.reports_filter.items.offers', { count: offers_count })) ? "" : pug_interp)) + "\u003Ca class=\"close\" href=\"#\"\u003E&times;\u003C\u002Fa\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"campaigns_count" in locals_for_with?locals_for_with.campaigns_count:typeof campaigns_count!=="undefined"?campaigns_count:undefined,"offers_count" in locals_for_with?locals_for_with.offers_count:typeof offers_count!=="undefined"?offers_count:undefined,"pins_count" in locals_for_with?locals_for_with.pins_count:typeof pins_count!=="undefined"?pins_count:undefined));;return pug_html;};
module.exports = template;