var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, _, field, multiple, values) {pug_html = pug_html + "\u003Cselect" + (" class=\"form-control\""+" name=\"values\""+pug.attr("multiple", multiple, true, true)+" data-style=\"select-values\"") + "\u003E\u003Coption disabled selected\u003E\u003C\u002Foption\u003E";
// iterate I18n.t(field, { scope: 'reports.advanced.enum_values'})
;(function(){
  var $$obj = I18n.t(field, { scope: 'reports.advanced.enum_values'});
  if ('number' == typeof $$obj.length) {
      for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
        var label = $$obj[key];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", key, true, true)+pug.attr("selected", _.include(values, key), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;
      var label = $$obj[key];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", key, true, true)+pug.attr("selected", _.include(values, key), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"_" in locals_for_with?locals_for_with._:typeof _!=="undefined"?_:undefined,"field" in locals_for_with?locals_for_with.field:typeof field!=="undefined"?field:undefined,"multiple" in locals_for_with?locals_for_with.multiple:typeof multiple!=="undefined"?multiple:undefined,"values" in locals_for_with?locals_for_with.values:typeof values!=="undefined"?values:undefined));;return pug_html;};
module.exports = template;