$(() =>
  $('body.location_tags-index').each(() => {
    $('table tbody tr').each((_i, el) => {
      const $form = $(el).find('.edit-location-tag-form');
      const $update_link = $(el).find('a.update-link');

      $update_link.click(() => $form.submit());
    });
  })
);
