import * as cookieConsent from '@waze/cookie_consent';
import Rails from 'rails-ujs';
import { sendCustomDimension } from './shared/analytics';
import { createStackdriverErrorReporter } from './shared/stackdriver';
import { feedbackPopup } from './ui/feedback_popup';

$(() => {
  Rails.start();

  cookieConsent.initialize();

  $(document).on('click', 'a[data-reset-modal]', function () {
    const form_name = $(this).data('reset-modal');
    $(`${form_name} .modal-success`).hide();
    $(`${form_name} .modal-default`).show();
  });

  // a[rel=feedback-popup] triggers FeedbackPopup
  $('body').on('click', 'a[rel=feedback-popup]', e => {
    e.preventDefault();
    feedbackPopup();
  });

  // disabled links
  $('a.disabled').on('click', e => e.preventDefault());

  // locale dropdown: switch to the full client-side location route
  $('.locale.dropdown a.locale').on('click', function () {
    const { href: oldHref, origin } = document.location!;
    const oldPrefix = `${origin}/${I18n.locale}`;

    let newHref;
    const newLocale = $(this).attr('data-locale');
    const newPrefix = `${origin}/${newLocale}`;

    if (oldHref.indexOf(oldPrefix) === 0) {
      // If there is already a locale specifier in the URI, replace it
      newHref = oldHref.replace(oldPrefix, newPrefix);
    } else {
      // Else, add in the new locale specifier by replacing just the origin
      newHref = oldHref.replace(origin, newPrefix);
    }

    // Remove locale specifier if switching to the default 'en' locale to avoid
    // an extra BE redirect
    newHref = newHref.replace(`${origin}/en`, origin);

    $(this).attr('href', newHref);
  });

  // bootstrap stuff
  $('a[rel=popover]').popover();
  $('a[rel=tooltip], .tooltip').tooltip();

  // send a custom user dimension analytics event
  sendCustomDimension();

  // Create and start the Stackdriver Error Reporting client.
  // This patches `window.onerror` to capture uncaught errors.
  // Caught errors can be reported via `window.stackdriver.report(e)`.
  createStackdriverErrorReporter();

  // dismiss popups on outside clicks
  // via http://stackoverflow.com/a/14857326/107085
  $('body').on('click', e =>
    $('[data-toggle="popover"]').each(function () {
      if (!$(this).is(e.target)) {
        if (
          $(this).has(e.target).length === 0 &&
          $('.popover').has(e.target).length === 0
        ) {
          $(this).popover('hide');
        }
      }
    })
  );
});
