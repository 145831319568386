import Backbone from 'backbone';
import _ from 'underscore';

export interface BrandCategoryPresenceTargetAttributes {
  lookbackWindow: '_7_DAYS' | '_14_DAYS' | '_30_DAYS' | '_60_DAYS';
  atLeast: number;
  atMost: number;
}

export interface ProviderAudiencesTargetAttributes {
  targetingType: 'Include' | 'Exclude';
  audienceIds: number[];
}

interface CampaignAttributes {
  id: number;
  name: string;
  offer_ids: string; // csv
  visibility: 'all' | ' staff' | 'none';
  channel: string; // enum
  price: number;
  price_from_pin: boolean;
  radius: number;
  pins_association:
    | 'all'
    | 'only_points'
    | 'only_polygons'
    | 'by_tags'
    | 'by_ids';
  all_pins: boolean;
  pins_by_location_tags: string[];
  pin_ids: string;
  placed_measurement_enabled: boolean;
  neighbourhood_target: {};
  target_devices: 'IPHONE' | 'ANDROID';
  target_genders: string[]; // multi enum
  target_ages: string[]; // multi enum
  filter_when_closed: boolean;
  show_schedule: {}; // Schedule Array
  drive_targeting: {}; // DriveTargeting
  route_length_target: {};
  brand_category_presence_target: BrandCategoryPresenceTargetAttributes;
  navigation_history_target: {};
  brand_destination_target: {};
  traffic_target: {}; // TrafficTarget
  custom_target: {};
  targeted_origin_regions: [];
  targeted_destination_regions: [];
  spent: number;
  budget_plan_id: number;
  campaign_grouping: string;
  provider_audiences_target: ProviderAudiencesTargetAttributes;
  enable_home_screen: boolean;
}

class Campaign extends Backbone.Model {
  defaults(): Partial<CampaignAttributes> {
    return {
      id: undefined,
      name: '',
      offer_ids: undefined,
      visibility: undefined,
      channel: undefined,
      price: undefined,
      price_from_pin: false,
      radius: this.getDefaultRadius(),
      pins_association: undefined,
      all_pins: true,
      pins_by_location_tags: undefined,
      pin_ids: '',
      placed_measurement_enabled: false,
      neighbourhood_target: undefined,
      target_devices: undefined,
      target_genders: undefined,
      target_ages: undefined,
      filter_when_closed: false,
      show_schedule: undefined,
      drive_targeting: undefined,
      route_length_target: undefined,
      traffic_target: undefined,
      custom_target: undefined,
      spent: undefined,
      budget_plan_id: undefined,
      campaign_grouping: undefined,
      provider_audiences_target: undefined,
      enable_home_screen: false
    };
  }

  initialize() {
    this.on('change:channel', () => {
      this.set('radius', this.defaults().radius);
    });
  }

  getDefaultRadius() {
    if (this.isTakeOver()) {
      return 10000;
    } else {
      return 5000;
    }
  }

  isTakeOver() {
    return this.get('channel') === 'ADS_0SPEED_INFO';
  }

  isPin() {
    return this.get('channel') === 'ADS_PIN_INFO';
  }

  isCategorySearch() {
    return this.get('channel') === 'ADS_PREMIUM_CATEGORY_SEARCH';
  }

  isSearch() {
    return this.get('channel') === 'ADS_LINE_SEARCH_INFO';
  }

  isIntent() {
    return this.get('channel') === 'ADS_INTENT_INFO';
  }

  isNew() {
    return super.isNew() || _.isEmpty(this.id);
  }

  has1pAudiences() {
    return !!this.get('provider_audiences_target')?.audienceIds?.length;
  }

  priceType(): 'CPC' | 'CPM' {
    return this.isCategorySearch() ? 'CPC' : 'CPM';
  }
}

export default Campaign;
