import Backbone from 'backbone';

interface CampaignTagAssociationAttributes {
  tag?: string;
  values: string[];
}

class CampaignTagAssociation extends Backbone.Model {
  defaults(): CampaignTagAssociationAttributes {
    return {
      tag: undefined,
      values: []
    };
  }
}

export default CampaignTagAssociation;
