var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, countries) {pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E\u003Cdiv class=\"control-label\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('campaigns.form.cellular_targeting.country_label')) ? "" : pug_interp)) + "\u003Ca" + (" class=\"fa fa-question-circle\""+" rel=\"tooltip\""+pug.attr("title", I18n.t('campaigns.form.cellular_targeting.country_hint'), true, true)) + "\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"controls\"\u003E\u003Cselect class=\"country-select\"\u003E\u003Coption\u003E\u003C\u002Foption\u003E";
// iterate countries
;(function(){
  var $$obj = countries;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var country = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", country, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = country) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var country = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", country, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = country) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cdiv class=\"control-label\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"controls\"\u003E\u003Cdiv class=\"network-select\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"countries" in locals_for_with?locals_for_with.countries:typeof countries!=="undefined"?countries:undefined));;return pug_html;};
module.exports = template;