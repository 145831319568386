import { App } from './app';

$('body.predictions').each(() => {
  const app = new App({
    el: '#predictions-app',
    currentUser: gon.current_user,
    geoLists: {
      google_countries: gon.google_countries?.sort((a, b) =>
        a.display_name.localeCompare(b.display_name)
      ),
      radius_options: gon.radius_options
    }
  });

  app.start();
});
