import checkboxesOrNone from 'ui/form/checkboxes_or_none';
import { togglePanelCollapse } from 'helpers/views';
import { isDeepEmpty } from 'helpers/utils';

const CampaignTrafficTargetingView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '[name="campaign[traffic_target_attributes][levels][]"]': {
      observe: 'traffic_target',
      afterUpdate($el) {
        $el.trigger('change');
      }
    }
  },

  initialize() {
    checkboxesOrNone(this.$('.check_boxes[data-or-none]'));
    this.stickit();
    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get('traffic_target'))
    );
  }
});

export default CampaignTrafficTargetingView;
