import Header from 'campaigns/views/alerts/header';
import List from 'campaigns/views/alerts/list';

import template from 'templates/campaign_alerts/component.pug';
import emptyHeader from 'templates/campaign_alerts/header_empty.pug';
import loadingHeader from 'templates/campaign_alerts/header_loading.pug';

class Component extends Marionette.LayoutView {
  static initClass() {
    this.prototype.template = template;

    this.prototype.regions = {
      header_region: '.alerts-header',
      body_region: '.alerts-body'
    };

    this.prototype.ui = {
      header: '.alerts-header',
      body: '.alerts-body'
    };

    this.prototype.events = { 'click @ui.header': '_onHeaderClick' };

    this.prototype.modelEvents = { 'sync update reset': '_onModelUpdate' };
  }

  initialize(options = {}) {
    this.end_date = options.end_date;
    this.is_collapsed = true;
    this.$el.addClass('collapsed');

    this._createViews();

    return this.render();
  }

  _createViews() {
    this.header_item_view = new Header({
      model: this.model,
      end_date: this.end_date
    });

    this.header_empty_view = new Marionette.ItemView({
      template: emptyHeader
    });

    this.header_loading_view = new Marionette.ItemView({
      template: loadingHeader
    });

    return (this.body_view = new List({
      collection: this.model,
      end_date: this.end_date
    }));
  }

  onRender() {
    return this.header_region.show(this.header_loading_view);
  }

  _onModelUpdate() {
    if (this.model.length > 0) {
      this.header_region.show(this.header_item_view);
      return this.body_region.show(this.body_view);
    } else {
      return this.header_region.show(this.header_empty_view);
    }
  }

  _onHeaderClick() {
    if (this.model.length <= 0) {
      return;
    }

    this.is_collapsed = !this.is_collapsed;
    this.$el.toggleClass('collapsed', this.is_collapsed);
    if (this.is_collapsed) {
      return this.ui.body.slideUp('fast');
    } else {
      return this.ui.body.slideDown('fast');
    }
  }
}
Component.initClass();

export default Component;
