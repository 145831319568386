import toggleSelect from 'ui/form/toggle_select';
import { togglePanelCollapse } from 'helpers/views';
import { isDeepEmpty } from 'helpers/utils';

const CampaignNeighborhoodTargetingView = Marionette.ItemView.extend({
  template: false,

  ui: {
    toggleHomeRadius:
      '[name="toggle_campaign_neighbourhood_target_homeRadius"]',
    homeRadius:
      'select[name="campaign[neighbourhood_target_attributes][homeRadius]"]',
    toggleWorkRadius:
      '[name="toggle_campaign_neighbourhood_target_workRadius"]',
    workRadius:
      'select[name="campaign[neighbourhood_target_attributes][workRadius]"]',
    toggleAwayRadius:
      '[name="toggle_campaign_neighbourhood_target_awayRadius"]',
    awayRadius:
      'select[name="campaign[neighbourhood_target_attributes][awayRadius]"]',
    toggleSelect: 'div.toggle_select'
  },

  events: {
    change: 'onRadiusChange'
  },

  modelEvents: {
    'change:neighbourhood_target': 'renderInputs'
  },

  initialize() {
    this.bindUIElements();
    this.renderViews();
  },

  renderViews() {
    this.renderInputs();
    toggleSelect(this.ui.toggleSelect);
    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get('neighbourhood_target'))
    );
  },

  onRadiusChange() {
    this.model.set('neighbourhood_target', this.getSelectedRadii());
  },

  renderInputs() {
    const target = this.model.get('neighbourhood_target') || {};
    this.ui.toggleHomeRadius.prop('checked', target.homeRadius);
    this.ui.toggleWorkRadius.prop('checked', target.workRadius);
    this.ui.toggleAwayRadius.prop('checked', target.awayRadius);

    this.ui.homeRadius
      .val(target.homeRadius)
      .prop('disabled', target.homeRadius === 0);
    this.ui.workRadius
      .val(target.workRadius)
      .prop('disabled', target.workRadius === 0);
    this.ui.awayRadius
      .val(target.awayRadius)
      .prop('disabled', target.awayRadius === 0);
  },

  getSelectedRadii() {
    return {
      homeRadius: this.ui.toggleHomeRadius.is(':checked')
        ? +this.ui.homeRadius.val()
        : 0,
      workRadius: this.ui.toggleWorkRadius.is(':checked')
        ? +this.ui.workRadius.val()
        : 0,
      awayRadius: this.ui.toggleAwayRadius.is(':checked')
        ? +this.ui.awayRadius.val()
        : 0
    };
  }
});

export default CampaignNeighborhoodTargetingView;
