import DirtyWarning from 'views/dirty_warning';
import categorySelect from 'ui/form/category_select';
import { sendEvent } from '../shared/analytics';

const ACCOUNT_PAUSED_EVENT = 'accountPaused';

class App {
  constructor(options) {
    const $form = $(options.formEl);

    categorySelect($(options.categoryEl));

    new DirtyWarning({
      form: $form
    });

    const accountType = options.data.provider.account_type;
    const isStaff = options.data.user.staff;
    if (accountType === 'longtail' && !isStaff) {
      const message = I18n.t('providers.messages.pause');
      $(options.flashEl)
        .find('.alert-info')
        .each((_, ele) => {
          if (ele.innerText.search(message) >= 0) {
            sendEvent(ACCOUNT_PAUSED_EVENT);
          }
        });
    }
  }
}

export default App;
