import DisableIf from 'ui/form/disable_if';
import RadioToggleButtons from 'views/radio_toggle_buttons';
import DirtyWarning from 'views/dirty_warning';

class App {
  constructor(options) {
    const $form = $(options.formEl);

    new DirtyWarning({ form: $form });

    $(options.stopFormEl).submit(e => {
      if (!window.confirm(I18n.t('providers.admin_settings.stop_confirm'))) {
        e.preventDefault();
      }
    });

    $form.find('.radio_toggle').each(function () {
      new RadioToggleButtons({ el: this });
    });

    DisableIf($(options.varTypeEl), 'hide-if', {
      field: 'var_content',
      value: ''
    });

    $(options.varContentEl).on('change click', e => {
      toggleResellerOption();
    });

    toggleResellerOption();

    function toggleResellerOption() {
      const varContentVal = $(options.varContentEl)
        .find('option:selected')
        .text();
      if (varContentVal) {
        const access = options.accessList.find(x => {
          return x.email == varContentVal;
        });
        if (access && access.advar) {
          $(options.varTypeEl).find('option').show();
        } else {
          $(options.varTypeEl)
            .find('option')
            .filter((_, el) => el.value == 'RESELLER')
            .hide();
        }
      }
    }
  }
}

export default App;
