import moment from 'moment';
const DateCell = Backgrid.DateCell.extend({
  formatter: {
    fromRaw(val) {
      if (val == null) {
        return '';
      }

      return moment.utc(val).format(I18n.t('time.formats.momentjs'));
    }
  }
});

export default DateCell;
