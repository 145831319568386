import _ from 'underscore';
import 'd3';
import 'nvd3';
import linePlusMultiBarChart from 'ui/nv.models.linePlusMultiBarChart';

const NA_VALUE = -1;

export default function (element) {
  nv.dev = false; // turn off dev mode for NVD3

  $(element).each(function () {
    const $svg = $('svg', this);
    const $legend = $($(this).data('chart-legend'));
    const $checks = $(':checkbox', $legend);
    const $selected = $($(this).data('chart-fields'));
    let selected = $selected.val().split(',');
    const bars = $(this).data('chart-bars');
    const data = window.gon && window.gon.provider_chart;
    let fields;
    let chartData;
    let chart;

    if (data && $svg.length) {
      /* prepare data */
      fields = _.chain(data).values().first().keys().value();
      chartData = _.map(fields, name => {
        // check if field is available (has a legend checkbox)
        const $check = $checks.filter(`[data-chart-field=${name}]`);

        const available = $check.length > 0;
        const chartType = _.indexOf(bars, name) >= 0 ? 'bar' : 'line';

        // remember chart type
        $check.attr('data-chart-type', chartType);

        return {
          key: I18n.t(`reports.dates_report.ADS_PIN_INFO.fields.${name}`, {
            defaultValue: I18n.t(`reports.dates_report.fields.${name}`)
          }),
          values: [],
          disabled: !available || _.indexOf(selected, name) < 0,
          bar: chartType === 'bar'
        };
      });

      _.each(data, (report, date_str) => {
        // parsing dates is so much fun
        const date_parts = date_str.split('-');

        // so
        const y = date_parts[0];

        // let's
        const m = date_parts[1];

        // do it
        const d = date_parts[2];

        // yes
        const date = new Date(y, m - 1, d);

        _.each(fields, (f, i) => {
          const item = {
            x: date,
            y: report[fields[i]] || NA_VALUE
          };

          // cleanup
          if (NA_VALUE != item.y) {
            item.y = item.y.replace(/[^\d.-]/g, '');
          }

          // casting
          if (`${item.y}`.match(/\./)) {
            item.y = parseFloat(item.y, 10);
          } else {
            item.y = parseInt(item.y, 10);
          }

          // we mark N/A values as y=0 because we can't break the chart line
          // the user would be able to see that the value is N/A through the tooltip
          if (item.y == NA_VALUE) {
            item.y = 0;
            item.na = true;
          }

          chartData[i].values.push(item);
        });
      });

      /* initiate chart */
      nv.addGraph(() => {
        const valueFormat = d3.format(',.2r');

        chart = linePlusMultiBarChart();

        chart.xAxis.tickFormat(d => I18n.localize('date.formats.short', d));

        chart.yAxis1.tickFormat(valueFormat);
        chart.yAxis2.tickFormat(valueFormat);

        chart.bars.forceY([0]);

        d3.select($svg.get(0))
          .datum(chartData)
          .transition()
          .duration(500)
          .call(chart);

        nv.utils.windowResize(chart.update);

        return chart;
      });

      /* custom legend */
      $checks
        .on('change.chart_legend', function (e) {
          const field = $(this).data('chart-field');
          const index = _.indexOf(fields, field);

          // update chart
          chartData[index].disabled = !$(this).prop('checked');

          d3.select($svg.get(0))
            .datum(chartData)
            .transition()
            .duration(200)
            .call(chart);

          // update selected array and field
          selected = $checks
            .filter(':checked')
            .map(function () {
              return $(this).data('chart-field');
            })
            .get();
          $selected.val(selected.join(','));
        })
        .on('change.chart_legend_toggle', e => {
          const $checked = $checks.filter(':checked');

          $checked.prop('disabled', $checked.length === 1);
        })
        .on('change.chart_legend_class', function (e) {
          $(this).closest('li').toggleClass('active', $(this).prop('checked'));
        });

      $checks
        .filter(_.map(selected, f => `[data-chart-field=${f}]`).join(','))
        .prop('checked', true)
        .trigger('change.chart_legend_toggle')
        .trigger('change.chart_legend_class');
    }

    // prepare legend tooltips
    $('abbr', $legend).tooltip({
      placement: 'bottom',
      container: $legend
    });
  });
}
