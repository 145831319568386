import reportPage from '../providers/views/report_page';
import FormApp from './form';
import PreviewsApp from './preview';

$(() => {
  $('body.offers-index').each(function () {
    reportPage(this);
  });

  $('body.offers.form').each(() => {
    // tslint:disable-next-line:no-unused-expression
    new FormApp({
      previewsEl: '.previews-bar',
      formEl: 'form#new_offer, form#edit_offer',
      data: {
        offer: gon.offer!,
        offerKindsWithReminder: gon.offer_kinds_with_reminder!,
        previewLocation: gon.preview_location,
        isGuestProvider: gon.previews.is_guest_provider,
        isResellerOrStaff: gon.current_user
          ? gon.current_user.staff_or_reseller
          : false
      }
    });
  });

  $('body.offers-previews').each(() => {
    // tslint:disable-next-line:no-unused-expression
    new PreviewsApp({
      el: '.previews-bar-container',
      data: {
        location: gon.preview_location || {},
        offer: gon.preview_offer || {},
        reminder: gon.preview_reminder || {},
        locale: gon.preview_locale,
        provider: gon.provider!,
        isGuestProvider: gon.previews.is_guest_provider
      }
    });
  });
});
