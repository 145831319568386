Backgrid.HrefCell = Backgrid.UriCell.extend({
  className: 'href-cell',

  render() {
    const columnName = this.column.get('name');
    const rawValue = this.model.get(columnName);
    const formattedValue = this.formatter.fromRaw(rawValue, this.model);
    const href = Backgrid.callByNeed(this.column.get('href'), null, this.model);
    const anchor = $('<a>', { href }).text(formattedValue);
    this.$el.empty().append(anchor).addClass(columnName);
    return this.delegateEvents();
  }
});
