import Report from '../models/report';

interface ReportsOptions {
  type: string;
}

class Reports extends Backbone.Collection<Report> {
  type!: string;

  static initClass() {
    this.prototype.model = Report;
  }

  initialize(_models: Report[], options: ReportsOptions) {
    this.type = options.type;
  }
}
Reports.initClass();

export default Reports;
