import AdminSettingsApp from 'providers/admin_settings';
import NewFormApp from 'providers/new';
import EditFormApp from 'providers/edit';
import TrackingTagsApp from 'providers/tracking_tags';

$(() => {
  $('body.providers-admin_settings').each(() => {
    new AdminSettingsApp({
      formEl: 'form#edit_provider',
      stopFormEl: 'form.stop',
      varTypeEl: '.provider_var_type',
      varContentEl: '.provider_var_content',
      accessList: gon.access_list,
      provider: gon.provider
    });
  });

  $('body.providers.providers-new').each(() => {
    new NewFormApp({
      brandEl: '#provider_brand_id',
      categoryEl: 'select[rel=category]'
    });
  });

  $('body.providers.edit').each(() => {
    new EditFormApp({
      categoryEl: 'select[rel=category]',
      formEl: 'form#new_provider, form#edit_provider',
      flashEl: '.flash-messages',
      data: {
        provider: gon.provider,
        user: gon.current_user
      }
    });
  });

  $('body.providers-tracking_tags').each(() => {
    new TrackingTagsApp({
      campaignEl: '.campaign-input'
    });
  });
});
