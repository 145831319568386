import Form from 'budget_plans/views/form';
import Selector from 'budget_plans/views/selector';

const CampaignBudgetPlanView = Marionette.ItemView.extend({
  template: false,

  ui: {
    budgetPlanIdContainer: '.campaign_budget_plan_id',
    budgetPlanId: '#campaign_budget_plan_id',
    budgetPlanForm: '.budget_plan_form'
  },

  modelEvents: {
    change: '_toggleBudgetPlanSelector'
  },

  initialize(options) {
    this.mergeOptions(options, [
      'currentBudgetPlan',
      'budgetPlans',
      'emptyBudgetPlan',
      'earliestTimezoneOffsetMs',
      'latestTimezoneOffsetMs',
      'providerAccountType'
    ]);
    this.bindUIElements();
    this.renderViews();

    this.listenTo(
      this.currentBudgetPlan,
      'change:first_look',
      this._toggleBudgetPlanSelector
    );
  },

  renderViews() {
    this.initBudgetPlan();
    this._toggleBudgetPlanSelector();
  },

  initBudgetPlan() {
    if (this.ui.budgetPlanId.length) {
      new Selector({
        el: this.ui.budgetPlanId,
        model: this.model,
        budget_plans: this.budgetPlans,
        budget_plan: this.currentBudgetPlan,
        empty_budget_plan: this.emptyBudgetPlan,
        enable_link: this.ui.budgetPlanId.data('enable-link')
      });
    }

    new Form({
      el: this.ui.budgetPlanForm,
      model: this.currentBudgetPlan,
      campaign: this.model,
      earliestTimezoneOffsetMs: this.earliestTimezoneOffsetMs,
      latestTimezoneOffsetMs: this.latestTimezoneOffsetMs,
      isLocal: this.providerAccountType === 'longtail'
    });
  },

  _toggleBudgetPlanSelector() {
    this.ui.budgetPlanIdContainer.toggle(
      !this.model.isSearch() &&
        !this.model.isCategorySearch() &&
        !this.model.isIntent() &&
        !this.currentBudgetPlan.isFirstLook()
    );
  }
});

export default CampaignBudgetPlanView;
