import AlertsCollection from '../../collections/alerts';
import SelectableReportsCollection from '../../collections/selectable_reports';
import ReportsForm from '../../views/reports_form';
import ReportsTable from '../../views/reports_table';
import setupAjaxLogin from '../ajax_login';

export default function (
  element: JQuery,
  action: 'collection' | 'page' | { simple?: boolean } = {}
) {
  setupAjaxLogin();

  switch (action) {
    case 'collection':
      return $(element).data('reports_table').collection;
    case 'page':
      return $(element).data('reports_table').page;
    default:
      return $(element).each(function () {
        const $this = $(this);
        const $table = $('table', this);
        const $toolbar = $('.btn-toolbar', this);

        const simple = !!action.simple;

        const type = $table.data('table');

        // init dataTables (for non-empty tables)
        if ($('.no_matches', this).length === 0) {
          const reports = new SelectableReportsCollection([], { type });

          const tableView = new ReportsTable({
            el: $table.get(0),
            collection: reports,
            server_url: $table.data('server-url'),
            server_params: $table.data('additional-server-params'),
            edit_url: $table.data('edit-url'),
            completeCallback() {
              return $toolbar.addClass('dataTables');
            },
            simple
          });

          $this.data('reports_table', tableView);

          if (gon.show_alerts) {
            const alerts_url = $table.data('alerts-url');
            const alerts = new AlertsCollection([], { url: alerts_url });
            alerts.fetchAndSet(reports);
          }

          if (!simple) {
            // tslint:disable-next-line:no-unused-expression
            new ReportsForm({
              el: $toolbar,
              input: $(inputNameSelector(type), $toolbar),
              collection: reports.selected
            });

            reports.selected.on('change add remove reset', () =>
              $this.trigger('reports_table.filter', {
                type,
                selected: reports.selected.models
              })
            );
          }
        }
      });
  }
}

function inputNameSelector(type: string): string {
  let value = '';
  switch (type) {
    case 'pins':
      value = 'pin_ids';
      break;
    case 'campaigns':
      value = 'campaign_ids';
      break;
    default:
      value = 'pin_ids';
  }
  return `[name=${value}]`;
}
