var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, _, days, scheduleHourLabel, scheduleHourOption, weekStart) {pug_html = pug_html + "\u003Cfieldset class=\"form-group hours pull-left\"\u003E";
// iterate ['from', 'to']
;(function(){
  var $$obj = ['from', 'to'];
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var type = $$obj[pug_index0];
pug_html = pug_html + "\u003Clabel class=\"control-label pull-left\"\u003E" + (pug.escape(null == (pug_interp = I18n.t(type, {scope: 'schedule.form'})) ? "" : pug_interp)) + "\u003Cselect" + (pug.attr("class", pug.classes([type], [true]), false, true)+pug.attr("name", type, true, true)+pug.attr("id", type, true, true)) + "\u003E";
// iterate _.range(24)
;(function(){
  var $$obj = _.range(24);
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var hour = $$obj[pug_index1];
// iterate [0, 15, 30, 45]
;(function(){
  var $$obj = [0, 15, 30, 45];
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var minutes = $$obj[pug_index2];
let val = scheduleHourOption(hour, minutes)
pug_html = pug_html + "\u003Coption" + (pug.attr("value", val, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = scheduleHourLabel(hour, minutes)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var minutes = $$obj[pug_index2];
let val = scheduleHourOption(hour, minutes)
pug_html = pug_html + "\u003Coption" + (pug.attr("value", val, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = scheduleHourLabel(hour, minutes)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var hour = $$obj[pug_index1];
// iterate [0, 15, 30, 45]
;(function(){
  var $$obj = [0, 15, 30, 45];
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var minutes = $$obj[pug_index3];
let val = scheduleHourOption(hour, minutes)
pug_html = pug_html + "\u003Coption" + (pug.attr("value", val, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = scheduleHourLabel(hour, minutes)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var minutes = $$obj[pug_index3];
let val = scheduleHourOption(hour, minutes)
pug_html = pug_html + "\u003Coption" + (pug.attr("value", val, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = scheduleHourLabel(hour, minutes)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

    }
  }
}).call(this);

pug_html = pug_html + "\u003Coption value=\"24:00\"\u003E" + (pug.escape(null == (pug_interp = scheduleHourLabel(24, 0)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Flabel\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var type = $$obj[pug_index0];
pug_html = pug_html + "\u003Clabel class=\"control-label pull-left\"\u003E" + (pug.escape(null == (pug_interp = I18n.t(type, {scope: 'schedule.form'})) ? "" : pug_interp)) + "\u003Cselect" + (pug.attr("class", pug.classes([type], [true]), false, true)+pug.attr("name", type, true, true)+pug.attr("id", type, true, true)) + "\u003E";
// iterate _.range(24)
;(function(){
  var $$obj = _.range(24);
  if ('number' == typeof $$obj.length) {
      for (var pug_index4 = 0, $$l = $$obj.length; pug_index4 < $$l; pug_index4++) {
        var hour = $$obj[pug_index4];
// iterate [0, 15, 30, 45]
;(function(){
  var $$obj = [0, 15, 30, 45];
  if ('number' == typeof $$obj.length) {
      for (var pug_index5 = 0, $$l = $$obj.length; pug_index5 < $$l; pug_index5++) {
        var minutes = $$obj[pug_index5];
let val = scheduleHourOption(hour, minutes)
pug_html = pug_html + "\u003Coption" + (pug.attr("value", val, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = scheduleHourLabel(hour, minutes)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index5 in $$obj) {
      $$l++;
      var minutes = $$obj[pug_index5];
let val = scheduleHourOption(hour, minutes)
pug_html = pug_html + "\u003Coption" + (pug.attr("value", val, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = scheduleHourLabel(hour, minutes)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

      }
  } else {
    var $$l = 0;
    for (var pug_index4 in $$obj) {
      $$l++;
      var hour = $$obj[pug_index4];
// iterate [0, 15, 30, 45]
;(function(){
  var $$obj = [0, 15, 30, 45];
  if ('number' == typeof $$obj.length) {
      for (var pug_index6 = 0, $$l = $$obj.length; pug_index6 < $$l; pug_index6++) {
        var minutes = $$obj[pug_index6];
let val = scheduleHourOption(hour, minutes)
pug_html = pug_html + "\u003Coption" + (pug.attr("value", val, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = scheduleHourLabel(hour, minutes)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index6 in $$obj) {
      $$l++;
      var minutes = $$obj[pug_index6];
let val = scheduleHourOption(hour, minutes)
pug_html = pug_html + "\u003Coption" + (pug.attr("value", val, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = scheduleHourLabel(hour, minutes)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

    }
  }
}).call(this);

pug_html = pug_html + "\u003Coption value=\"24:00\"\u003E" + (pug.escape(null == (pug_interp = scheduleHourLabel(24, 0)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Flabel\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ffieldset\u003E\u003Cfieldset class=\"days pull-left form-inline\"\u003E";
// iterate _.range(weekStart, 7).concat(_.range(0, weekStart))
;(function(){
  var $$obj = _.range(weekStart, 7).concat(_.range(0, weekStart));
  if ('number' == typeof $$obj.length) {
      for (var pug_index7 = 0, $$l = $$obj.length; pug_index7 < $$l; pug_index7++) {
        var day = $$obj[pug_index7];
pug_html = pug_html + "\u003Clabel class=\"checkbox day\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = I18n.t("date.abbr_day_names")[day]) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cinput" + (" type=\"checkbox\""+pug.attr("value", day, true, true)+pug.attr("checked", _.contains(days, day), true, true)) + "\u003E\u003C\u002Flabel\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index7 in $$obj) {
      $$l++;
      var day = $$obj[pug_index7];
pug_html = pug_html + "\u003Clabel class=\"checkbox day\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = I18n.t("date.abbr_day_names")[day]) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cinput" + (" type=\"checkbox\""+pug.attr("value", day, true, true)+pug.attr("checked", _.contains(days, day), true, true)) + "\u003E\u003C\u002Flabel\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ffieldset\u003E\u003Cbutton" + (" class=\"btn btn-default remove-item\""+" type=\"button\""+pug.attr("title", I18n.t('schedule.form.delete'), true, true)) + "\u003E\u003Ci class=\"fa fa-minus-circle\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = I18n.t('schedule.form.delete')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"_" in locals_for_with?locals_for_with._:typeof _!=="undefined"?_:undefined,"days" in locals_for_with?locals_for_with.days:typeof days!=="undefined"?days:undefined,"scheduleHourLabel" in locals_for_with?locals_for_with.scheduleHourLabel:typeof scheduleHourLabel!=="undefined"?scheduleHourLabel:undefined,"scheduleHourOption" in locals_for_with?locals_for_with.scheduleHourOption:typeof scheduleHourOption!=="undefined"?scheduleHourOption:undefined,"weekStart" in locals_for_with?locals_for_with.weekStart:typeof weekStart!=="undefined"?weekStart:undefined));;return pug_html;};
module.exports = template;