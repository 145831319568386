import CampaignTagAssociation from '../../../models/campaign_tag_association';
import template from '../../../templates/campaign_tags/list.pug';
import Item from './item';

const List = Marionette.CompositeView.extend({
  template,

  childView: Item,

  childViewContainer: '.tag-list',

  ui: {
    add_button: 'button.add-item'
  },

  events: {
    'click @ui.add_button': 'onAdd'
  },

  childViewOptions() {
    return {
      available_tags: this.options.available_tags
    };
  },

  onAdd(e) {
    e.preventDefault();
    this.collection.add(new CampaignTagAssociation());
  }
});

export default List;
