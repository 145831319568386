import _ from 'underscore';
import DisableIf from 'ui/form/disable_if';

const CampaignSurveyView = Marionette.ItemView.extend({
  template: false,

  ui: {
    surveyFields: '.survey-fields',
    studyType: '[name="campaign[study_type]"]',
    brandQuestionWrapper: '.campaign_ad_recall_study_question',
    brandQuestion: '[name="campaign[ad_recall_study_attributes][question]"]',
    correctAnswer:
      '[name="campaign[ad_recall_study_attributes][correct_answer]"]',
    noneAnswer: '[name="campaign[ad_recall_study_attributes][none_answer]"]',
    firstWrongAnswer:
      '[name="campaign[ad_recall_study_attributes][wrong_answer_1]"]',
    secondWrongAnswer:
      '[name="campaign[ad_recall_study_attributes][wrong_answer_2]"]'
  },

  events: {
    change: 'onChange'
  },

  modelEvents: {
    'change:distribution_id': 'onDistributionIdChange',
    'change:study_type': 'onStudyTypeChange',
    'change:ad_recall_study': 'onAdRecallStudyChange'
  },

  initialize(options) {
    this.mergeOptions(options, ['distributions']);
    this.bindUIElements();
    this.renderViews();
  },

  renderViews() {
    this.initSurveyForm();

    DisableIf(this.ui.surveyFields, 'disable-if', {
      field: 'study_type',
      value: 'none'
    });

    DisableIf(this.ui.brandQuestionWrapper, 'hide-unless', {
      field: 'study_type',
      value: 'preference'
    });
  },

  initSurveyForm() {
    this.$el.hide();
    this.onDistributionIdChange();
  },

  onDistributionIdChange() {
    const distribution = _.findWhere(this.distributions, {
      id: +this.model.get('distribution_id')
    });
    const $studyType = this.ui.studyType;

    if (distribution && distribution.control_group > 0) {
      this.$el.show();
      const last_study_type = $studyType.data('last_study_type');

      if (last_study_type) {
        $studyType.val(last_study_type);
      }
    } else {
      this.$el.hide();
      $studyType.data('last_study_type', $studyType.val());
      $studyType.val('none');
    }
  },

  onStudyTypeChange() {
    const studyType = this.model.get('study_type');
    this.ui.studyType.val(studyType).trigger('change');
  },

  onAdRecallStudyChange() {
    const adRecallStudy = this.model.get('ad_recall_study');
    this.ui.brandQuestion.val(adRecallStudy.question);
    this.ui.correctAnswer.val(adRecallStudy.correct_answer);
    this.ui.noneAnswer.val(adRecallStudy.none_answer);
    this.ui.firstWrongAnswer.val(adRecallStudy.wrong_answer_1);
    this.ui.secondWrongAnswer.val(adRecallStudy.wrong_answer_2);
  },

  onChange() {
    const studyType = this.ui.studyType.val();
    const adRecallStudy = {
      question: this.ui.brandQuestion.prop('disabled')
        ? null
        : this.ui.brandQuestion.val(),
      correct_answer: this.ui.correctAnswer.val(),
      none_answer: this.ui.noneAnswer.val(),
      wrong_answer_1: this.ui.firstWrongAnswer.val(),
      wrong_answer_2: this.ui.secondWrongAnswer.val()
    };

    this.model.set({
      study_type: studyType,
      ad_recall_study: adRecallStudy
    });
  }
});

export default CampaignSurveyView;
