var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, _, location, offer) {pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["footer",offer.navigable ? 'navigable' : ''], [false,true]), false, true)) + "\u003E\u003Csection class=\"actions\"\u003E";
if (location.phone) {
pug_html = pug_html + "\u003Cdiv class=\"action\"\u003E\u003Cdiv class=\"icon phone\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"label\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('preview.info.call')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
if (location.url) {
pug_html = pug_html + "\u003Cdiv class=\"action\"\u003E\u003Cdiv class=\"icon website\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"label\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('preview.info.website')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"action\"\u003E\u003Cdiv class=\"icon edit\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"label\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('preview.info.edit')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fsection\u003E";
if (offer.navigable && !_.isEmpty(location.open_hours)) {
pug_html = pug_html + "\u003Csection class=\"hours\"\u003E\u003Cdiv class=\"title\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('preview.info.opening_hours')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"open-hours-container\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fsection\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"_" in locals_for_with?locals_for_with._:typeof _!=="undefined"?_:undefined,"location" in locals_for_with?locals_for_with.location:typeof location!=="undefined"?location:undefined,"offer" in locals_for_with?locals_for_with.offer:typeof offer!=="undefined"?offer:undefined));;return pug_html;};
module.exports = template;