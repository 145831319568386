import { isDeepEmpty } from '../../../helpers/utils';
import { togglePanelCollapse } from '../../../helpers/views';

interface OracleSegment {
  id: string;
  name: string;
}

export class OracleSegmentsTargetView extends Marionette.ItemView<Backbone.Model> {
  public stickit!: Function;
  public bindings!: { [key: string]: string };

  initialize() {
    this.ui = {
      targetedOracleSegments: '#campaign_target_oracle_segments'
    };

    this.bindings = {
      '#campaign_target_oracle_segments': 'target_oracle_segments'
    };
    super.bindUIElements();
    this.renderViews();
    this.stickit();
    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get('target_oracle_segments'))
    );
  }

  renderViews() {
    this.ui.targetedOracleSegments.addClass('select2-stickit').select2({
      data: this.ui.targetedOracleSegments
        .data('segments')
        .map((segment: OracleSegment) => ({
          id: segment.id,
          text: segment.name
        })),
      multiple: true,
      placeholder: ' ',
      allowClear: true
    });
  }
}
