import _ from 'underscore';
import renderTooltip from 'campaigns/views/spent/tooltip';
import toFlightData from 'campaigns/views/spent/to_flight_data';
import { UNSPENT_COLOR } from 'campaigns/views/spent/colors';

import template from 'templates/spent/progress_graph.pug';

const BAR_HEIGHT = 20;

const GOLDEN_RATIO = 1.618;

const GRAPH_OPTIONS = {
  chartArea: { width: '100%', height: '100%' },
  legend: { position: 'none' },
  tooltip: { isHtml: true, ignoreBounds: true },
  isStacked: true,
  focusTarget: 'category'
};

const removeNullValues = data =>
  _.chain(data)
    .unzip()
    .reject(column => _.rest(column).every(_.isNull))
    .unzip()
    .value();

const getDataTable = flightsData => {
  const chartData = flightsData.map(flight =>
    [flight.name, renderTooltip(flight)]
      .concat(flight.campaigns.map(c => c.spentInFlight))
      .concat(flight.unspent)
  );

  const header = ['name', { role: 'tooltip', p: { html: true } }]
    .concat(flightsData[0].campaigns.map(c => c.name))
    .concat('unspent');

  const data = removeNullValues([header].concat(chartData));
  return google.visualization.arrayToDataTable(data);
};

export default Marionette.LayoutView.extend({
  template,

  initialize() {
    this.listenTo(this.model, 'change', this.render);
  },

  ui: {
    graph: '.budget-progress-graph',
    bars: '.budget-progress-budget-bars'
  },

  onAttach() {
    this.chartWrapper = new google.visualization.ChartWrapper({
      chartType: 'BarChart'
    });
    this.render();
  },

  onRender() {
    if (!this.chartWrapper) {
      return;
    }

    const flightsData = toFlightData(this.model, this.options.activeCampaignId);

    const dataTable = getDataTable(flightsData);

    const colors = flightsData[0].campaigns.map(v => v.color);
    // add unspent color at correct index (- 2 to allow for 'name', 'tooltip')
    colors.splice(dataTable.getColumnIndex('unspent') - 2, 0, UNSPENT_COLOR);

    const maxValue = _.chain(flightsData)
      .map(flight => Math.max(flight.spent, flight.budget + flight.overflow))
      .max()
      .value();

    const options = _.defaults(
      {
        height: dataTable.getNumberOfRows() * BAR_HEIGHT,
        colors,
        hAxis: {
          gridlines: { count: 0 },
          viewWindowMode: 'explicit',
          viewWindow: { min: 0, max: maxValue }
        }
      },
      GRAPH_OPTIONS
    );

    this.chartWrapper.setDataTable(dataTable);
    this.chartWrapper.setOptions(options);
    this.chartWrapper.draw(this.ui.graph.get(0));

    this._renderBudgetBars(flightsData);
  },

  _renderBudgetBars(flightsData) {
    const layout = this.chartWrapper.getChart().getChartLayoutInterface();

    const budgetBars = flightsData.map((flight, i) =>
      $('<div>', {
        class: 'budget-progress-budget-bar',
        css: {
          top: layout.getYLocation(i) + BAR_HEIGHT / (2 * GOLDEN_RATIO),
          width: layout.getXLocation(flight.budget)
        }
      })
    );

    this.ui.bars.html(budgetBars);
  },

  selectCampaign(id) {
    if (!this.chart) {
      return;
    }

    const column = _.findIndex(this.model.get('campaigns'), { id }) + 2;
    const selected = this.chart.getSelection()[0];
    if (selected && selected.column == column) {
      this.chart.setSelection();
    } else {
      this.chart.setSelection([{ column }]);
    }
  }
});
