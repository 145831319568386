import mailcheck from './ui/form/mailcheck';

$(() => {
  $('body.users-new').each(() => {
    mailcheck($('[name="registration[email]"]'));

    $('.registration_password .controls').each(function () {
      const $passwordInput = $(this);

      const $visibilityToggleIcon = $('<i class="fa fa-eye fa-lg"></i>');
      const $visibilityToggle = $(
        '<a class="toggle-password-visibility-button" href="#"></a>'
      )
        .append($visibilityToggleIcon)
        .appendTo($passwordInput);

      // Pad the input a bit more to provide room for the toggle button.
      const $input = $passwordInput.find('input');
      $input.css('padding-right', '38px');

      $visibilityToggle.click(() => {
        // Decide if we now want to show the password, based on whether or not
        // the password is currently hidden.
        const showPassword = $input.attr('type') === 'password';

        $input.attr('type', showPassword ? 'text' : 'password');
        $visibilityToggleIcon.toggleClass('fa-eye', !showPassword);
        $visibilityToggleIcon.toggleClass('fa-eye-slash', showPassword);
      });
    });
  });
});
