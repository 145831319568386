import Item from 'campaigns/views/alerts/item';

import template from 'templates/campaign_alerts/list.pug';

class List extends Marionette.CollectionView {
  static initClass() {
    this.prototype.template = template;

    this.prototype.childView = Item;
    this.prototype.childViewContainer = '.alerts-body';

    this.prototype.collectionEvents = {
      remove: 'render',
      change: '_onChange'
    };
  }
  templateHelpers() {
    return { alertsCount: this.collection.length };
  }
  childViewOptions() {
    return { snooze_options: this.snooze_options };
  }

  initialize(options = {}) {
    this.snooze_options = options.snooze_options;
    return this.render();
  }

  _onChange() {
    this.collection.sort();
    return this.render();
  }
}
List.initClass();

export default List;
