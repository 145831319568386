import { isDeepEmpty } from '../../../helpers/utils';
import { togglePanelCollapse } from '../../../helpers/views';
import checkboxesOrNone from '../../../ui/form/checkboxes_or_none';

export class DemographicsTargetView extends Marionette.ItemView<Backbone.Model> {
  public stickit!: Function;
  public bindings!: { [key: string]: string };

  initialize() {
    this.ui = {
      targetGender: '.target-gender .check_boxes[data-or-none]',
      targetAge: '.target-age .check_boxes[data-or-none]'
    };
    this.bindings = {
      '[name="campaign[target_genders][]"]': 'target_genders',
      '[name="campaign[target_ages][]"]': 'target_ages'
    };

    super.bindUIElements();
    this.stickit();
    this.renderViews();

    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get('target_genders')) ||
        !isDeepEmpty(this.model.get('target_ages'))
    );
  }

  renderViews() {
    checkboxesOrNone(this.ui.targetGender);
    checkboxesOrNone(this.ui.targetAge);
    // Rebind to capture 'All' checkboxes
    super.bindUIElements();
  }
}
