import _ from 'underscore';
import Routes from '../helpers/routes';
import App from './app';

$('body.reports').each(() => {
  const provider = gon.provider;
  const providerId = provider!.id;
  const accountType = provider!.account_type;

  const app = new App({
    providerId,
    providerName: provider!.name,
    providerCategoryId: provider!.category_id,
    providerAccountType: accountType,
    brand_name: gon.brand_name,
    locationTags: gon.location_tags,
    currentUser: gon.current_user,
    exportPaths: {
      csv: Routes.reports_export_provider_path({
        id: providerId,
        format: 'csv'
      }),
      excel: Routes.reports_export_provider_path({
        id: providerId,
        format: 'xlsx'
      })
    },
    loggerConfig: {
      admiralUri: gon.config.admiral_uri,
      labels: _.pick(gon.config, 'site', 'version')
    }
  });

  app.start();
});
