import FormApp from './form';
import ListApp from './list';

$(() => {
  $('body.pins.form, body.pins.edit-multiple').each(() => {
    // tslint:disable-next-line:no-unused-expression
    new FormApp({
      el: 'form.pin',
      data: {
        pin: gon.pin || {}
      }
    });
  });

  $('body.pins-index').each(function () {
    // tslint:disable-next-line:no-unused-expression
    new ListApp({
      el: this,
      pinsMapEl: '#pins-map'
    });
  });
});
