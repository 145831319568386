import { isDeepEmpty } from '../../../helpers/utils';
import { togglePanelCollapse } from '../../../helpers/views';

export class BrandDestinationTargetView extends Marionette.ItemView<Backbone.Model> {
  public stickit!: Function;
  public bindings!: { [key: string]: string };

  initialize() {
    this.ui = {
      all: '#campaign_brand_destination_target_all'
    };

    this.bindings = {
      ':radio[name="campaign[brand_destination_target]"]':
        'brand_destination_target'
    };

    this.bindUIElements();
    this.stickit();

    // enable "All" checkbox when model is empty (no targeting)
    this.ui.all.prop('checked', !this.model.get('brand_destination_target'));

    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get('brand_destination_target'))
    );
  }
}
