import ProgressGraphView from 'campaigns/views/spent/progress_graph';
import { toCurrency } from 'helpers';

import template from 'templates/spent/progress.pug';
import { GoogleChartsService } from '../../../angular/core/common/google_charts_service';

const currencyDisplay = value => toCurrency(value, { precision: 2 });

export default Marionette.LayoutView.extend({
  template,

  regions: {
    graph: '.budget-progress'
  },

  initialize(options) {
    this.graphView = new ProgressGraphView({
      model: this.model,
      activeCampaignId: options.activeCampaignId
    });
  },

  onRender() {
    GoogleChartsService.promise
      .then(() => this.showChildView('graph', this.graphView))
      .catch(() => {});
  },

  selectCampaign(id) {
    this.graphView.selectCampaign(id);
  },

  serializeData(options) {
    const spent = currencyDisplay(this.model.get('spent') / 100);
    const budget = currencyDisplay(this.model.get('budget'));

    return {
      spent: I18n.t('providers.budget.format', { spent, budget }),
      activeCampaignId: this.options.activeCampaignId,
      campaignSpent: currencyDisplay(this.options.activeCampaignSpent / 100)
    };
  }
});
