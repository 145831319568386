import { isDeepEmpty } from '../../../helpers/utils';
import { togglePanelCollapse } from '../../../helpers/views';

const ATTRIBUTE_NAME = 'navigation_history_target';

export class NavigationHistoryTargetView extends Marionette.ItemView<Backbone.Model> {
  initialize() {
    this.ui = {
      atLeast: '#campaign_navigation_history_target_attributes_atLeast',
      atMost: '#campaign_navigation_history_target_attributes_atMost',
      lookbackWindow:
        '#campaign_navigation_history_target_attributes_lookbackWindow',
      all: '#campaign_navigation_history_target_attributes_navigation_history_state_all',
      never:
        '#campaign_navigation_history_target_attributes_navigation_history_state_never',
      between:
        '#campaign_navigation_history_target_attributes_navigation_history_state_between',
      state:
        'input[name="campaign[navigation_history_target_attributes][navigation_history_state]"]'
    };

    this.bindUIElements();

    this.updateNavigationHistoryFields();

    togglePanelCollapse(this.$el, !isDeepEmpty(this.model.get(ATTRIBUTE_NAME)));
  }

  events() {
    return {
      change: this.onViewChange
    };
  }

  onViewChange() {
    const checked = this.ui.state.filter(':checked').val();

    switch (checked) {
      case 'all':
        this.setInputsState('all');
        this.model.set(ATTRIBUTE_NAME, null);
        break;
      case 'never':
        this.setInputsState('never');
        this.model.set(ATTRIBUTE_NAME, {
          lookbackWindow: this.ui.lookbackWindow.val(),
          atMost: 0,
          atLeast: -1
        });
        break;
      case 'between':
        this.setInputsState('between');
        this.model.set(ATTRIBUTE_NAME, {
          lookbackWindow: this.ui.lookbackWindow.val(),
          atMost: this.ui.atMost.val(),
          atLeast: this.ui.atLeast.val()
        });
        break;
      default:
        break;
    }
  }

  private updateNavigationHistoryFields() {
    const navigationHistory = this.model.get(ATTRIBUTE_NAME) || {};
    if (Object.keys(navigationHistory).length === 0) {
      this.ui.all.prop('checked', true);
      this.setInputsState('all');
    } else if (!navigationHistory.atLeast && navigationHistory.atMost === 0) {
      this.ui.lookbackWindow.val(navigationHistory.lookbackWindow);
      this.ui.never.prop('checked', true);
      this.setInputsState('never');
      this.ui.atMost.val(null);
    } else {
      this.ui.lookbackWindow.val(navigationHistory.lookbackWindow);
      this.ui.between.prop('checked', true);
      this.ui.atLeast.val(navigationHistory.atLeast);
      this.ui.atMost.val(navigationHistory.atMost);
      this.setInputsState('between');
    }
  }

  private setInputsState(state: 'all' | 'never' | 'between') {
    this.ui.lookbackWindow.prop('disabled', state === 'all');
    this.ui.atMost.prop('disabled', state !== 'between');
    this.ui.atLeast.prop('disabled', state !== 'between');
  }
}
