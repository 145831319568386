import ListApp from 'budget_plans/list';
import FormApp from 'budget_plans/form';

$(function () {
  $('body.budget_plans.form').each(function () {
    new FormApp({ el: this });
  });

  $('.budget-plans-table').each(function () {
    new ListApp({ el: this });
  });
});
