interface CampaignChoice {
  id: number;
  provider_id: number;
  text: string;
}

class App {
  constructor(options: { campaignEl: HTMLElement }) {
    if (!gon.campaigns) {
      throw new Error('Missing campaigns data'); // should never happen
    }

    $(options.campaignEl)
      .select2({
        data: gon.campaigns
          .filter(
            // Only keep pin, takeover, and arrow campaigns.
            campaign =>
              campaign.channel === 'ADS_PIN_INFO' ||
              campaign.channel === 'ADS_0SPEED_INFO' ||
              campaign.channel === 'ADS_ARROW_NEARBY_INFO'
          )
          .map(campaign => {
            return {
              id: campaign.id,
              provider_id: campaign.provider_id,
              text: `${campaign.name} (${campaign.id})`
            };
          }),
        placeholder: I18n.t('providers.tracking_tags.campaign_placeholder')
      })
      .on('select2-selecting', event => {
        event.preventDefault();

        const campaign = event.choice as CampaignChoice;
        window.location.href =
          `/app/providers/${campaign.provider_id}` +
          `/campaigns/${campaign.id}/tracking_tags`;
      });
  }
}

export default App;
