var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, isStaff, numEntriesOptions) {pug_html = pug_html + "\u003Cselect class=\"page-size\"\u003E";
// iterate numEntriesOptions
;(function(){
  var $$obj = numEntriesOptions;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var value = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", value, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.show_x_entries', {count: value})) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var value = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", value, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.show_x_entries', {count: value})) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003Cdiv class=\"archived boolean-input\"\u003E\u003Clabel\u003E\u003Cinput type=\"radio\" name=\"archived\" value=\"0\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.active')) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003Clabel\u003E\u003Cinput type=\"radio\" name=\"archived\" value=\"1\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.archived')) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"mine boolean-input\"\u003E\u003Clabel\u003E\u003Cinput type=\"radio\" name=\"mine\" value=\"1\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.mine')) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E";
if (isStaff) {
pug_html = pug_html + "\u003Clabel\u003E\u003Cinput type=\"radio\" name=\"mine\" value=\"0\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.all')) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"search\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"archive-button\"\u003E\u003C\u002Fdiv\u003E\u003Ci class=\"spinner fa fa-spinner fa-spin fa-2x\"\u003E\u003C\u002Fi\u003E\u003Cdiv class=\"add\"\u003E\u003Cdiv class=\"btn-group\"\u003E\u003Ca class=\"add-button btn btn-info\" href=\"\u002Fpredictions\u002Fnew\"\u003E\u003Ci class=\"fa fa-plus\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.new')) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"isStaff" in locals_for_with?locals_for_with.isStaff:typeof isStaff!=="undefined"?isStaff:undefined,"numEntriesOptions" in locals_for_with?locals_for_with.numEntriesOptions:typeof numEntriesOptions!=="undefined"?numEntriesOptions:undefined));;return pug_html;};
module.exports = template;