var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, file_link, radius_options) {pug_html = pug_html + "\u003Cdiv class=\"alert alert-info\"\u003E\u003Ci class=\"fa fa-comment\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.locations.file.tab.comment')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"alert alert-warning existing-file\"\u003E\u003Ci class=\"fa fa-comment\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.locations.file.tab.existing_file')) ? "" : pug_interp)) + "\u003Ca" + (" class=\"location_file_link\""+pug.attr("href", file_link, true, true)+" download=\"\"") + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.locations.file.tab.existing_file_link')) ? "" : pug_interp)) + "\u003C\u002Fa\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.locations.file.tab.replace_file')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group required radius\"\u003E\u003Clabel class=\"required control-label\" for=\"radius\"\u003E\u003Cabbr title=\"required\"\u003E*\u003C\u002Fabbr\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.locations.radius.label')) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003Cdiv class=\"controls\"\u003E\u003Cselect class=\"required form-control input-short\" id=\"radius\" name=\"radius\" required\u003E";
// iterate radius_options
;(function(){
  var $$obj = radius_options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var option = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('campaigns.form.radius_labels.' + option)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var option = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", option, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('campaigns.form.radius_labels.' + option)) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group required upload_file\"\u003E\u003Clabel class=\"required control-label\" for=\"upload_file\"\u003E\u003Cabbr title=\"required\"\u003E*\u003C\u002Fabbr\u003E" + (pug.escape(null == (pug_interp = I18n.t('predictions.locations.file.tab.input_label')) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003Cdiv class=\"controls\"\u003E\u003Cinput class=\"required form-control\" id=\"upload_file\" name=\"upload_file\" required accept=\".csv\" type=\"file\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"upload-alert\"\u003E\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"file_link" in locals_for_with?locals_for_with.file_link:typeof file_link!=="undefined"?file_link:undefined,"radius_options" in locals_for_with?locals_for_with.radius_options:typeof radius_options!=="undefined"?radius_options:undefined));;return pug_html;};
module.exports = template;