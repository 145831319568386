import AlertsCollection from 'collections/alerts';
import BasicDetailsView from 'campaigns/views/campaign_basic_details_view';
import BudgetPlanView from 'campaigns/views/campaign_budget_plan_view';
import BidView from 'campaigns/views/campaign_bid_view';
import LocationsView from 'campaigns/views/campaign_locations_view';
import SearchSettingsView from 'campaigns/views/campaign_search_settings_view';
import TargetingLayout from 'campaigns/views/targeting/campaign_targeting_layout';
import SurveyView from 'campaigns/views/campaign_survey_view';
import DisplayOptionsView from 'campaigns/views/campaign_display_options_view';
import SpentView from 'campaigns/views/campaign_spent_view';
import AlertsComponent from 'campaigns/views/alerts/component';

import DisableIf from 'ui/form/disable_if';
import DirtyWarning from 'views/dirty_warning';

const FormLayout = Marionette.LayoutView.extend({
  template: false,

  ui: {
    details: '#details',
    budgetPlan: '#budget_plan',
    budgetField: '.budget_inputs_wrapper',
    bid: '#bid',
    locations: '#locations',
    categorySearchDetails: '#category_search_details',
    displayOptions: '#display_options',
    searchSettings: '#search_target',
    targeting: '#targeting',
    surveys: '#surveys',
    io: '#io',
    spent: '.spent',
    alerts: '.alerts'
  },

  initialize(options) {
    this.mergeOptions(options, [
      'budgetPlans',
      'emptyBudgetPlan',
      'currentBudgetPlan',
      'distributions',
      'channelsWithoutPrice',
      'earliestTimezoneOffsetMs',
      'latestTimezoneOffsetMs',
      'firstLookCpm',
      'providerAccountType'
    ]);
  },

  onRender() {
    new BasicDetailsView({
      el: this.ui.details,
      model: this.model,
      currentBudgetPlan: this.currentBudgetPlan,
      distributions: this.distributions,
      firstLookCpm: this.firstLookCpm
    });

    new BudgetPlanView({
      el: this.ui.budgetPlan,
      model: this.model,
      currentBudgetPlan: this.currentBudgetPlan,
      budgetPlans: this.budgetPlans,
      emptyBudgetPlan: this.emptyBudgetPlan,
      earliestTimezoneOffsetMs: this.earliestTimezoneOffsetMs,
      latestTimezoneOffsetMs: this.latestTimezoneOffsetMs,
      providerAccountType: this.providerAccountType
    });

    new BidView({
      el: this.ui.bid,
      model: this.model,
      currentBudgetPlan: this.currentBudgetPlan
    });

    new LocationsView({
      el: this.ui.locations,
      model: this.model
    });

    new SearchSettingsView({
      el: this.ui.searchSettings,
      model: this.model
    });

    new DisplayOptionsView({
      el: this.ui.displayOptions,
      model: this.model
    });

    new TargetingLayout({
      el: this.ui.targeting,
      model: this.model
    });

    if (this.ui.surveys.length) {
      new SurveyView({
        el: this.ui.surveys,
        model: this.model,
        distributions: this.distributions
      });
    }

    if (this.ui.spent.length) {
      new SpentView({
        el: this.ui.spent,
        model: this.currentBudgetPlan,
        campaign: this.model
      });
    }

    this.initAlerts();
    this.initDisableIf();
    this.initDirtyForm();
  },

  initAlerts() {
    const $alerts = this.ui.alerts;
    if ($alerts.length) {
      const alerts_collection = new AlertsCollection([], {
        url: $alerts.data('url')
      });
      alerts_collection.fetch();
      new AlertsComponent({
        el: $alerts,
        model: alerts_collection,
        end_date: this.model.get('end_date')
      });
    }
  },

  initDisableIf() {
    DisableIf(this.ui.bid, 'hide-if', {
      field: 'channel',
      value: this.channelsWithoutPrice
    });

    DisableIf(this.ui.displayOptions, 'hide-unless', {
      field: 'channel',
      value: ['ADS_0SPEED_INFO', 'ADS_ARROW_NEARBY_INFO', 'ADS_INTENT_INFO']
    });

    DisableIf(this.ui.searchSettings, 'hide-unless', {
      field: 'channel',
      value: ['ADS_LINE_SEARCH_INFO']
    });

    DisableIf(this.ui.budgetField, 'hide-if', {
      field: 'channel',
      value: ['ADS_LINE_SEARCH_INFO', 'ADS_INTENT_INFO']
    });

    DisableIf(this.ui.categorySearchDetails, 'hide-unless', {
      field: 'channel',
      value: ['ADS_PREMIUM_CATEGORY_SEARCH']
    });
  },

  initDirtyForm() {
    new DirtyWarning({
      form: this.$el,
      model: this.model
    });
  }
});

export default FormLayout;
