import Routes from 'helpers/routes';
import { toCurrency } from 'helpers';

import template from 'templates/spent/table.pug';
import rowTemplate from 'templates/spent/row.pug';

export default Marionette.CompositeView.extend({
  template,
  childViewContainer: 'tbody',
  childViewOptions() {
    return {
      providerId: this.options.providerId
    };
  },
  childView: Marionette.LayoutView.extend({
    template: rowTemplate,
    tagName: 'tr',
    templateHelpers() {
      return {
        Routes,
        providerId: this.options.providerId,
        toCurrency
      };
    },
    events: {
      click: '_onRowClicked'
    },
    _onRowClicked(e) {
      // Using instead of Marionette's 'trigger' property since triggers swallow
      // the event and the <a> inside the row doesn't work
      this.triggerMethod('click:row');
    }
  })
});
