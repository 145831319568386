import _ from 'underscore';
import Campaign from '../models/campaign';
import CellularNetwork from '../models/cellular_network';
import template from '../templates/campaigns/carrier_select.pug';

type EscapeFn = (markup: string) => string;

// TODO(b/130036754): bring interface from model?
interface CarrierData {
  mcc: number;
  mnc: number;
  country: string;
  network: string;
}

type CarriersByCountry = {
  [country: string]: {
    [network: string]: {
      name: string;
      country: string;
      carriers: { mcc: number; mnc: number }[];
    };
  };
};

class CarrierSelect extends Marionette.ItemView<Campaign> {
  template!: typeof template;

  carrier_list!: CarrierData[];
  carrier_list_as_tree!: CarriersByCountry;
  initial_networks!: { country: string; name: string }[];
  selected_country?: string;

  static initClass() {
    this.prototype.template = template;

    this.prototype.ui = {
      country_select: '.country-select',
      network_select: '.network-select',
      carriers_input: '+ .carrier-select-input'
    };
  }

  events() {
    return {
      'change @ui.country_select': 'onCountryChange',
      'change @ui.network_select': 'onNetworkChange'
    };
  }

  initialize() {
    this.carrier_list = this.$el.data('carrier-list');

    this.carrier_list_as_tree = _(this.carrier_list).reduce(
      (memo: CarriersByCountry, c) => {
        memo[c.country] = memo[c.country] || {};
        memo[c.country][c.network] = memo[c.country][c.network] || {};
        memo[c.country][c.network].country = c.country;
        memo[c.country][c.network].name = c.network;
        memo[c.country][c.network].carriers =
          memo[c.country][c.network].carriers || [];
        memo[c.country][c.network].carriers.push({ mcc: c.mcc, mnc: c.mnc });
        return memo;
      },
      {}
    );

    this.initial_networks = _(
      this.model.get('cellular_targeting')
        ? this.model.get('cellular_targeting').networks
        : []
    )
      .chain()
      .map(network =>
        _(this.carrier_list).where({ mcc: network.mcc, mnc: network.mnc })
      )
      .flatten()
      .map(carrier => ({ country: carrier.country, name: carrier.network }))
      .uniq(obj => obj.name)
      .value();
  }

  onRender() {
    this.initCountrySelect();
    this.initNetworkSelect();
  }

  onCountryChange(event: JQuery.Event<HTMLInputElement>) {
    this.selected_country = event.target.value;
  }

  onNetworkChange() {
    const carriers = _(this.ui.network_select.select2('data'))
      .chain()
      .map(network => network.get('carriers'))
      .flatten()
      .value();
    this.ui.carriers_input.val(JSON.stringify(carriers));
    this.trigger('change:network', carriers);
  }

  initCountrySelect() {
    this.ui.country_select.select2({
      placeholder: I18n.t('campaigns.form.cellular_targeting.country'),
      width: 'auto'
    });

    const countries = this.initial_networks.map(network => network.country);
    const initial_country =
      _.unique(countries).length === 1 ? countries[0] : undefined;

    this.ui.country_select.val(initial_country).trigger('change');
  }

  initNetworkSelect() {
    this.ui.network_select.select2({
      placeholder: I18n.t('campaigns.form.cellular_targeting.carriers'),
      multiple: true,
      data: () => {
        return {
          results: _.map(
            this.carrier_list_as_tree[this.selected_country!],
            network => new CellularNetwork(network)
          )
        };
      },
      matcher: (term: string, _text: string, network: CellularNetwork) => {
        const name = network.get('name') || '';
        return name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
      },
      formatResult(
        result: CellularNetwork,
        _c: JQuery,
        _q: string,
        escapeMarkup: EscapeFn
      ) {
        return escapeMarkup(result.uniqueName());
      },
      formatSelection(
        item: CellularNetwork,
        _: string,
        escapeMarkup: EscapeFn
      ) {
        return escapeMarkup(item.uniqueName());
      }
    });

    const initial_networks = this.initial_networks.map(network => {
      return new CellularNetwork(
        this.carrier_list_as_tree[network.country][network.name]
      );
    });

    this.ui.network_select.select2('data', initial_networks);
  }

  serializeData() {
    return _.extend(this.model.toJSON(), {
      countries: Object.keys(this.carrier_list_as_tree).sort()
    });
  }

  toggleDisabled(disable: boolean) {
    this.ui.country_select.prop('disabled', disable);
    this.ui.network_select.select2('enable', !disable);
    this.ui.carriers_input.prop('disabled', disable);
  }
}
CarrierSelect.initClass();

export default CarrierSelect;
