import reportsTable from 'ui/table/reports_table';
import reportFilters from 'providers/views/report_filters';
import providerChart from 'providers/views/chart';

export default function (element) {
  // initiate table
  const $table = $('.reports-list-wrapper', element);
  reportsTable($table);

  // initiate filter form
  const $filter = $('form.filter', element);
  reportFilters($filter);

  // initiate chart
  providerChart($('.provider-chart', element));

  // sync table with filter form
  $table.on('reports_table.filter', (e, selected) =>
    $filter.trigger('reports_table.filter', [selected])
  );

  return $table;
}
