/* tslint:disable-next-line:no-implicit-dependencies reason: Gem dependency */
import Routes from 'js-routes';

import { mapObject } from 'underscore';

type RouteMap = { [k: string]: (v?: {}) => string };

function addLocaleParam(options: {}) {
  if (I18n.defaultLocale !== I18n.locale) {
    return Object.assign({ locale: I18n.locale }, options);
  } else {
    return options;
  }
}

function wrapRouteFunction(routeFn: (obj: {}) => {}) {
  return (options: {}) => {
    return routeFn(
      Object.assign(addLocaleParam(options || {}), { _options: true })
    );
  };
}

export default mapObject(Routes, wrapRouteFunction) as RouteMap;
