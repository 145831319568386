var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, canSave, isLoading, validQuery) {pug_html = pug_html + "\u003Cdiv class=\"btn-group\"\u003E\u003Cbutton" + (pug.attr("class", pug.classes(["btn","btn-default","dropdown-toggle",isLoading ? 'disabled' : ''], [false,false,false,true]), false, true)+" type=\"button\" data-toggle=\"dropdown\"") + "\u003E";
if (isLoading) {
pug_html = pug_html + "\u003Ci class=\"fa fa-spinner fa-spin\"\u003E\u003C\u002Fi\u003E";
}
else {
pug_html = pug_html + "\u003Ci class=\"fa fa-folder-open\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"sr-only\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.index.query_templates.button')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan class=\"caret\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Cul class=\"dropdown-menu\"\u003E\u003Cli\u003E\u003Cul class=\"dropdown-menu dropdown-menu-internal\"\u003E\u003Cli" + (pug.attr("class", pug.classes([canSave ? '' : 'disabled'], [true]), false, true)) + "\u003E\u003Ca class=\"save\" href=\"#\"\u003E\u003Ci class=\"fa fa-save\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.index.query_templates.save')) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli" + (pug.attr("class", pug.classes([validQuery ? '' : 'disabled'], [true]), false, true)) + "\u003E\u003Ca class=\"save-as\" href=\"#\"\u003E\u003Ci class=\"fa fa-save\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.index.query_templates.save_as')) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Cul class=\"preset-templates dropdown-menu dropdown-menu-internal\"\u003E\u003C\u002Ful\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Cul class=\"custom-templates dropdown-menu dropdown-menu-internal\"\u003E\u003C\u002Ful\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"canSave" in locals_for_with?locals_for_with.canSave:typeof canSave!=="undefined"?canSave:undefined,"isLoading" in locals_for_with?locals_for_with.isLoading:typeof isLoading!=="undefined"?isLoading:undefined,"validQuery" in locals_for_with?locals_for_with.validQuery:typeof validQuery!=="undefined"?validQuery:undefined));;return pug_html;};
module.exports = template;