import 'Select2';
import _ from 'underscore';
import Routes from '../../helpers/routes';
import linkTemplate from '../../templates/campaigns/selector_popup_link.pug';

class Selector extends Marionette.ItemView {
  static initClass() {
    this.prototype.template = false;

    this.prototype.modelEvents = {
      'change:budget_plan_id': '_setBudgetPlanAttributes',
      'change:channel': '_onChannelChange'
    };

    this.prototype.events = {
      change: '_changeBudgetPlanId'
    };
  }

  initialize(options) {
    this.budget_plan = options.budget_plan;
    this.budget_plans = options.budget_plans;
    this.empty_budget_plan = options.empty_budget_plan;
    this.enable_link = options.enable_link;

    this.$el.select2({
      allowEmpty: false
    });

    this.render();
  }

  onRender() {
    this.$linkEl = $('<div>')
      .addClass('budget-plan-popup-link')
      .insertAfter(this.$el);
    this._setBudgetPlanAttributes();
  }

  _changeBudgetPlanId() {
    this.model.set('budget_plan_id', this.$el.select2('val'));
  }

  _setBudgetPlanAttributes() {
    const selected = this._getSelected();
    if (!selected) {
      return;
    }
    this._updateSelectField();
    if (this.budget_plan.id !== selected.id) {
      this.budget_plan.set(this.budget_plan.parse(selected));
    }
    this._renderLink(selected);
  }

  _onChannelChange() {
    if (
      this.model.isSearch() ||
      this.model.isCategorySearch() ||
      this.model.isIntent()
    ) {
      this.model.set('budget_plan_id', '');
    }
  }

  _getSelected() {
    const id = this.model.get('budget_plan_id');
    if (id === '') {
      return this.empty_budget_plan;
    } else {
      return _.find(this.budget_plans, { id: +id });
    }
  }

  _updateSelectField() {
    this.$el.val(this.model.get('budget_plan_id')).trigger('change.select2');
  }

  _renderLink(selected) {
    let url;
    if (selected && selected.id) {
      url = Routes.provider_budget_plan_path({
        provider_id: selected.provider_id,
        id: selected.id
      });
    }

    this.$linkEl
      .toggle(this.enable_link && selected && selected.id != null)
      .html(linkTemplate({ url }));
  }
}
Selector.initClass();

export default Selector;
