import _ from 'underscore';
import CarrierSelect from 'views/carrier_select';
import { togglePanelCollapse } from 'helpers/views';
import { isDeepEmpty } from 'helpers/utils';

const CampaignCarrierTargetingView = Marionette.ItemView.extend({
  template: false,

  ui: {
    carrierSelectContainer: '.carrier-select-container',
    networkSelect: '.network-select',
    cellularTargetingType: '#campaign_cellular_targeting_type',
    cellularTargetingAttributesWhitelist:
      '#campaign_cellular_targeting_attributes_whitelist'
  },

  events: {
    'change @ui.cellularTargetingType': 'onTargetingTypeChange'
  },

  initialize() {
    this.bindUIElements();
    this.renderViews();
  },

  renderViews() {
    this.renderTargetingTypeSelect();
    this.renderCarrierSelectView();
    const cellularTargeting = this.model.get('cellular_targeting');
    togglePanelCollapse(
      this.$el,
      !!cellularTargeting && !isDeepEmpty(cellularTargeting.networks)
    );
  },

  renderTargetingTypeSelect() {
    const target = this.model.get('cellular_targeting');
    let value;
    if (!target || _.isEmpty(target.networks)) {
      value = 'none';
    } else if (target.whitelist) {
      value = 'include';
    } else {
      value = 'exclude';
    }
    this.ui.cellularTargetingType.val(value);
  },

  renderCarrierSelectView() {
    const carrier_select_el = this.ui.carrierSelectContainer;
    if (carrier_select_el.length > 0) {
      this.carrier_select = new CarrierSelect({
        el: carrier_select_el,
        model: this.model
      });

      this.listenTo(
        this.carrier_select,
        'change:network',
        this.onCarrierSelectNetworkChange
      );

      this.carrier_select.render();
      this.carrier_select.toggleDisabled(
        this.ui.cellularTargetingType.val() == 'none'
      );
    }
  },

  onTargetingTypeChange() {
    const targetingType = this.ui.cellularTargetingType.val();
    const $cellularTargetingWhitelist =
      this.ui.cellularTargetingAttributesWhitelist;

    let disableFields = false;
    let whitelistVal = false;

    switch (targetingType) {
      case 'include':
        whitelistVal = true;
        break;
      case 'none':
        whitelistVal = $cellularTargetingWhitelist.val();
        disableFields = true;
        break;
    }

    $cellularTargetingWhitelist
      .val(whitelistVal)
      .prop('disabled', disableFields);
    if (this.carrier_select) {
      this.carrier_select.toggleDisabled(disableFields);
    }

    const target = _.clone(this.model.get('cellular_targeting')) || {};
    target.whitelist = $cellularTargetingWhitelist.val() == 'true';
    this.model.set('cellular_targeting', target);
  },

  onCarrierSelectNetworkChange(networks) {
    const target = _.clone(this.model.get('cellular_targeting')) || {};
    target.networks = networks;
    this.model.set('cellular_targeting', target);
  }
});

export default CampaignCarrierTargetingView;
