var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, limitOptions) {pug_html = pug_html + "\u003Cselect name=\"report_query[limit]\"\u003E\u003Coption disabled\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.advanced.options.number_of_result')) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
// iterate limitOptions
;(function(){
  var $$obj = limitOptions;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var value = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", value, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.advanced.options.count_per_page', {count: value})) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var value = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", value, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.advanced.options.count_per_page', {count: value})) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"limitOptions" in locals_for_with?locals_for_with.limitOptions:typeof limitOptions!=="undefined"?limitOptions:undefined));;return pug_html;};
module.exports = template;