import Backgrid from 'backgrid';
import moment, { DurationInputArg1, DurationInputArg2, Moment } from 'moment';
import _ from 'underscore';

class DatepickerCellEditor extends Backgrid.InputCellEditor {
  formatter!: DatepickerCellFormatter;

  events() {
    // Override Backgrid blur event so that datepicker can set date
    return {};
  }

  postRender(model: Backbone.Model, column: Backbone.Model) {
    const startDate = moment.utc(this.formatter.options.minDate(this.model));
    const endDate = moment.utc(this.formatter.options.maxDate(this.model));

    this.$el.datepicker({
      language: I18n.locale,
      startDate: startDate.format(this.formatter.options.displayFormat),
      endDate: endDate.format(this.formatter.options.displayFormat),
      autoclose: true
    });

    // Manually call backgrid save
    this.$el.on('hide', e => {
      return this.saveOrCancel(_.extend({}, e, { type: 'blur' }));
    });

    return super.postRender(model, column);
  }
}

interface DatepickerOffsetParams {
  //  The duration to be offset by
  value: DurationInputArg1;

  //  The units days/months etc to be offset by
  units: DurationInputArg2;
}

interface DatepickerFormatterOptions {
  minDate: (model: Backbone.Model) => Date;
  maxDate: (model: Backbone.Model) => Date;
  offset: DatepickerOffsetParams;
  modelFormat: string;
  displayFormat: string;
}

class DatepickerCellFormatter extends Backgrid.CellFormatter {
  constructor(public options: DatepickerFormatterOptions) {
    super();
  }

  toRaw(value: number, _model: Backbone.Model) {
    return this.addOffset(moment.utc(value, this.options.displayFormat)).format(
      this.options.modelFormat
    );
  }

  fromRaw(value: string, _model: Backbone.Model) {
    return this.subtractOffset(
      moment.utc(value, this.options.modelFormat)
    ).format(this.options.displayFormat);
  }

  addOffset(value: Moment) {
    const newValue = value.clone();
    return this.options.offset
      ? newValue.add(this.options.offset.value, this.options.offset.units)
      : newValue;
  }

  subtractOffset(value: Moment) {
    const newValue = value.clone();
    return this.options.offset
      ? newValue.subtract(this.options.offset.value, this.options.offset.units)
      : newValue;
  }
}

class DatepickerCell extends Backgrid.StringCell {
  column!: Backbone.Model;

  // @ts-ignore TODO(b/129067522): missing Backgrid typing
  editor = DatepickerCellEditor;

  initialize(options?: Backbone.ViewOptions<Backbone.Model>) {
    super.initialize(options);

    this.formatter = new DatepickerCellFormatter({
      modelFormat: this.column.get('modelFormat') || 'x',
      displayFormat:
        this.column.get('displayFormat') || I18n.t('time.formats.momentjs'),
      minDate: this.column.get('minDate'),
      maxDate: this.column.get('maxDate'),
      offset: this.column.get('offset')
    });
  }
}

Object.assign(Backgrid, { DatepickerCell });
