export default function (element: JQuery) {
  $(element)
    .find('.panel')
    .on('show.bs.collapse hide.bs.collapse', e =>
      $(e.target)
        .siblings('.panel-heading')
        .find('.panel-title i')
        .toggleClass('fa-caret-down fa-caret-right')
    );
}
