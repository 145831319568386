import _ from 'underscore';
import RadioToggleButtons from 'views/radio_toggle_buttons';
import ItemSelector from 'views/item_selector';
import DistributionOffersSelector from 'views/distribution_offers_selector';

const CampaignBasicDetailsView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '[name="campaign[id]"]': 'id',
    '[name="campaign[name]"]': 'name',
    '[name="campaign[campaign_grouping]"]': 'campaign_grouping',
    '[name="campaign[distribution_id]"]': 'distribution_id',
    '[name="campaign[offer_ids]"]': 'offer_ids',
    '[name="campaign[visibility]"]': 'visibility',
    '[name="campaign[channel]"]': 'channel',
    '[name="campaign[enable_home_screen]"]': 'enable_home_screen',
    '[name="campaign[placed_measurement_enabled]"]':
      'placed_measurement_enabled'
  },

  ui: {
    radioToggles: '.radio_toggle',
    campaignOfferIds: '.campaign_offer_ids',
    distributionId: '[name="campaign[distribution_id]"]',
    trackingTagsExplanation: '.campaign-offer-tracking-tags .explain',
    channel: '[name="campaign[channel]"]',
    firstLook: '#campaign_budget_plan_attributes_first_look',
    placedMeasurementEnabled: '[name="campaign[placed_measurement_enabled]"]'
  },

  modelEvents: {
    'change:offer_ids': 'onOfferIdsModelChange',
    'change:channel': 'onChannelModelChange'
  },

  events: {
    'change @ui.firstLook': 'onFirstLookChange'
  },

  initialize(options) {
    this.mergeOptions(options, [
      'currentBudgetPlan',
      'distributions',
      'firstLookCpm'
    ]);
    this.stickit();
    this.bindUIElements();
    if (this.ui.trackingTagsExplanation.length > 0) {
      this.loadTrackingTags();
    }
    this.renderViews();
  },

  renderViews() {
    this.initRadioToggles();
    this.initDistributionSelector();
  },

  loadTrackingTags() {
    const xhr = new XMLHttpRequest();
    const url =
      `/api/providers/${this.model.get('provider_id')}` +
      `/campaigns/${this.model.get('id')}/tracking_tags`;
    xhr.open('GET', url, true);
    xhr.responseType = 'json';

    xhr.addEventListener('load', () => {
      if (xhr.status === 200 && xhr.response instanceof Array) {
        this.trackingTags = xhr.response.filter(
          trackingTag =>
            trackingTag.third_party_click_url ||
            trackingTag.third_party_view_url
        );
        this.onOfferIdsModelChange();
      }
    });

    xhr.send();
  },

  initRadioToggles() {
    _(this.ui.radioToggles).each($el => {
      new RadioToggleButtons({ el: $el });
    });
  },

  initDistributionSelector() {
    const $distribution_id_input = this.ui.distributionId;
    let distribution_selector_view;

    const useNameAsText = distribution => {
      distribution.text = distribution.name;
      return distribution;
    };

    if ($distribution_id_input.length) {
      distribution_selector_view = new ItemSelector({
        el: $distribution_id_input,
        allowClear: true,
        data: (this.distributions || []).map(useNameAsText),
        placeholder: I18n.t('campaigns.form.no_distribution'),
        formatResult: (distribution, _c, _q, escapeMarkup) =>
          escapeMarkup(distribution.name),
        formatSelection: (distribution, _c, escapeMarkup) =>
          escapeMarkup(distribution.name)
      });
    }

    new DistributionOffersSelector({
      el: this.ui.campaignOfferIds,
      model: this.model,
      distribution_selector: distribution_selector_view
    });
  },

  onOfferIdsModelChange() {
    if (
      this.ui.trackingTagsExplanation.length > 0 &&
      this.trackingTags !== undefined
    ) {
      const offerIds = this.model.get('offer_ids');
      const count = this.trackingTags.filter(trackingTag =>
        offerIds.includes(String(trackingTag.offer_id))
      ).length;

      this.ui.trackingTagsExplanation.text(
        I18n.t('campaigns.form.tracking_tags.offers_count', { count })
      );
    }
  },

  onFirstLookChange() {
    const firstLook = this.ui.firstLook.is(':checked');

    // Update campaign object
    if (firstLook) {
      this.model.set('budget_plan_id', '');
      this.model.set('channel', 'ADS_0SPEED_INFO');
      this.model.set('price', this.firstLookCpm);
    }
    this.currentBudgetPlan.set('first_look', firstLook);
    this.ui.channel.prop('disabled', firstLook).trigger('change');
  },

  onChannelModelChange() {
    if (
      this.model.isSearch() ||
      this.model.isCategorySearch() ||
      this.model.isIntent()
    ) {
      this.model.set('placed_measurement_enabled', false);
      this.ui.placedMeasurementEnabled.prop('disabled', true);
    } else {
      this.ui.placedMeasurementEnabled.prop('disabled', false);
    }
  }
});

export default CampaignBasicDetailsView;
