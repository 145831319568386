import _ from 'underscore';
import ReportsCollection from 'collections/reports';
import ReportsFilterInput from 'views/reports_filter_input';
import ReportsFilterItems from 'views/reports_filter_items';
import daterangeFields from 'providers/views/daterange_fields';

export default function (element) {
  const $form = $(element);

  // init daterange fields
  daterangeFields($form.find('.daterange-controls'));

  // init filtered reports collections
  const items = {};
  const itemsInputs = {};
  for (const type of ['pins', 'offers', 'campaigns']) {
    items[type] = new ReportsCollection([], { type });

    itemsInputs[type] = new ReportsFilterInput({
      collection: items[type],
      el: $(`.items :input.${type}`, $form)
    });
  }

  new ReportsFilterItems({
    collections: items,
    el: $('.filter-preview', $form)
  });

  // update collections when receiving a 'filter' event
  $(element).on('reports_table.filter', (e, options) => {
    if (_.has(items, options.type)) {
      return items[options.type].reset(options.selected);
    }
  });

  $form.find('select').selectpicker();
}
