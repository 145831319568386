import { isDeepEmpty } from '../../../helpers/utils';
import { togglePanelCollapse } from '../../../helpers/views';

export class RouteLengthTargetView extends Marionette.ItemView<Backbone.Model> {
  initialize() {
    this.ui = {
      min: '[name="campaign[route_length_target_attributes][min]"]',
      max: '[name="campaign[route_length_target_attributes][max]"]'
    };
    this.modelEvents = {};
    this.modelEvents[`change:${this.attributes.attributeName}`] =
      this.updateRouteLengthFields;

    Marionette.bindEntityEvents(this, this.model, this.modelEvents);
    this.bindUIElements();

    this.updateRouteLengthFields();

    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get(this.attributes.attributeName))
    );
  }

  events() {
    return {
      change: this.onViewChange
    };
  }

  updateRouteLengthFields() {
    const routeLength = this.model.get(this.attributes.attributeName) || {};
    this.ui.min.val(routeLength.min);
    this.ui.max.val(routeLength.max);
  }

  onViewChange() {
    this.model.set(this.attributes.attributeName, {
      min: this.ui.min.val(),
      max: this.ui.max.val()
    });
  }
}
