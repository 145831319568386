var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, daterangeOptions) {pug_html = pug_html + "\u003Cselect name=\"date_range\"\u003E\u003Coption disabled\u003E" + (pug.escape(null == (pug_interp = I18n.t('date_range.label')) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
// iterate daterangeOptions()
;(function(){
  var $$obj = daterangeOptions();
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var range = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", range.value, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t(range.value, {scope: 'date_range.presets', label: range.label})) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var range = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", range.value, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t(range.value, {scope: 'date_range.presets', label: range.label})) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003Cdiv class=\"input-group input-daterange\"\u003E\u003Cinput class=\"form-control\" type=\"text\" name=\"from_date\"\u003E\u003Cdiv class=\"input-group-addon\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('date_range.separator')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cinput class=\"form-control\" type=\"text\" name=\"to_date\"\u003E\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"daterangeOptions" in locals_for_with?locals_for_with.daterangeOptions:typeof daterangeOptions!=="undefined"?daterangeOptions:undefined));;return pug_html;};
module.exports = template;