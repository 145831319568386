import Backbone from 'backbone';

interface RadioToggleButtonsOptions
  extends Backbone.ViewOptions<Backbone.Model> {
  labels?: JQuery;
  inputs?: JQuery;
  controls?: JQuery;
}

class RadioToggleButtons extends Backbone.View<Backbone.Model> {
  options!: RadioToggleButtonsOptions;
  private $labels!: JQuery;
  private $inputs!: JQuery;
  private $controls!: JQuery;

  constructor(options: RadioToggleButtonsOptions) {
    super(options);
  }

  initialize(options: RadioToggleButtonsOptions) {
    this.options = options;
    this.$labels = options.labels || this.$('.radio');
    this.$inputs = options.inputs || this.$(':input');
    this.$controls = options.controls || this.$('.controls');

    this.$labels.addClass('btn btn-default');

    this.$controls.addClass('btn-group').attr('data-toggle', 'buttons');

    const explain = this.$labels.find('.explain').hide();
    if (explain.length) {
      this.initTooltips();
    }

    // mark current active
    this.toggleChecked();

    this.$inputs.hide();

    this.toggleDisabled();
    this.$inputs.on('change', () => {
      this.toggleChecked();
      this.toggleDisabled();
    });
    this.$el.on('click', '.btn-group .btn.disabled', e => e.stopPropagation());

    this.listenTo(this.model, 'change', this.toggleDisabled);
    this.listenTo(this.model, 'toggle-disabled', this.toggleDisabled);
  }

  initTooltips() {
    this.$labels.each(function () {
      const text = $(this).find('.explain').text();
      $(this).popover({
        content: text,
        placement: 'bottom',
        trigger: 'hover focus',
        container: 'body'
      });
    });
  }

  toggleChecked() {
    this.$inputs.closest('.btn').removeClass('active');
    this.$inputs.filter(':checked').closest('.btn').addClass('active');
  }

  toggleDisabled() {
    this.$labels.each((_index, label) => {
      const $label = $(label);
      $label.toggleClass('disabled', $label.find(':input').is(':disabled'));
    });
  }
}

export default RadioToggleButtons;
