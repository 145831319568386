var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, localeOptions, optionValue) {pug_html = pug_html + "\u003Cselect name=\"locale\"\u003E";
// iterate localeOptions
;(function(){
  var $$obj = localeOptions;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var option = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", optionValue(option), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t(option, {scope: 'locale_settings.locales'})) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var option = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", optionValue(option), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t(option, {scope: 'locale_settings.locales'})) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"localeOptions" in locals_for_with?locals_for_with.localeOptions:typeof localeOptions!=="undefined"?localeOptions:undefined,"optionValue" in locals_for_with?locals_for_with.optionValue:typeof optionValue!=="undefined"?optionValue:undefined));;return pug_html;};
module.exports = template;