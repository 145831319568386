var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, UNSPENT_COLOR, budget, campaigns, flightStarted, name, overflow, spent, toCurrency, unspent) {pug_html = pug_html + ("\u003Cdiv class=\"budget-spent-tooltip panel panel-default\"\u003E\u003Cdiv class=\"panel-heading\"\u003E\u003Ch3 class=\"panel-title\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"panel-body\"\u003E\u003Cdiv\u003E" + (pug.escape(null == (pug_interp = I18n.t('budget_plans.spent.budget', {budget: toCurrency(budget)})) ? "" : pug_interp)));
if (overflow) {
pug_html = pug_html + "&nbsp\u003Cspan class=\"overflow\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('budget_plans.spent.overflow', {overflow: toCurrency(overflow)})) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (flightStarted) {
pug_html = pug_html + "\u003Cdiv\u003E" + (pug.escape(null == (pug_interp = I18n.t('budget_plans.spent.total_spent', {spent: toCurrency(spent)})) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (flightStarted) {
pug_html = pug_html + "\u003Cul class=\"list-group\"\u003E";
// iterate campaigns
;(function(){
  var $$obj = campaigns;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var campaign = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli class=\"list-group-item clearfix\"\u003E\u003Cdiv" + (" class=\"google-visualization-tooltip-square\""+pug.attr("style", pug.style({'background-color': campaign.color}), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = campaign.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan class=\"pull-right\"\u003E" + (pug.escape(null == (pug_interp = toCurrency(campaign.spentInFlight || 0)) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var campaign = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli class=\"list-group-item clearfix\"\u003E\u003Cdiv" + (" class=\"google-visualization-tooltip-square\""+pug.attr("style", pug.style({'background-color': campaign.color}), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = campaign.name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan class=\"pull-right\"\u003E" + (pug.escape(null == (pug_interp = toCurrency(campaign.spentInFlight || 0)) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

if (unspent) {
pug_html = pug_html + "\u003Cli class=\"list-group-item\"\u003E\u003Cdiv" + (" class=\"google-visualization-tooltip-square\""+pug.attr("style", pug.style({'background-color': UNSPENT_COLOR}), true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = I18n.t('budget_plans.spent.unspent')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan class=\"pull-right\"\u003E" + (pug.escape(null == (pug_interp = toCurrency(unspent || 0)) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fli\u003E";
}
pug_html = pug_html + "\u003C\u002Ful\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"UNSPENT_COLOR" in locals_for_with?locals_for_with.UNSPENT_COLOR:typeof UNSPENT_COLOR!=="undefined"?UNSPENT_COLOR:undefined,"budget" in locals_for_with?locals_for_with.budget:typeof budget!=="undefined"?budget:undefined,"campaigns" in locals_for_with?locals_for_with.campaigns:typeof campaigns!=="undefined"?campaigns:undefined,"flightStarted" in locals_for_with?locals_for_with.flightStarted:typeof flightStarted!=="undefined"?flightStarted:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"overflow" in locals_for_with?locals_for_with.overflow:typeof overflow!=="undefined"?overflow:undefined,"spent" in locals_for_with?locals_for_with.spent:typeof spent!=="undefined"?spent:undefined,"toCurrency" in locals_for_with?locals_for_with.toCurrency:typeof toCurrency!=="undefined"?toCurrency:undefined,"unspent" in locals_for_with?locals_for_with.unspent:typeof unspent!=="undefined"?unspent:undefined));;return pug_html;};
module.exports = template;