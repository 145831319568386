import '../vendor';
import '../../../config/locales/en.yml';
import 'layout';
import 'access_list';
import 'budget_plans';
import 'campaigns';
import 'distributions';
import 'location_tags';
import 'offers';
import 'pins';
import 'predictions';
import 'providers';
import 'reports';
import 'users';
import '../angular/index';

I18n.locales[I18n.currentLocale()] = [
  `${I18n.currentLocale()}`, //  E.g. es
  `${I18n.currentLocale()}_dexter`, //  E.g. es_dexter
  I18n.defaultLocale, //  E.g. en
  `${I18n.defaultLocale}_dexter` //  E.g. en_dexter
];
