import _ from 'underscore';
const elementUniqueId = (element: JQuery) => {
  if (!element.attr('id')) {
    element.attr('id', _.uniqueId());
  }
  return element.attr('id')!;
};

const getInputValue = (element: JQuery) => {
  if ($(element).is(':radio')) {
    return $(element).filter(':checked').val();
  } else if ($(element).is(':checkbox')) {
    return $(element).is(':checked');
  } else {
    return $(element).val();
  }
};

type DisableIfAction = 'hide' | 'disable';

type DisableIfMode =
  | 'hide-if'
  | 'hide-unless'
  | 'disable-if'
  | 'disable-unless';

interface DisableIfOptions {
  action?: DisableIfAction;
  field: string | JQuery<HTMLInputElement>;
  inverse?: boolean;
  value: string | string[];
}

interface TesterOptions {
  action: DisableIfAction;
  field: JQuery<HTMLInputElement>;
  inverse: boolean;
  value: string | string[];
}

class DisableIf {
  private $element: JQuery;
  private openElementsSet: { [id: string]: boolean };

  constructor(element: JQuery, options: DisableIfOptions) {
    this.onChange = this.onChange.bind(this);
    let $field: JQuery;

    if (typeof options.field === 'string') {
      $field = $(`:input[name*='[${options.field}]']`);
    } else {
      $field = options.field;
    }

    this.$element = $(element);
    this.$element.data('disable-if-tester', {
      field: $field,
      action: options.action,
      inverse: options.inverse,
      value: options.value
    } as TesterOptions);
    this.$element.attr('disable-if-installed', 'true');
    this.openElementsSet = {};

    $field.on('change.disable-if', this.onChange);
    $field.trigger('change.disable-if');
  }

  onChange() {
    if (!this.openElement()) {
      return;
    }

    const $testers = this.getTesters(this.$element.parents().addBack());

    const hide_result = this.evaluateTesters(
      this.filterByTesterType($testers, 'hide')
    );

    let disable_result;
    if (hide_result) {
      disable_result = true;
    } else {
      disable_result = this.evaluateTesters(
        this.filterByTesterType($testers, 'disable')
      );
    }

    this.$element.toggle(!hide_result);
    this.toggleDisable(disable_result);

    this.closeElement();
  }

  openElement() {
    if (_.has(this.openElementsSet, elementUniqueId(this.$element))) {
      return false;
    }

    this.openElementsSet[elementUniqueId(this.$element)] = true;
    return true;
  }

  closeElement() {
    delete this.openElementsSet[elementUniqueId(this.$element)];
  }

  getTesters($elements: JQuery) {
    return $elements.map(function () {
      return $(this).data('disable-if-tester') as TesterOptions;
    });
  }

  filterByTesterType($testers: JQuery<TesterOptions>, type: DisableIfAction) {
    return $testers
      .filter(function () {
        return this.action === type;
      })
      .get();
  }

  evaluateTesters($testers: TesterOptions[]) {
    return $testers.some(tester => this.evaluateTester(tester));
  }

  evaluateTester(tester: TesterOptions) {
    let actual = getInputValue(tester.field);
    if (actual != null) {
      actual = actual.toString();
    }

    const expected = tester.value;
    const match = expected === actual || _.indexOf(expected, actual) > -1;

    return tester.inverse ? !match : match;
  }

  toggleDisable(value: boolean) {
    const $inputs = this.$element.is(':input')
      ? this.$element
      : this.$element.find(':input');

    const $elements_to_toggle = $inputs
      .filter(':not(.permission-disable)')
      .filter(value ? ':not(:disabled)' : ':disabled')
      .prop('disabled', value);

    if (!value && $elements_to_toggle.length) {
      this.getTesters(this.$element.find('[disable-if-installed]')).each(
        (_i, tester) => {
          tester.field.trigger('change.disable-if');
        }
      );
    }
  }
}

export default (
  element: JQuery,
  mode: DisableIfMode,
  options: DisableIfOptions
) => {
  let defaults: { action: DisableIfAction; inverse: boolean };

  switch (mode) {
    case 'hide-if':
      defaults = { action: 'hide', inverse: false };
      break;
    case 'hide-unless':
      defaults = { action: 'hide', inverse: true };
      break;
    case 'disable-if':
      defaults = { action: 'disable', inverse: false };
      break;
    case 'disable-unless':
      defaults = { action: 'disable', inverse: true };
      break;
    default:
      throw new Error('Invalid DisableIf mode');
      break;
  }

  return new DisableIf($(element), Object.assign({}, defaults, options));
};
