var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (showSnooze, showUnsnooze, snoozeOptions, titles) {pug_html = pug_html + "\u003Ca" + (" class=\"action delete\""+pug.attr("title", titles.delete, true, true)) + "\u003E\u003Ci class=\"fa fa-lg fa-trash\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E";
if (showSnooze()) {
pug_html = pug_html + "\u003Cspan class=\"action snooze dropdown\"\u003E\u003Ca" + (" class=\"dropdown-toggle\""+" data-toggle=\"dropdown\""+pug.attr("title", titles.snooze, true, true)) + "\u003E\u003Ci class=\"fa fa-lg fa-clock-o\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Cul class=\"dropdown-menu\"\u003E";
// iterate snoozeOptions
;(function(){
  var $$obj = snoozeOptions;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var option = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli" + (pug.attr("data-time", option.time, true, true)) + "\u003E\u003Ca\u003E" + (pug.escape(null == (pug_interp = option.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var option = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli" + (pug.attr("data-time", option.time, true, true)) + "\u003E\u003Ca\u003E" + (pug.escape(null == (pug_interp = option.label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Fspan\u003E";
}
if (showUnsnooze()) {
pug_html = pug_html + "\u003Ca" + (" class=\"action unsnooze\""+pug.attr("title", titles.unsnooze, true, true)) + "\u003E\u003Cspan class=\"fa fa-lg fa-stack\"\u003E\u003Ci class=\"fa fa-clock-o fa-stack-1x\"\u003E\u003C\u002Fi\u003E\u003Ci class=\"fa fa-ban fa-stack-1x\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}}.call(this,"showSnooze" in locals_for_with?locals_for_with.showSnooze:typeof showSnooze!=="undefined"?showSnooze:undefined,"showUnsnooze" in locals_for_with?locals_for_with.showUnsnooze:typeof showUnsnooze!=="undefined"?showUnsnooze:undefined,"snoozeOptions" in locals_for_with?locals_for_with.snoozeOptions:typeof snoozeOptions!=="undefined"?snoozeOptions:undefined,"titles" in locals_for_with?locals_for_with.titles:typeof titles!=="undefined"?titles:undefined));;return pug_html;};
module.exports = template;