import _ from 'underscore';
import categorySelect from 'ui/form/category_select';
import toggleSelect from 'ui/form/toggle_select';
import { togglePanelCollapse } from 'helpers/views';
import { isDeepEmpty } from 'helpers/utils';

const CampaignDriveTargetingView = Marionette.ItemView.extend({
  template: false,

  ui: {
    targetHome: '#campaign_drive_targeting_attributes_home',
    targetWork: '#campaign_drive_targeting_attributes_work',
    targetAway: '[name="toggle_campaign_drive_targeting_away"]',
    targetAwayRadius: 'select#campaign_drive_targeting_attributes_away',
    targetedCategoryBuckets: '#campaign_drive_targeting_attributes_buckets',
    targetedKeywords: '[name="campaign[drive_targeting_attributes][keywords]"]',
    targetedCategories: '#campaign_drive_targeting_attributes_categories',
    toggleSelect: 'div.toggle_select'
  },

  events: {
    change: 'onViewChange'
  },

  modelEvents: {
    'change:drive_targeting': 'renderInputs'
  },

  initialize() {
    this.bindUIElements();
    this.renderViews();
    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get('drive_targeting'))
    );
  },

  renderViews() {
    this.ui.targetedKeywords.select2({
      tags: [],
      minimumInputLength: 3,
      maximumInputLength: 35,
      tokenSeparators: [',', '\n']
    });
    categorySelect(this.ui.targetedCategoryBuckets);
    categorySelect(this.ui.targetedCategories);
    this.renderInputs();
    toggleSelect(this.ui.toggleSelect);
  },

  onViewChange() {
    const target = {
      hwas: _.compact([
        this.ui.targetHome.prop('checked') ? 'HOME' : null,
        this.ui.targetWork.prop('checked') ? 'WORK' : null,
        this.ui.targetAway.prop('checked') && this.ui.targetAwayRadius.val()
          ? `AWAY${this.ui.targetAwayRadius.val()}`
          : null
      ]),
      buckets: this.ui.targetedCategoryBuckets.select2('val'),
      keywords: this.ui.targetedKeywords.select2('val'),
      categories: this.ui.targetedCategories.select2('val')
    };

    this.model.set('drive_targeting', target);
  },

  renderInputs() {
    const target = this.model.get('drive_targeting') || {};
    const awayRadius = _(target.hwas)
      .chain()
      .filter(val => val.startsWith('AWAY'))
      .invoke('split', 'AWAY')
      .flatten()
      .compact()
      .first()
      .value();

    this.ui.targetHome.prop('checked', _.contains(target.hwas, 'HOME'));
    this.ui.targetWork.prop('checked', _.contains(target.hwas, 'WORK'));
    this.ui.targetAway.prop('checked', !!awayRadius);
    this.ui.targetAwayRadius.val(awayRadius);
    if (target.buckets) {
      this.ui.targetedCategoryBuckets.select2('val', target.buckets);
    }
    if (target.keywords) {
      this.ui.targetedKeywords.select2('val', target.keywords);
    }
    if (target.categories) {
      this.ui.targetedCategories.select2('val', target.categories);
    }
  }
});

export default CampaignDriveTargetingView;
