import _ from 'underscore';
import moment from 'moment';
import '../../views/backgrid/enum_cell';
import '../../views/backgrid/button_cell';
import '../../views/backgrid/datepicker_cell';
import '../../views/backgrid/currency_cell';
import { toCurrency } from '../../helpers';

const DELETE_CONTENT = '<i class="fa fa-lg fa-trash">';
const ADD_CONTENT = '<i class="fa fa-lg fa-plus">';

class FlightsGridRow extends Backgrid.Row {
  initialize() {
    this.listenTo(this.model.collection, 'validated', this._setValidationClass);
    this._setValidationClass();
    super.initialize(...arguments);
  }

  _setValidationClass() {
    this.$el.toggleClass('error', this.model.validationError != null);
  }
}

class FlightsGrid extends Backgrid.Grid {
  initialize(options = {}) {
    this._getEndMinDate = this._getEndMinDate.bind(this);
    this._getStartMaxDate = this._getStartMaxDate.bind(this);
    this._addFlight = this._addFlight.bind(this);
    this._isDeletable = this._isDeletable.bind(this);
    this._showAdd = this._showAdd.bind(this);

    this.isFormEditable = options.isFormEditable;
    this.wasLive = options.wasLive;
    this.earliestTimezoneOffsetMs = options.earliestTimezoneOffsetMs;
    this.latestTimezoneOffsetMs = options.latestTimezoneOffsetMs;
    const opts = _.extend(
      {},
      { row: FlightsGridRow, columns: this._columns() },
      options
    );
    super.initialize(opts);
    this.spendableColumnConfigs = this.columns
      .filter(
        column =>
          column.get('name') === 'budget' || column.get('name') === 'spent'
      )
      .map(column => ({
        index: this.columns.indexOf(column),
        column
      }))
      // Sort by index in ascending order to avoid having to reindex after
      // inserting a column.
      .sort((config1, config2) => {
        return config1.index - config2.index;
      });
  }

  toggleSpendableColumns(visible) {
    this.spendableColumnConfigs.forEach(config => {
      if (visible) {
        this.insertColumn(config.column, { at: config.index });
      } else {
        this.removeColumn(config.column);
      }
    });
  }

  _columns() {
    const columns = [
      { name: 'name', cell: 'string' },
      {
        name: 'start_date',
        cell: 'datepicker',
        minDate: this._getStartMinDate,
        maxDate: this._getStartMaxDate
      },
      {
        name: 'end_date',
        cell: 'datepicker',
        offset: { value: 1, units: 'd' },
        minDate: this._getEndMinDate,
        maxDate: this._getEndMaxDate
      },
      {
        name: 'budget',
        cell: 'currency',
        tooltip: this._getMinBudgetTooltip,
        precision: 2
      },
      {
        name: 'spent',
        cell: 'currency',
        editable: false,
        precision: 2,
        magnitudeCorrection: -2
      },
      {
        name: 'delete',
        label: '',
        cell: 'button',
        editable: false,
        content: DELETE_CONTENT,
        renderable: this._isDeletable,
        action: this._delete
      },
      {
        name: 'add',
        label: '',
        cell: 'button',
        editable: false,
        content: ADD_CONTENT,
        renderable: this._showAdd,
        action: this._addFlight
      }
    ];
    return _.map(columns, col => {
      return _.extend(
        {
          label: I18n.t(col.name, { scope: 'simple_form.flights.columns' }),
          editable: model => this._isEditable(model, col.name)
        },
        col
      );
    });
  }

  _getStartMinDate(flight) {
    return flight.collection.getStartTime();
  }

  _getEndMinDate(flight) {
    return Math.max(
      this._getStartMinDate(flight),
      flight.get('start_date'),
      +moment.utc().startOf('d').format('x')
    );
  }

  _getStartMaxDate(flight) {
    return Math.min(this._getEndMaxDate(flight), flight.get('end_date'));
  }

  _getEndMaxDate(flight) {
    return flight.collection.getEndTime();
  }

  _getMinBudgetTooltip(model) {
    const min_budget = model.get('spent') - model.get('overflow');
    if (min_budget > 0) {
      const formatted = toCurrency(min_budget / 100);

      return I18n.t('simple_form.flights.minimum_budget', {
        budget: formatted
      });
    }
  }

  _isDeletable(model) {
    // model is sometimes a flight and sometimes a collection
    return this.isFormEditable() && model.isDeletable && model.isDeletable();
  }

  _isEditable(model, field) {
    // Only check if flight has started or ended for live budget plans.
    if (this.wasLive) {
      // model is sometimes a flight and sometimes a collection
      if (field === 'start_date' && model.get && model.get(field) != null) {
        const startDate = new Date(model.get(field));
        const beginningOfStartDate = Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate()
        );
        // Subtract to convert from time at latest timezone to UTC.
        const startDateAtLatestTimezone =
          beginningOfStartDate - this.latestTimezoneOffsetMs;

        // Not editable if the campaign has started at a location already.
        if (startDateAtLatestTimezone <= new Date().getTime()) {
          return false;
        }
      } else if (
        field === 'end_date' &&
        model.get &&
        model.get(field) != null
      ) {
        // end_date is the first moment when the flight should no longer be
        // running.
        const endDate = new Date(model.get(field));
        const beginningOfEndDate = Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate()
        );
        // Subtract to convert from time at earliest timezone to UTC.
        const endDateAtEarliestTimezone =
          beginningOfEndDate - this.earliestTimezoneOffsetMs;

        // Not editable if the campaign has ended at all locations already.
        if (endDateAtEarliestTimezone <= new Date().getTime()) {
          return false;
        }
      }
    }
    return this.isFormEditable() && model.isEditable && model.isEditable(field);
  }

  _showAdd(model) {
    // model is sometimes a flight and sometimes a collection
    return (
      this.isFormEditable() &&
      model.collection &&
      model.collection.last() === model &&
      model.collection.budget_plan.canAddFlights()
    );
  }

  _delete(model) {
    model.collection.remove(model);
  }

  _addFlight() {
    this.collection.addFlight();
  }
}

export default FlightsGrid;
