import { isDeepEmpty } from '../../../helpers/utils';
import { togglePanelCollapse } from '../../../helpers/views';
import Campaign, {
  BrandCategoryPresenceTargetAttributes
} from '../../../models/campaign';

const ATTRIBUTE_NAME = 'brand_category_presence_target';

export class BrandCategoryPresenceTargetView extends Marionette.ItemView<Campaign> {
  initialize() {
    this.ui = {
      atLeast: '#campaign_brand_category_presence_target_attributes_atLeast',
      atMost: '#campaign_brand_category_presence_target_attributes_atMost',
      lookbackWindow:
        '#campaign_brand_category_presence_target_attributes_lookbackWindow',
      all: '#campaign_brand_category_presence_target_attributes_brand_category_presence_state_all',
      between:
        '#campaign_brand_category_presence_target_attributes_brand_category_presence_state_between',
      state:
        'input[name="campaign[brand_category_presence_target_attributes][brand_category_presence_state]"]'
    };

    this.bindUIElements();

    this.initBrandCategoryPresenceUI();

    togglePanelCollapse(this.$el, !isDeepEmpty(this.model.get(ATTRIBUTE_NAME)));
  }

  events() {
    return {
      change: this.onViewChange
    };
  }

  onViewChange() {
    const checked = this.ui.state.filter(':checked').val();

    switch (checked) {
      case 'all':
        this.disableInputs(true);
        this.model.set(ATTRIBUTE_NAME, null);
        break;
      case 'between':
        this.disableInputs(false);
        if (!this.ui.atLeast.val()) {
          this.ui.atLeast.val(0);
        }
        if (!this.ui.atMost.val()) {
          this.ui.atMost.val(100);
        }
        this.model.set(ATTRIBUTE_NAME, {
          lookbackWindow: this.ui.lookbackWindow.val(),
          atMost: this.ui.atMost.val(),
          atLeast: this.ui.atLeast.val()
        } as BrandCategoryPresenceTargetAttributes);
        break;
      default:
        throw new Error(
          'Unhandled state: state must be either "all" or "between"'
        );
    }
  }

  private initBrandCategoryPresenceUI() {
    const brandCategoryPresence = this.model.get(ATTRIBUTE_NAME) || {};
    if (Object.keys(brandCategoryPresence).length === 0) {
      this.ui.all.prop('checked', true);
      this.ui.atLeast.val(null);
      this.ui.atMost.val(null);
      this.disableInputs(true);
    } else {
      this.ui.between.prop('checked', true);
      this.ui.lookbackWindow.val(brandCategoryPresence.lookbackWindow);
      this.ui.atLeast.val(brandCategoryPresence.atLeast);
      this.ui.atMost.val(brandCategoryPresence.atMost);
      this.disableInputs(false);
    }
  }

  private disableInputs(state: boolean) {
    this.ui.lookbackWindow.prop('disabled', state);
    this.ui.atMost.prop('disabled', state);
    this.ui.atLeast.prop('disabled', state);
  }
}
