import Popover from 'views/popover';
import Routes from 'helpers/routes';

class PopoverCell extends Backgrid.Cell {
  initialize() {
    super.initialize(...arguments);
    this._count = this.model.get(this.column.get('name')).length;
    if (this._count > 0) {
      this._popover = new Popover({
        el: this.$el,
        content: this._getContent.bind(this),
        popover: this._getPopoverContent.bind(this)
      });
    }
  }

  _getContent() {
    if (this._count <= 0) {
      return '';
    }

    return I18n.t(this.column.get('translation'), { count: this._count });
  }

  render() {
    if (this._popover) {
      this._popover.render();
    }
    return this;
  }

  _getPopoverContent() {
    if (this._count <= 0) {
      return;
    }
    const template = this.column.get('template');
    return template({
      model: this.model.toJSON(),
      Routes
    });
  }
}

export default PopoverCell;
