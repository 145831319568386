import CampaignsView from 'campaigns/views/spent/campaigns_table';
import ProgressView from 'campaigns/views/spent/progress';

import template from 'templates/spent/component.pug';

const Component = Marionette.LayoutView.extend({
  template,

  regions: {
    table: '.budget-plan-campaigns-table',
    progress: '.budget-plan-progress'
  },

  initialize(options = {}) {
    this.campaignsView = new CampaignsView({
      collection: new Backbone.Collection(this.model.get('campaigns')),
      providerId: this.model.get('provider_id')
    });

    this.progressView = new ProgressView({ model: this.model });

    this.render();
  },

  onRender() {
    this.showChildView('table', this.campaignsView);
    this.showChildView('progress', this.progressView);
  },

  onChildviewClickRow(child) {
    this.progressView.selectCampaign(child.model.get('id'));
  }
});

export default Component;
