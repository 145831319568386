var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, clientImageUrl, is_guest_provider, location, offer) {var logo_url = clientImageUrl(offer.preview_logo) || offer.logo_to_display
pug_html = pug_html + "\u003Cdiv class=\"header\"\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["header-map",offer.navigable ? 'navigable' : ''], [false,true]), false, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["logo-img",is_guest_provider ? 'guest' : ''], [false,true]), false, true)) + "\u003E\u003Cimg" + (pug.attr("src", logo_url, true, true)) + "\u003E\u003C\u002Fdiv\u003E";
if (offer.navigable) {
pug_html = pug_html + "\u003Cdiv class=\"favorite\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('preview.info.favorite')) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Csection class=\"location-details\"\u003E\u003Cp class=\"title\"\u003E" + (pug.escape(null == (pug_interp = location.title) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cp class=\"address\"\u003E" + (pug.escape(null == (pug_interp = location.address + ', ' + location.city) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
if (location.location) {
pug_html = pug_html + "\u003Cp class=\"additional-location\"\u003E" + (pug.escape(null == (pug_interp = location.location) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Cp class=\"distance\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('preview.info.distance')) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cp class=\"open-now\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('preview.info.open_now')) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003C\u002Fsection\u003E";
}
else {
pug_html = pug_html + "\u003Csection class=\"location-details\"\u003E\u003Cp class=\"title\"\u003E" + (pug.escape(null == (pug_interp = location.title) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003C\u002Fsection\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"clientImageUrl" in locals_for_with?locals_for_with.clientImageUrl:typeof clientImageUrl!=="undefined"?clientImageUrl:undefined,"is_guest_provider" in locals_for_with?locals_for_with.is_guest_provider:typeof is_guest_provider!=="undefined"?is_guest_provider:undefined,"location" in locals_for_with?locals_for_with.location:typeof location!=="undefined"?location:undefined,"offer" in locals_for_with?locals_for_with.offer:typeof offer!=="undefined"?offer:undefined));;return pug_html;};
module.exports = template;