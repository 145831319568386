import DisableIf from 'ui/form/disable_if';

const CampaignDisplayOptionsView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '[name="campaign[popup_caps]"]': 'popup_caps'
  },

  ui: {
    radius: '[name="campaign[radius]"]',
    radiusContainer: '.radius',
    hideForArrowRadii: 'option.hide-for-arrow',
    popupCaps: '.popup_caps',
    arrivalType: '.arrival_type'
  },

  events: {
    'change @ui.radius': 'onRadiusSelectChange'
  },

  modelEvents: {
    'change:radius': 'onRadiusChange'
  },

  initialize(options) {
    this.bindUIElements();
    this.stickit();
    this.onRadiusChange();
    this.initDisableIf();
  },

  onRadiusSelectChange() {
    this.model.set('radius', +this.ui.radius.val());
  },

  onRadiusChange() {
    this.ui.radius.val(this.model.get('radius'));
  },

  initDisableIf() {
    DisableIf(this.ui.hideForArrowRadii, 'hide-if', {
      field: 'channel',
      value: 'ADS_ARROW_NEARBY_INFO'
    });

    DisableIf(this.ui.radiusContainer, 'hide-unless', {
      field: 'channel',
      value: ['ADS_0SPEED_INFO', 'ADS_ARROW_NEARBY_INFO']
    });

    DisableIf(this.ui.popupCaps, 'hide-unless', {
      field: 'channel',
      value: 'ADS_0SPEED_INFO'
    });

    DisableIf(this.ui.arrivalType, 'hide-unless', {
      field: 'channel',
      value: 'ADS_INTENT_INFO'
    });
  }
});

export default CampaignDisplayOptionsView;
