import Report from '../models/report';

class ReportsFilterInput extends Marionette.ItemView<Backbone.Model> {
  template!: false;
  options!: Backbone.ViewOptions<Backbone.Model>;

  static initClass() {
    this.prototype.template = false;

    this.prototype.collectionEvents = { 'add remove reset': 'render' };
  }

  initialize(options: Backbone.ViewOptions<Backbone.Model> = {}) {
    this.options = options;
    this.populateFromInput();
    return this.render();
  }

  onRender() {
    return this.$el.val(this.collection.pluck('id').join(','));
  }

  populateFromInput() {
    return (() => {
      const value = this.$el.val()! as string;

      const result = [];
      for (const id of value.split(',')) {
        let item;
        if (id) {
          item = this.collection.add(new Report({ id }));
        }
        result.push(item);
      }
      return result;
    })();
  }
}
ReportsFilterInput.initClass();

export default ReportsFilterInput;
