import _ from 'underscore';
import DateCell from 'budget_plans/views/date_cell';
import PopoverCell from 'budget_plans/views/popover_cell';
import 'views/backgrid/href_cell';
import { toCurrency } from 'helpers';
import Routes from 'helpers/routes';
import moment from 'moment';
import flightsPopoverTemplate from 'templates/budget_plans/flights_popover.pug';
import campaignsPopoverTemplate from 'templates/budget_plans/campaigns_popover.pug';

class Table extends Backbone.View {
  initialize(options = {}) {
    const columns = _.map(this._columns(), column =>
      _.extend(column, {
        label: I18n.t(column.name, { scope: 'budget_plans.index' }),
        editable: false
      })
    );

    this._grid = new Backgrid.Grid({
      columns,
      collection: this.collection,
      className: 'backgrid table table-bordered budget-plans-table'
    });

    this._paginator = new Backgrid.Extension.Paginator({
      collection: this.collection
    });

    return this.render();
  }

  render() {
    this.$el.html(this._grid.render().el);
    return this.$el.after(this._paginator.render().el);
  }

  _edit_url(model) {
    return Routes.provider_budget_plan_path({
      provider_id: model.get('provider_id'),
      id: model.get('id')
    });
  }

  _columns() {
    return [
      { name: 'id', cell: 'string' },
      { name: 'name', cell: 'href', href: this._edit_url },
      { name: 'start_date', cell: DateCell },
      { name: 'end_date', cell: DateCell },
      {
        name: 'budget_type',
        cell: 'enum',
        enumScope: 'simple_form.options.budget_plan'
      },
      {
        name: 'budget',
        cell: 'currency',
        precision: 2
      },
      {
        name: 'flights',
        cell: PopoverCell,
        template: _.compose(flightsPopoverTemplate, o =>
          _.extend({}, o, {
            moment,
            toCurrency
          })
        ),
        translation: 'budget_plans.index.flights_count'
      },
      {
        name: 'spread_type',
        cell: 'enum',
        enumScope: 'simple_form.options.budget_plan'
      },
      {
        name: 'campaigns',
        cell: PopoverCell,
        template: campaignsPopoverTemplate,
        translation: 'budget_plans.index.campaigns_count'
      }
    ];
  }
}

export default Table;
