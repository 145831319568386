import Routes from '../../../helpers/routes';
import { isDeepEmpty } from '../../../helpers/utils';
import { togglePanelCollapse } from '../../../helpers/views';
import Campaign from '../../../models/campaign';
import {
  GoogleRegionResult,
  RegionSearchType
} from '../../../shared/google_region';

const ORIGIN_REGION_FIELD_NAME = 'targeted_origin_regions';
const DESTINATION_REGION_FIELD_NAME = 'targeted_destination_regions';

/**
 * Component for Origin Destination Targeting (AKA Geo Targeting).
 * Instead of using Stickit to bind Select2 change to input value, the
 * input value is set explicitly as the JSON string instead of Select2's
 * default behavior of the selected option's id (in this case place_id).
 */
export class OriginDestinationTargetView extends Marionette.ItemView<Campaign> {
  initialize() {
    this.ui = {
      originInput: '#campaign_targeted_origin_regions',
      destinationInput: '#campaign_targeted_destination_regions'
    };

    this.bindUIElements();

    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get(ORIGIN_REGION_FIELD_NAME)) ||
        !isDeepEmpty(this.model.get(DESTINATION_REGION_FIELD_NAME))
    );

    this.initGoogleRegionSelect(this.ui.originInput, ORIGIN_REGION_FIELD_NAME);
    this.initGoogleRegionSelect(
      this.ui.destinationInput,
      DESTINATION_REGION_FIELD_NAME
    );
  }

  private initGoogleRegionSelect($el: JQuery, fieldName: string) {
    const model = this.model;

    // Initialize the element bounded to Select2 with any arbitrary value.
    // This is necessary to invoke Select2's `initSelection` callback for
    // prediction page because this page doesn't run through Rails.
    // The value set here is ignored and the model's value is used.
    $el.val('triggerInitSelection');

    $el.select2({
      minimumInputLength: 2,
      ajax: {
        url: Routes.api_geo_region_search_path(),
        data: (term, _page) => {
          return {
            search_string: term,
            region_search_type: RegionSearchType.TARGETING
          };
        },
        results(data: GoogleRegionResult[], _params) {
          return {
            results: data.map(region => ({
              id: region.place_id,
              text: region.display_name,
              ...region
            }))
          };
        }
      },
      multiple: true,
      allowClear: true,
      initSelection(_element, callback) {
        const regions: GoogleRegionResult[] = model.get(fieldName) || [];
        if (regions.length) {
          callback(
            regions.map(region => ({
              id: region.place_id,
              text: region.display_name,
              ...region
            }))
          );
        }
      }
    } as Select2Options);

    this.setInputValue($el, fieldName);
    $el.on('change', () => this.setInputValue($el, fieldName));

    this.registerClearButton($el, fieldName);
  }

  /** Sets select2 value as JSON string to input element value */
  private setInputValue($el: JQuery, fieldName: string) {
    const formattedData = $el
      .select2('data')
      .map((region: GoogleRegionResult) => ({
        ...region,
        // clear select2-specific fields
        id: undefined,
        text: undefined
      }));

    $el.val(JSON.stringify(formattedData));
    this.model.set(fieldName, formattedData);
  }

  private registerClearButton($el: JQuery, fieldName: string) {
    $el.siblings('.clear-all').on('click', e => {
      e.preventDefault();
      $el.val([]).trigger('change');
      this.model.set(fieldName, []);
    });
  }
}
