import _ from 'underscore';
import Distribution from '../distributions/models/distribution';
import Campaign from '../models/campaign';
import template from '../templates/campaigns/distribution_offers_selector.pug';
import ItemSelector from './item_selector';
import OfferSelector from './offer_selector';

interface DistributionOffersSelectorOptions
  extends Backbone.ViewOptions<Campaign> {
  distribution_selector: ItemSelector<Distribution>;
}

class DistributionOffersSelector extends Marionette.ItemView<Campaign> {
  template!: typeof template;
  options!: DistributionOffersSelectorOptions;
  offer_selectors!: OfferSelector[];
  distribution_selector?: ItemSelector<Distribution>;
  private $input?: JQuery<HTMLTextAreaElement>;
  private $container?: JQuery<HTMLElement>;

  static initClass() {
    this.prototype.template = template;

    this.prototype.ui = {
      selectors: 'input.offer-selector'
    };
  }

  initialize(options: DistributionOffersSelectorOptions) {
    this.options = options;
    this.offer_selectors = [];
    this._bindDistributionSelector();
    this.render();
  }

  serializeData() {
    return {
      exposure_type: this.model.get('exposure_type'),
      offer_ids: this.model.get('offer_ids') || [],
      offer_ids_disabled: this.$el.is('.disabled') || this.$el.is('.readonly'),
      distribution:
        this.distribution_selector && this.distribution_selector.getSelected()
    };
  }

  onRender() {
    this._initOfferSelectors();
    this._updateModel();
  }

  onBeforeRender() {
    this.offer_selectors.map(selector => selector.destroy());
  }

  attachElContent(html: string) {
    this.$input = this.$('textarea').hide() as JQuery<HTMLTextAreaElement>;
    if (!this.$container) {
      this.$container = $('<div>').insertAfter(this.$input);
    }
    this.$container.html(html);
  }

  _bindDistributionSelector() {
    this.distribution_selector = this.options.distribution_selector;
    if (this.distribution_selector) {
      this.distribution_selector.on('change', () => this.render());
    }
  }

  _updateModel() {
    this.model.set(
      'offer_ids',
      _.map(this.ui.selectors, (input: HTMLInputElement) => input.value)
    );
  }

  // TODO(b/198963944): Add unit tests to cover new functionality
  _initOfferSelectors() {
    const campaign = this.model;
    this.offer_selectors = _.map(this.ui.selectors, input => {
      const selector = new OfferSelector({
        el: input,
        data: (gon.offers || []).map(offer => ({
          text: offer.name,
          campaign,
          ...offer
        })),
        allowClear: this.ui.selectors.length > 1
      });
      return selector.on('change', () => this._updateModel());
    });
  }
}
DistributionOffersSelector.initClass();

export default DistributionOffersSelector;
