import _ from 'underscore';
type PopoverContent = string | Element | ((this: Element) => string | Element);

export interface PopoverOptions<T extends Backbone.Model = Backbone.Model>
  extends Backbone.ViewOptions<T> {
  content: PopoverContent;
  popover: PopoverContent;
  popover_class?: string;
}

class Popover<
  T extends Backbone.Model = Backbone.Model
> extends Backbone.View<T> {
  content!: PopoverContent;
  private shown!: boolean;
  private options!: PopoverOptions;
  private popover!: PopoverContent;
  private popover_class!: string;
  private timeout?: number;

  constructor(options: PopoverOptions<T>) {
    super(options);
  }

  events() {
    return {
      mouseenter: this.onMouseEnter,
      mouseleave: this.onMouseLeave
    };
  }

  initialize(options: PopoverOptions<T>) {
    this.shown = false;

    this.options = options;
    this.content = this.options.content;
    this.popover = this.options.popover;
    this.popover_class = this.options.popover_class || 'popover-large';
    this.initPopover();
  }

  render() {
    const content = _.result(this, 'content');
    this.$el
      .empty()
      .append($('<span>').addClass('popover-trigger').text(content));
    return this;
  }

  private showPopover() {
    if (!this.shown) {
      this.$el.popover('show');
      this.shown = true;
    }
  }

  private hidePopover() {
    if (this.shown) {
      this.$el.popover('hide');
      this.shown = false;
    }
  }

  private onMouseEnter() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(this.showPopover.bind(this), 200);
  }

  private onMouseLeave() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(this.hidePopover.bind(this), 200);
  }

  private initPopover() {
    const content = this.popover;

    // Bootstrap has a default list of acceptable HTML tags for popovers. This
    // list excludes table tags. Therefore, we need to append that list with
    // table-related tags.
    // https://getbootstrap.com/docs/4.3/getting-started/javascript/#sanitizer
    const allowedTags = {
      ...$.fn.popover.prototype.constructor.Constructor.DEFAULTS.whiteList,
      table: [],
      thead: [],
      tbody: [],
      tr: [],
      th: [],
      td: []
    };

    this.$el.popover({
      html: true,
      placement: 'top',
      content,
      container: 'body',
      trigger: 'manual',
      whiteList: allowedTags
    });

    const popover = this.$el.data('bs.popover').tip();
    popover.on('mouseenter', this.onMouseEnter.bind(this));
    popover.on('mouseleave', this.onMouseLeave.bind(this));
    popover.addClass(this.popover_class);
  }
}

export default Popover;
