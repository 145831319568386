// Warns the user of unsaved changes when navigating away from page.

interface DirtyWarningOptions extends Backbone.ViewOptions<Backbone.Model> {
  form: JQuery;
}

class DirtyWarning extends Backbone.View<Backbone.Model> {
  options!: DirtyWarningOptions;
  private $form!: JQuery;
  private dirty = false;

  constructor(options: DirtyWarningOptions) {
    super(options);
  }

  initialize(options: DirtyWarningOptions) {
    this.options = options;
    this.$form = this.options.form;
    this.bindEvents();
    this.bindWarningEvent();
  }

  private bindWarningEvent() {
    window.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
      if (this.dirty) {
        e.preventDefault();
        e.returnValue = I18n.t('dirty_warning');
        return e.returnValue;
      }
    });
  }

  private bindEvents() {
    (this.model || this.$form).on('change', () => {
      this.dirty = true;
    });

    this.$form.on('submit', event => {
      this.dirty = false;
      return event;
    });
  }
}
export default DirtyWarning;
