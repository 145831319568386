// tslint:disable:ordered-imports TODO: resolve import order concerns
import 'babel-polyfill';
import 'core-js/es6/reflect';
import 'core-js/es7/reflect';
import 'hammerjs';
import 'zone.js/dist/zone';

import 'jquery';
import 'bootstrap-sass';
import 'backbone';
import 'backbone.marionette';
import 'backbone-relational';
import 'backbone.paginator';
import 'backbone.stickit';
import './stickit_handlers';

import 'backgrid';
import 'backgrid-moment-cell';
import 'backgrid-filter';
import 'backgrid-select-all';
import 'backgrid-paginator';

import 'Select2';
import './select2_defaults';
import 'bootstrap-select';
import './bootstrap-datepicker';
import 'autonumeric';
import 'blueimp-file-upload';

import '@client-side-validations/client-side-validations';

import './validations';

window.gon = window.gon || { config: {} };

// Include all locale files in the webpack's dependency graph.
// This does not actually bundle the files
require.context('../../../config/locales', false, /.*\.yml$/);

// For non-en, the lang file is included as a separate script tag and
// creates window.I18n object
import i18n from 'i18n-js';
window.I18n = Object.assign(i18n, gon.config.i18n, window.I18n);
