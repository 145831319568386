import _ from 'underscore';
import template from '../templates/providers/report_filter_item.pug';

interface ReportsFilterItemsOptions
  extends Backbone.ViewOptions<Backbone.Model> {
  collections: { [type: string]: Backbone.Collection<Backbone.Model> };
}

class ReportsFilterItems extends Marionette.ItemView<Backbone.Model> {
  template!: typeof template;
  options!: ReportsFilterItemsOptions;
  collections!: { [type: string]: Backbone.Collection<Backbone.Model> };

  static initClass() {
    this.prototype.template = template;
  }

  events() {
    return {
      'click .label > .close': this.removeType
    };
  }

  initialize(options: ReportsFilterItemsOptions) {
    this.options = options;
    this.collections = this.options.collections;

    for (const type of Object.keys(this.collections)) {
      const collection = this.collections[type];
      collection.on('add remove reset', this.render, this);
    }

    return this.render();
  }

  serializeData() {
    return {
      pins_count: this.collections.pins.length,
      campaigns_count: this.collections.campaigns.length,
      offers_count: this.collections.offers.length
    };
  }

  removeType(e: JQuery.Event) {
    const type = $(e.target).parent('.label').data('type');
    if (_.has(this.collections, type)) {
      this.collections[type].each(m => m.set('selected', false));
      this.collections[type].reset();
    }

    return e.preventDefault();
  }
}
ReportsFilterItems.initClass();

export default ReportsFilterItems;
