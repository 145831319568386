import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app/app_state';
import { SetLibraryLoaded } from '../../store/app/common/common_actions';
import { loaded } from '../../store/entity/loading';
import { LibraryLoader } from './library_loader';

export const GOOGLE_CHARTS_ID = 'GOOGLE_CHARTS';
const GOOGLE_CHARTS_SRC = '//www.gstatic.com/charts/loader.js';

const VERSION = 'current';
const PACKAGES = ['corechart'];

@Injectable({
  providedIn: 'root'
})
export class GoogleChartsService {
  // A promise for non-angular code that depends on google charts.
  // Can listen on this to know when the google charts library has loaded.
  // TODO(b/127460620): Remove when google charts is not used by non-angular code.
  public static promise = new Promise(resolve => {
    GoogleChartsService.resolveLoadPromise = resolve;
  });
  private static resolveLoadPromise: (value?: unknown) => void;

  constructor(
    private store: Store<AppState>,
    private libraryLoader: LibraryLoader
  ) {
    //
  }

  public load() {
    const bundleId = GOOGLE_CHARTS_ID;
    const bundleSources = [GOOGLE_CHARTS_SRC];

    this.libraryLoader
      .load({
        bundleId,
        bundleSources,
        onLoad: () => this.handleOnLoad()
      })
      .subscribe();
  }

  private handleOnLoad() {
    google.charts.load(VERSION, { packages: PACKAGES, language: I18n.locale });
    google.charts.setOnLoadCallback(() => {
      this.store.dispatch(
        new SetLibraryLoaded({
          id: GOOGLE_CHARTS_ID,
          loaded: loaded(true)
        })
      );
      GoogleChartsService.resolveLoadPromise();
    });
  }
}
