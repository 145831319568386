export default function ($el: JQuery) {
  $el.each(function () {
    const label = $(this).data('or-none');
    const $others = $(':checkbox', this);
    const disabled = $others.hasClass('permission-disable');
    const $container = $('<label>').addClass('checkbox');

    const $none = $('<input>')
      .attr('type', 'checkbox')
      .prop('disabled', disabled)
      .appendTo($container);

    $container.append(label);

    $none.on('change.checkboxes_or_none', () => {
      if ($none.is(':checked')) {
        return $others.prop('checked', false).trigger('change');
      }
    });

    $others.on('change.checkboxes_or_none', () => $none.prop('checked', false));

    $(this).on('change.checkboxes_or_none', ':checkbox', () => {
      if ($others.filter(':checked').length === 0) {
        return $none.prop('checked', true);
      }
    });

    $(this).find('.controls').prepend($container);

    $(':checkbox:first', this).trigger('change.checkboxes_or_none');
  });
}
