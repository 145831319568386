import Backbone from 'backbone';
import ComputedFields from 'backbone-computedfields';
import moment from 'moment';
import _ from 'underscore';
import FlightsCollection from '../collections/flights';
import Campaign from './campaign';
import Flight, { BUDGET_TO_HUNDREDTHS } from './flight';

export const SEARCH_EDITABLE_FIELDS = [
  'start_date',
  'end_date',
  'io_id',
  'flights',
  'budget_type',
  'customer_line_item_name'
];

export const FIRST_LOOK_EDITABLE_FIELDS = ['budget', 'start_date', 'io_id'];

export const BUDGET_TYPES = {
  BILLABLE: 'billable',
  BONUS: 'bonus',
  PUSH: 'push',
  TEST: 'test',
  BUG: 'bug',
  FIXED: 'fixed',
  SEARCH: 'search',
  INTERNAL: 'internal'
};

/**
 * Search channel campaigns can have budget_type of 'search' or 'internal'
 * b/110036653
 */
export const SEARCH_BUDGET_TYPES = [BUDGET_TYPES.SEARCH, BUDGET_TYPES.INTERNAL];

/**
 * Intent channel campaigns can have budget_type of 'bonus' or 'internal'.
 */
export const INTENT_BUDGET_TYPES = [BUDGET_TYPES.BONUS, BUDGET_TYPES.INTERNAL];

/**
 * Channels other than search and intent can have any budget_type other than
 * 'search'.
 */
export const STANDARD_BUDGET_TYPES = [
  BUDGET_TYPES.BILLABLE,
  BUDGET_TYPES.BONUS,
  BUDGET_TYPES.PUSH,
  BUDGET_TYPES.TEST,
  BUDGET_TYPES.BUG,
  BUDGET_TYPES.FIXED,
  BUDGET_TYPES.INTERNAL
];

export interface BudgetPlanCampaignAttributes {
  id: number;
  spent: number;
}

interface BudgetPlanAttributes {
  id: null;
  name: null;
  budget: null;
  budget_type: null;
  start_date: null;
  end_date: null;
  spread_type: null;
  campaigns: BudgetPlanCampaignAttributes[];
  spent: number;
  io_id: number;
  io_budget: number;
  flights_overflow: boolean;
  first_look: boolean;
}

class BudgetPlan extends Backbone.RelationalModel {
  // tslint:disable-next-line:no-any
  [x: string]: any;

  computedFields!: ComputedFields;

  // The previous spread_type, before it was forced to change due to a change in
  // the campaign channel.
  private _prev_spread?: string;

  static initClass() {
    this.prototype.defaults = (): BudgetPlanAttributes => {
      return {
        id: null,
        name: null,
        budget: null,
        budget_type: null,
        start_date: null,
        end_date: null,
        spread_type: null,
        campaigns: [],
        spent: 0,
        io_id: 0,
        io_budget: 0,
        flights_overflow: true,
        first_look: false
      };
    };

    this.prototype.relations = [
      {
        type: Backbone.HasMany,
        key: 'flights',
        relatedModel: Flight,
        collectionType: FlightsCollection,
        collectionOptions(budget_plan: BudgetPlan) {
          return { budget_plan };
        }
      }
    ];

    this.prototype.computed = {
      spent: {
        depends: ['campaigns'],
        get(fields: BudgetPlanAttributes) {
          return _.reduce(
            fields.campaigns,
            (sum, c) => sum + c['spent'] || 0,
            0
          );
        }
      }
    };
  }

  initialize() {
    this.computedFields = new ComputedFields(this);
    this.set('budget', this.get('budget_hundredths') / BUDGET_TO_HUNDREDTHS);

    this.listenTo(this.get('flights'), 'change update', () =>
      this.trigger('change change:flights', this)
    );

    this.listenTo(this, 'change:first_look', () => {
      if (this.isFirstLook()) {
        this.set('spread_type', 'CUSTOM');
        this.set('budget_type', BUDGET_TYPES.FIXED);
        this.set('end_date', this.get('start_date'));
      }
    });

    this.listenTo(this, 'change:start_date', () => {
      if (this.isFirstLook()) {
        this.set('end_date', this.get('start_date'));
      }
    });

    this.listenTo(this, 'change:budget_hundredths', () => {
      this.set('budget', this.get('budget_hundredths') / BUDGET_TO_HUNDREDTHS);
    });
  }

  canAddFlights() {
    return (
      this.get('start_date') &&
      this.get('end_date') &&
      moment
        .utc(this.get('end_date'))
        .isSameOrAfter(moment.utc(this.get('start_date')), 'day') &&
      moment.utc(this.get('end_date')).isSameOrAfter(moment.utc(), 'day') &&
      // Search does not use spread type or budget.
      (this.isSearch() ||
        (this.get('spread_type') === 'CUSTOM' && this.get('budget') > 0))
    );
  }

  isShared() {
    return this.has('campaigns') && this.get('campaigns').length > 1;
  }

  isSearch() {
    return this.get('budget_type') === 'search';
  }

  isFirstLook() {
    return this.get('first_look') === true;
  }

  /**
   * Updates the type and spread for this budget plan to be valid for the given
   * Campaign.
   */
  updateChannelBasedFields(campaign: Campaign) {
    // Keep the previous spread type so that we can revert the forced change
    // if we switch back to a non-search channel.
    if (campaign.isSearch()) {
      this._prev_spread = this.get('spread_type');
      this.set('spread_type', 'MONTHLY');
    } else {
      this.set('spread_type', this._prev_spread || 'CUSTOM');
      this._prev_spread = undefined;
    }

    const currentBudgetType = this.get('budget_type');
    if (campaign.isSearch()) {
      this.set(
        'budget_type',
        SEARCH_BUDGET_TYPES.includes(currentBudgetType)
          ? currentBudgetType
          : BUDGET_TYPES.SEARCH
      );
    } else if (campaign.isIntent()) {
      this.set(
        'budget_type',
        INTENT_BUDGET_TYPES.includes(currentBudgetType)
          ? currentBudgetType
          : BUDGET_TYPES.BONUS
      );
    } else {
      this.set(
        'budget_type',
        STANDARD_BUDGET_TYPES.includes(currentBudgetType)
          ? currentBudgetType
          : BUDGET_TYPES.BILLABLE
      );
    }
  }

  isFixed() {
    return this.get('budget_type') === 'fixed';
  }

  hasOverflow() {
    return !this.isFixed() && this.get('flights_overflow');
  }

  isEndDateInPast() {
    return moment().isSameOrAfter(moment.utc(this.get('end_date')));
  }

  isStartDateInPast() {
    return moment()
      .utc()
      .startOf('day')
      .isAfter(moment.utc(this.get('start_date')));
  }

  hasFlights() {
    return this.get('flights').length > 0;
  }

  splitBudgetToFlights() {
    if (this.isStartDateInPast()) {
      // pop-up alert for budget plan start date passed
      window.alert(
        I18n.t('simple_form.flights.split_equally_start_date_alert')
      );
      return;
    }

    if (!window.confirm(I18n.t('simple_form.flights.split_confirm'))) {
      return;
    }

    const flights = this.get('flights').reject((flight: Flight) =>
      flight.isEndDateInPast()
    );

    const budget = this.get('flights')
      .filter((flight: Flight) => flight.isEndDateInPast())
      .reduce(
        (budgetSum: number, flight: Flight) => budgetSum - flight.get('budget'),
        this.get('budget')
      );

    const budgetHundredths = Math.round(budget * BUDGET_TO_HUNDREDTHS);
    const numFlights = flights.length;

    let sumHundredths = 0;
    flights.forEach((flight: Flight, i: number) => {
      const split = Math.ceil(
        (budgetHundredths - sumHundredths) / (numFlights - i)
      );
      flight.set('budget', split / BUDGET_TO_HUNDREDTHS);
      sumHundredths += split;
    });
  }

  createMonthlyFlights() {
    if (this.isStartDateInPast()) {
      // pop-up alert for budget plan start date passed
      window.alert(
        I18n.t('simple_form.flights.monthly_flights_start_date_alert')
      );
      return;
    }

    if (!this.canAddFlights()) {
      return;
    }
    const flights = this.get('flights');

    const startDate = moment.utc(flights.getStartTime());
    const endDate = moment.utc(flights.getEndTime());
    if (flights.length > 0) {
      // pop-up confirmation for deleting current flights
      if (
        !window.confirm(I18n.t('simple_form.flights.monthly_flights_confirm'))
      ) {
        return;
      }
    }

    flights.reset();

    const yearlySuffix = moment(endDate).year() - startDate.year() > 0;
    const monthIterator = startDate.clone();
    let totalBudgetHundredthsLeft = Math.round(
      this.get('budget') * BUDGET_TO_HUNDREDTHS
    );
    let firstDay = moment(monthIterator).startOf('month');
    let lastDay = moment(monthIterator).add(1, 'month').startOf('month');
    let monthlyStartDate,
      monthlyEndDate,
      monthlyBudgetHundredths,
      budgetHundredthsPerDay;

    do {
      monthlyStartDate = firstDay.isSameOrAfter(startDate, 'day')
        ? firstDay
        : startDate;

      monthlyEndDate = lastDay.isSameOrBefore(endDate, 'day')
        ? lastDay
        : moment(endDate).add(1, 'day');

      budgetHundredthsPerDay =
        totalBudgetHundredthsLeft / (endDate.diff(monthlyStartDate, 'day') + 1);

      monthlyBudgetHundredths = Math.round(
        monthlyEndDate.diff(monthlyStartDate, 'days') * budgetHundredthsPerDay
      );

      flights.addFlight({
        start_date: +monthlyStartDate,
        end_date: +monthlyEndDate,
        budget: monthlyBudgetHundredths / BUDGET_TO_HUNDREDTHS,
        name: monthIterator.format(yearlySuffix ? "MMMM, YY'" : 'MMMM')
      });

      totalBudgetHundredthsLeft -= monthlyBudgetHundredths;

      monthIterator.add(1, 'month');
      firstDay = moment(monthIterator).startOf('month');
      lastDay = moment(monthIterator).add(1, 'month').startOf('month');
    } while (moment(endDate).isSameOrAfter(firstDay, 'day'));

    // budget leftovers after rounding the monthly budget
    flights.models[flights.models.length - 1].set(
      'budget',
      (flights.models[flights.models.length - 1].get('budget_hundredths') +
        totalBudgetHundredthsLeft) /
        BUDGET_TO_HUNDREDTHS
    );
  }
}

BudgetPlan.initClass();
BudgetPlan.setup();

export default BudgetPlan;
