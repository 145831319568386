import _ from 'underscore';

// TODO(b/129067522): Missing Backgrid.Column typing
interface BackgridColumn extends Backbone.Model {
  renderable(): boolean;
}

class ButtonCell extends Backgrid.Cell {
  template!: (data: {}) => string;
  column!: BackgridColumn;

  static initClass() {
    this.prototype.className = 'button-cell';

    this.prototype.template = _.template('<a href=""><%= content %></a>');
  }

  events() {
    return {
      'click a': this.onClick
    };
  }

  render() {
    const renderable = Backgrid.callByNeed(
      this.column.renderable(),
      this.column,
      this.model
    );
    if (renderable) {
      this.$el.html(this.template({ content: this.column.get('content') }));
    } else {
      this.$el.html('');
    }
    this.$el.addClass(this.column.get('name'));
    this.delegateEvents();
    return this;
  }

  private onClick(e: Event) {
    e.preventDefault();
    Backgrid.callByNeed(this.column.get('action'), this.column, this.model);
  }
}
ButtonCell.initClass();

Object.assign(Backgrid, { ButtonCell });
