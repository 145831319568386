const CampaignSearchSettingsView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '[name="campaign[search_keywords]"]': {
      observe: 'search_keywords',
      update: 'onModelChange'
    },
    '[name="campaign[search_aliases]"]': {
      observe: 'search_aliases',
      update: 'onModelChange'
    },
    '[name="campaign[organic_search_default]"]': {
      observe: 'organic_search_default',
      update: 'onModelChange'
    }
  },

  ui: {
    searchTags: '.tags'
  },

  initialize(options) {
    this.stickit();
    this.bindUIElements();
    this.renderViews();
  },

  renderViews() {
    this.initTagSelectors();
  },

  initTagSelectors() {
    this.ui.searchTags.select2({
      tags: [],
      minimumInputLength: 3,
      maximumInputLength: 35,
      tokenSeparators: [',', '\n']
    });
  },

  onModelChange($el, val, model, options) {
    $el.select2('val', [val]);
  }
});

export default CampaignSearchSettingsView;
