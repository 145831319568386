import { logoUrl } from '../../helpers';

type EscapeFn = (text: string) => string;

interface CategoryResult {
  element: HTMLElement;
  text: string;
}

export default function ($el: JQuery) {
  const $select = $el;

  const format = (
    category: CategoryResult,
    _container: JQuery,
    escape: EscapeFn
  ) => {
    const $option = $(category.element);
    const icon = logoUrl($option.data('logo-image'));
    const categoryText = escape(category.text);

    if (icon != null) {
      return `<img src='${icon}' class='category-icon'> ${categoryText}`;
    } else {
      return categoryText;
    }
  };

  const formatResult = (
    category: CategoryResult,
    container: JQuery,
    _query: string,
    escape: EscapeFn
  ) => format(category, container, escape);

  $select.select2({
    formatResult,
    formatSelection: format,
    dropdownCssClass: 'select2-category-dropdown'
  });
}
