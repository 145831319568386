import BudgetPlan from 'models/budget_plan';

import Form from 'budget_plans/views/form';
import SpentView from 'campaigns/views/spent/component';

class App {
  constructor(options) {
    const budget_plan = new BudgetPlan(gon.budget_plan, { parse: true });
    new Form({
      model: budget_plan,
      el: options.el,
      earliestTimezoneOffsetMs: gon.earliest_timezone_offset_ms,
      latestTimezoneOffsetMs: gon.latest_timezone_offset_ms
    });

    $(options.el)
      .find('.budget-plan-spent')
      .each(function () {
        new SpentView({
          model: budget_plan,
          el: this
        });
      });
  }
}

export default App;
