import _ from 'underscore';
import DisableIf from 'ui/form/disable_if';
import { togglePanelCollapse } from 'helpers/views';
import { isDeepEmpty } from 'helpers/utils';

const CampaignWeatherTargetingView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '[name="campaign[low_temperature]"]': 'low_temperature',
    '[name="campaign[high_temperature]"]': 'high_temperature',
    '#campaign_weather_states': 'weather_states'
  },

  ui: {
    enableTemperature: '#campaign_enable_temperature',
    temperatureFields: '.temperature-fields',
    temperatureBoundaries: '[name="campaign[temperature_boundries]"]',
    lowTemperature: '[name="campaign[low_temperature]"]',
    highTemperature: '[name="campaign[high_temperature]"]',
    textSeparator: '.temperature-fields span',
    weatherStates: '#campaign_weather_states'
  },

  events: {
    'change @ui.enableTemperature': 'onEnableTemperatureChange',
    'change @ui.temperatureBoundaries': '_toggleTemperatureFields'
  },

  initialize() {
    this.bindUIElements();
    this.renderViews();
    this.stickit();
    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get('low_temperature')) ||
        !isDeepEmpty(this.model.get('high_temperature')) ||
        !isDeepEmpty(this.model.get('weather_states'))
    );
  },

  renderViews() {
    this.ui.weatherStates.addClass('select2-stickit').select2();

    this._toggleTemperatureFields();

    DisableIf(this.ui.temperatureFields, 'disable-if', {
      field: 'enable_temperature',
      value: 'false'
    });
  },

  onEnableTemperatureChange() {
    if (!this.ui.enableTemperature.is(':checked')) {
      _.each([this.ui.lowTemperature, this.ui.highTemperature], $input => {
        $input.val('').trigger('change');
      });
    }
  },

  _toggleTemperatureFields() {
    this.ui.highTemperature.toggle(
      this.ui.temperatureBoundaries.val() !== 'above'
    );
    this.ui.lowTemperature.toggle(
      this.ui.temperatureBoundaries.val() !== 'below'
    );
    this.ui.textSeparator.toggle(
      this.ui.temperatureBoundaries.val() === 'between'
    );
  }
});

export default CampaignWeatherTargetingView;
