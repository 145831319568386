import DisableIf from '../form/disable_if';

export default function ($el: JQuery) {
  $el.each(function () {
    const $checkbox = $(':checkbox', this) as JQuery<HTMLInputElement>;
    const $select = $('select', this) as JQuery<HTMLSelectElement>;

    DisableIf($select, 'disable-if', {
      field: $checkbox,
      value: 'false'
    });

    const clearSelect = () => {
      if (!$checkbox.is(':checked') && $select.val()) {
        $select.val('').trigger('change');
      }
    };

    $checkbox.on('change', clearSelect);

    clearSelect();
  });
}
