
/*
File generated by js-routes 1.4.14
Based on Rails 6.1.7.6 routes of Adman::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accept_invite => (/:locale)/accept_invite(.:format)
  // function(options)
  accept_invite_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"accept_invite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// admin_settings_provider => (/:locale)/providers/:id/admin_settings(.:format)
  // function(id, options)
  admin_settings_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"admin_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// adventure_ad_create => (/:locale)/providers/:provider_id/ads/new(.:format)
  // function(provider_id, options)
  adventure_ad_create_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"ads",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// adventure_ad_edit => (/:locale)/providers/:provider_id/ads/:offer_id/edit(.:format)
  // function(provider_id, offer_id, options)
  adventure_ad_edit_path: Utils.route([["locale",false],["provider_id",true],["offer_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"ads",false],[2,[7,"/",false],[2,[3,"offer_id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// adventure_location_create => (/:locale)/providers/:provider_id/locations/new(.:format)
  // function(provider_id, options)
  adventure_location_create_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// adventure_location_edit => (/:locale)/providers/:provider_id/locations/:pin_id/edit(.:format)
  // function(provider_id, pin_id, options)
  adventure_location_edit_path: Utils.route([["locale",false],["provider_id",true],["pin_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"pin_id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// alerts_provider => (/:locale)/providers/:id/alerts(.:format)
  // function(id, options)
  alerts_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"alerts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// all_by_ad_format_rate_cards => (/:locale)/rate_cards/all_by_ad_format(.:format)
  // function(options)
  all_by_ad_format_rate_cards_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"rate_cards",false],[2,[7,"/",false],[2,[6,"all_by_ad_format",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// angular_app => (/:locale)/app(/*rest)(.:format)
  // function(options)
  angular_app_path: Utils.route([["locale",false],["rest",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"app",false],[2,[1,[2,[7,"/",false],[5,[3,"rest",false],false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_bulk_download => /api/providers/:provider_id/bulk/:type(.:format)
  // function(provider_id, type, options)
  api_bulk_download_path: Utils.route([["provider_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_bulk_fields => /api/providers/:provider_id/bulk/:type/fields(.:format)
  // function(provider_id, type, options)
  api_bulk_fields_path: Utils.route([["provider_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[3,"type",false],[2,[7,"/",false],[2,[6,"fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_bulk_scheduling_download => /api/providers/:provider_id/bulk/schedules(.:format)
  // function(provider_id, options)
  api_bulk_scheduling_download_path: Utils.route([["provider_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[6,"schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_bulk_scheduling_upload => /api/providers/:provider_id/bulk/schedules(.:format)
  // function(provider_id, options)
  api_bulk_scheduling_upload_path: Utils.route([["provider_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[6,"schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_bulk_trackables_download => /api/trackables/bulk(.:format)
  // function(options)
  api_bulk_trackables_download_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"trackables",false],[2,[7,"/",false],[2,[6,"bulk",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_bulk_trackables_upload => /api/trackables/bulk(.:format)
  // function(options)
  api_bulk_trackables_upload_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"trackables",false],[2,[7,"/",false],[2,[6,"bulk",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_bulk_upload => /api/providers/:provider_id/bulk/:type(.:format)
  // function(provider_id, type, options)
  api_bulk_upload_path: Utils.route([["provider_id",true],["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_categories => /api/categories(.:format)
  // function(options)
  api_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_create_account => /api/local/createAccount(.:format)
  // function(options)
  api_create_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"local",false],[2,[7,"/",false],[2,[6,"createAccount",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_geo_region_search => /api/geo/region_search(.:format)
  // function(options)
  api_geo_region_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"geo",false],[2,[7,"/",false],[2,[6,"region_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_geo_region_search_in_country => /api/geo/region_search_in_country(.:format)
  // function(options)
  api_geo_region_search_in_country_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"geo",false],[2,[7,"/",false],[2,[6,"region_search_in_country",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_invoice_orders_search => /api/invoice_orders/search(.:format)
  // function(options)
  api_invoice_orders_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"invoice_orders",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_local_promotion_code => /api/local/promotionCodes/:id(.:format)
  // function(id, options)
  api_local_promotion_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"local",false],[2,[7,"/",false],[2,[6,"promotionCodes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_pending_approve => /api/providers/:provider_id/approve(.:format)
  // function(provider_id, options)
  api_pending_approve_path: Utils.route([["provider_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_pending_details => /api/providers/:provider_id/pendings(.:format)
  // function(provider_id, options)
  api_pending_details_path: Utils.route([["provider_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"pendings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_pending_reject => /api/providers/:provider_id/reject(.:format)
  // function(provider_id, options)
  api_pending_reject_path: Utils.route([["provider_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"reject",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_pendings_list => /api/pendings(.:format)
  // function(options)
  api_pendings_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"pendings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_pins => /api/providers/:provider_id/pins(.:format)
  // function(provider_id, options)
  api_pins_path: Utils.route([["provider_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"pins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_plus_accept_token => /api/plus/invites/accept/:token(.:format)
  // function(token, options)
  api_plus_accept_token_path: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"plus",false],[2,[7,"/",false],[2,[6,"invites",false],[2,[7,"/",false],[2,[6,"accept",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_plus_generate_invite => /api/plus/invites(.:format)
  // function(options)
  api_plus_generate_invite_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"plus",false],[2,[7,"/",false],[2,[6,"invites",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_plus_verify_token => /api/plus/invites/verify/:token(.:format)
  // function(token, options)
  api_plus_verify_token_path: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"plus",false],[2,[7,"/",false],[2,[6,"invites",false],[2,[7,"/",false],[2,[6,"verify",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_providers_autocomplete => /api/providers/autocomplete(.:format)
  // function(options)
  api_providers_autocomplete_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[6,"autocomplete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_providers_list => /api/providers(.:format)
  // function(options)
  api_providers_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// app_accept_invite => (/:locale)/app/accept_invite(.:format)
  // function(options)
  app_accept_invite_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"accept_invite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// app_login => (/:locale)/app/login(.:format)
  // function(options)
  app_login_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// audit => (/:locale)/audit(.:format)
  // function(options)
  audit_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"audit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// autocomplete_provider_pins => (/:locale)/providers/:provider_id/pins/autocomplete(.:format)
  // function(provider_id, options)
  autocomplete_provider_pins_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"pins",false],[2,[7,"/",false],[2,[6,"autocomplete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// big => (/:locale)/big(.:format)
  // function(options)
  big_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"big",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// billing_details_provider => (/:locale)/providers/:id/billing_details(.:format)
  // function(id, options)
  billing_details_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"billing_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// brand_provider => (/:locale)/providers/:id/brand(.:format)
  // function(id, options)
  brand_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"brand",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// brands_list => /api/brands/list(.:format)
  // function(options)
  brands_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"brands",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// broadcasters_register => (/:locale)/broadcasters/register(.:format)
  // function(options)
  broadcasters_register_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"broadcasters",false],[2,[7,"/",false],[2,[6,"register",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// broadcasters_submit => (/:locale)/broadcasters/submit(.:format)
  // function(options)
  broadcasters_submit_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"broadcasters",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// bulk_permissions => /api/bulk/permissions(.:format)
  // function(options)
  bulk_permissions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// campaign_ad_preview => (/:locale)/providers/:provider_id/campaigns/:campaign_id/offers/:offer_id/preview(.:format)
  // function(provider_id, campaign_id, offer_id, options)
  campaign_ad_preview_path: Utils.route([["locale",false],["provider_id",true],["campaign_id",true],["offer_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_id",false],[2,[7,"/",false],[2,[6,"offers",false],[2,[7,"/",false],[2,[3,"offer_id",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
// configs => (/:locale)/configs(.:format)
  // function(options)
  configs_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// configs_adman => (/:locale)/configs/adman(.:format)
  // function(options)
  configs_adman_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[6,"adman",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// configs_admanage => (/:locale)/configs/admanage(.:format)
  // function(options)
  configs_admanage_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[6,"admanage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// configs_all => (/:locale)/configs/all(.:format)
  // function(options)
  configs_all_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[6,"all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// configs_dexter => (/:locale)/configs/dexter(.:format)
  // function(options)
  configs_dexter_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"configs",false],[2,[7,"/",false],[2,[6,"dexter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// credit_card_provider => (/:locale)/providers/:id/credit_card(.:format)
  // function(id, options)
  credit_card_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"credit_card",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// customers_create => (/:locale)/customers/create(.:format)
  // function(options)
  customers_create_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// delete_payment_method_provider => (/:locale)/providers/:id/delete_payment_method(.:format)
  // function(id, options)
  delete_payment_method_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"delete_payment_method",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// destroy_multiple_provider_pins => (/:locale)/providers/:provider_id/pins/destroy_multiple(.:format)
  // function(provider_id, options)
  destroy_multiple_provider_pins_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"pins",false],[2,[7,"/",false],[2,[6,"destroy_multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// download_provider_invoice => (/:locale)/providers/:provider_id/invoices/:id/download(.:format)
  // function(provider_id, id, options)
  download_provider_invoice_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_multiple_provider_campaigns => (/:locale)/providers/:provider_id/campaigns/edit_multiple(.:format)
  // function(provider_id, options)
  edit_multiple_provider_campaigns_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[6,"edit_multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_multiple_provider_pins => (/:locale)/providers/:provider_id/pins/edit_multiple(.:format)
  // function(provider_id, options)
  edit_multiple_provider_pins_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"pins",false],[2,[7,"/",false],[2,[6,"edit_multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_provider => (/:locale)/providers/:id/edit(.:format)
  // function(id, options)
  edit_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_provider_budget_plan => (/:locale)/providers/:provider_id/budget_plans/:id/edit(.:format)
  // function(provider_id, id, options)
  edit_provider_budget_plan_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"budget_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_provider_campaign => (/:locale)/providers/:provider_id/campaigns/:id/edit(.:format)
  // function(provider_id, id, options)
  edit_provider_campaign_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_provider_offer => (/:locale)/providers/:provider_id/offers/:id/edit(.:format)
  // function(provider_id, id, options)
  edit_provider_offer_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"offers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_provider_pin => (/:locale)/providers/:provider_id/pins/:id/edit(.:format)
  // function(provider_id, id, options)
  edit_provider_pin_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"pins",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// forgot_password => (/:locale)/password/forgot(.:format)
  // function(options)
  forgot_password_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"forgot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// geo_countries => /api/geo/countries(.:format)
  // function(options)
  geo_countries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"geo",false],[2,[7,"/",false],[2,[6,"countries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// jwt_cookie => (/:locale)/sessions/jwt_cookie(.:format)
  // function(options)
  jwt_cookie_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[6,"jwt_cookie",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// leads => /api/leads(.:format)
  // function(options)
  leads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"leads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// local_createSMBAccount => /api/local/createSMBAccount(.:format)
  // function(options)
  local_createSMBAccount_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"local",false],[2,[7,"/",false],[2,[6,"createSMBAccount",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// localized_root => /:locale(.:format)
  // function(locale, options)
  localized_root_path: Utils.route([["locale",true],["format",false]], {"locale":"en"}, [2,[7,"/",false],[2,[3,"locale",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// login => (/:locale)/login(.:format)
  // function(options)
  login_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// new_plus => (/:locale)/plus/new(.:format)
  // function(options)
  new_plus_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"plus",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_promotion_code => (/:locale)/promotion_codes/new(.:format)
  // function(options)
  new_promotion_code_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"promotion_codes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_provider => (/:locale)/providers/new(.:format)
  // function(options)
  new_provider_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_provider_budget_plan => (/:locale)/providers/:provider_id/budget_plans/new(.:format)
  // function(provider_id, options)
  new_provider_budget_plan_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"budget_plans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_provider_campaign => (/:locale)/providers/:provider_id/campaigns/new(.:format)
  // function(provider_id, options)
  new_provider_campaign_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_provider_offer => (/:locale)/providers/:provider_id/offers/new(.:format)
  // function(provider_id, options)
  new_provider_offer_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"offers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_provider_pin => (/:locale)/providers/:provider_id/pins/new(.:format)
  // function(provider_id, options)
  new_provider_pin_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"pins",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// okcomputer => /health
  // function(options)
  okcomputer_path: Utils.route([], {}, [2,[7,"/",false],[6,"health",false]]),
// okcomputer.root => /health/
  // function(options)
  okcomputer_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"health",false]],[7,"/",false]]),
// okcomputer.okcomputer_checks => /health/all(.:format)
  // function(options)
  okcomputer_okcomputer_checks_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"health",false]],[7,"/",false]],[2,[6,"all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// okcomputer.okcomputer_check => /health/:check(.:format)
  // function(check, options)
  okcomputer_okcomputer_check_path: Utils.route([["check",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"health",false]],[7,"/",false]],[2,[3,"check",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// pages_faq => (/:locale)/pages/faq(.:format)
  // function(options)
  pages_faq_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"faq",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pages_privacy_policy => (/:locale)/pages/privacy-policy(.:format)
  // function(options)
  pages_privacy_policy_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"privacy-policy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pages_refund_policy => (/:locale)/pages/refund-policy(.:format)
  // function(options)
  pages_refund_policy_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"refund-policy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pages_tos => (/:locale)/pages/tos(.:format)
  // function(options)
  pages_tos_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"tos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pause_provider => (/:locale)/providers/:id/pause(.:format)
  // function(id, options)
  pause_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pause",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// pendings => (/:locale)/pendings(.:format)
  // function(options)
  pendings_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"pendings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// plus => (/:locale)/plus(.:format)
  // function(options)
  plus_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"plus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// prediction_download_locations_file => /api/predictions/:prediction_id/locations_file/:id(.:format)
  // function(prediction_id, id, options)
  prediction_download_locations_file_path: Utils.route([["prediction_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"predictions",false],[2,[7,"/",false],[2,[3,"prediction_id",false],[2,[7,"/",false],[2,[6,"locations_file",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// prediction_download_locations_file_upload => /api/predictions/locations_file(.:format)
  // function(options)
  prediction_download_locations_file_upload_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"predictions",false],[2,[7,"/",false],[2,[6,"locations_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// prediction_download_results_file => /api/predictions/:prediction_id/results_file(.:format)
  // function(prediction_id, options)
  prediction_download_results_file_path: Utils.route([["prediction_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"predictions",false],[2,[7,"/",false],[2,[3,"prediction_id",false],[2,[7,"/",false],[2,[6,"results_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// predictions => /api/predictions(.:format)
  // function(options)
  predictions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"predictions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// predictions_archive => /api/predictions/archive(.:format)
  // function(options)
  predictions_archive_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"predictions",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// predictions_page => (/:locale)/predictions(/*path)(.:format)
  // function(options)
  predictions_page_path: Utils.route([["locale",false],["path",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"predictions",false],[2,[1,[2,[7,"/",false],[5,[3,"path",false],false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// previews_provider_offer => (/:locale)/providers/:provider_id/offers/:id/previews(.:format)
  // function(provider_id, id, options)
  previews_provider_offer_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"offers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"previews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// promotion_codes => (/:locale)/promotion_codes(.:format)
  // function(options)
  promotion_codes_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"promotion_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// provider => (/:locale)/providers/:id(.:format)
  // function(id, options)
  provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// provider_access => (/:locale)/providers/:provider_id/access_list/:id(.:format)
  // function(provider_id, id, options)
  provider_access_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"access_list",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// provider_access_list => (/:locale)/providers/:provider_id/access_list(.:format)
  // function(provider_id, options)
  provider_access_list_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"access_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// provider_alert => (/:locale)/providers/:provider_id/campaigns/:id/alerts/:id(.:format)
  // function(provider_id, id, id, options)
  provider_alert_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"alerts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// provider_alerts => (/:locale)/providers/:provider_id/campaigns/:id/alerts(.:format)
  // function(provider_id, id, options)
  provider_alerts_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"alerts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// provider_budget_plan => (/:locale)/providers/:provider_id/budget_plans/:id(.:format)
  // function(provider_id, id, options)
  provider_budget_plan_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"budget_plans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// provider_budget_plans => (/:locale)/providers/:provider_id/budget_plans(.:format)
  // function(provider_id, options)
  provider_budget_plans_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"budget_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// provider_campaign => (/:locale)/providers/:provider_id/campaigns/:id(.:format)
  // function(provider_id, id, options)
  provider_campaign_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// provider_campaigns => (/:locale)/providers/:provider_id/campaigns(.:format)
  // function(provider_id, options)
  provider_campaigns_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// provider_distribution => (/:locale)/providers/:provider_id/distributions/:id(.:format)
  // function(provider_id, id, options)
  provider_distribution_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"distributions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// provider_distributions => (/:locale)/providers/:provider_id/distributions(.:format)
  // function(provider_id, options)
  provider_distributions_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"distributions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// provider_invoice => (/:locale)/providers/:provider_id/invoices/:id(.:format)
  // function(provider_id, id, options)
  provider_invoice_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// provider_invoices => (/:locale)/providers/:provider_id/invoices(.:format)
  // function(provider_id, options)
  provider_invoices_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// provider_location_tag => (/:locale)/providers/:provider_id/location_tags/:id(.:format)
  // function(provider_id, id, options)
  provider_location_tag_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"location_tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// provider_location_tags => (/:locale)/providers/:provider_id/location_tags(.:format)
  // function(provider_id, options)
  provider_location_tags_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"location_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// provider_offer => (/:locale)/providers/:provider_id/offers/:id(.:format)
  // function(provider_id, id, options)
  provider_offer_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"offers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// provider_offers => (/:locale)/providers/:provider_id/offers(.:format)
  // function(provider_id, options)
  provider_offers_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"offers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// provider_pin => (/:locale)/providers/:provider_id/pins/:id(.:format)
  // function(provider_id, id, options)
  provider_pin_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"pins",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// provider_pins => (/:locale)/providers/:provider_id/pins(.:format)
  // function(provider_id, options)
  provider_pins_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"pins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// providers => (/:locale)/providers(.:format)
  // function(options)
  providers_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// providers_midmarket_create => /api/providers/midmarket/create(.:format)
  // function(options)
  providers_midmarket_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[6,"midmarket",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rate_cards => (/:locale)/rate_cards(.:format)
  // function(options)
  rate_cards_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"rate_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// report_points_provider => (/:locale)/providers/:id/reports/points(.:format)
  // function(id, options)
  report_points_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"points",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// report_provider => (/:locale)/providers/:id/reports/:type(.:format)
  // function(id, type, options)
  report_provider_path: Utils.route([["locale",false],["id",true],["type",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// reports_export_provider => (/:locale)/providers/:id/reports/export(.:format)
  // function(id, options)
  reports_export_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// reports_manage_provider => (/:locale)/providers/:id/reports/manage(.:format)
  // function(id, options)
  reports_manage_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"manage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// reports_parc_slides_templates_provider => (/:locale)/providers/:id/reports/parc_slides_templates(.:format)
  // function(id, options)
  reports_parc_slides_templates_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"parc_slides_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// reports_provider => (/:locale)/providers/:id/reports(.:format)
  // function(id, options)
  reports_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// reports_slides_templates_provider => (/:locale)/providers/:id/reports/slides_templates(.:format)
  // function(id, options)
  reports_slides_templates_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"slides_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// reset_forgot_password => (/:locale)/password/reset(.:format)
  // function(options)
  reset_forgot_password_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// resume_provider => (/:locale)/providers/:id/resume(.:format)
  // function(id, options)
  resume_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resume",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// snooze_provider_alert => (/:locale)/providers/:provider_id/campaigns/:id/alerts/:id/snooze(.:format)
  // function(provider_id, id, id, options)
  snooze_provider_alert_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"alerts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"snooze",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
// snooze_provider_alerts => (/:locale)/providers/:provider_id/campaigns/:id/alerts/snooze(.:format)
  // function(provider_id, id, options)
  snooze_provider_alerts_path: Utils.route([["locale",false],["provider_id",true],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"alerts",false],[2,[7,"/",false],[2,[6,"snooze",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// source_params => (/:locale)/sessions/source_params(.:format)
  // function(options)
  source_params_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[6,"source_params",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// stop_provider => (/:locale)/providers/:id/stop(.:format)
  // function(id, options)
  stop_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"stop",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// suspend_provider => (/:locale)/providers/:id/suspend(.:format)
  // function(id, options)
  suspend_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"suspend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// tracking_tags_provider => (/:locale)/providers/:id/tracking_tags(.:format)
  // function(id, options)
  tracking_tags_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"tracking_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// update_multiple_provider_campaigns => (/:locale)/providers/:provider_id/campaigns/update_multiple(.:format)
  // function(provider_id, options)
  update_multiple_provider_campaigns_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[6,"update_multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_multiple_provider_pins => (/:locale)/providers/:provider_id/pins/update_multiple(.:format)
  // function(provider_id, options)
  update_multiple_provider_pins_path: Utils.route([["locale",false],["provider_id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"provider_id",false],[2,[7,"/",false],[2,[6,"pins",false],[2,[7,"/",false],[2,[6,"update_multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_payment_method_provider => (/:locale)/providers/:id/update_payment_method(.:format)
  // function(id, options)
  update_payment_method_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_payment_method",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// userInfo => /api/userInfo(.:format)
  // function(options)
  userInfo_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"userInfo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_login => (/:locale)/users/login(.:format)
  // function(options)
  users_login_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// users_logout => (/:locale)/users/logout(.:format)
  // function(options)
  users_logout_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// users_signup => (/:locale)/users/signup(.:format)
  // function(options)
  users_signup_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"signup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// verify_plus => (/:locale)/plus/verify(.:format)
  // function(options)
  verify_plus_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"plus",false],[2,[7,"/",false],[2,[6,"verify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// welcome_provider => (/:locale)/providers/:id/welcome(.:format)
  // function(id, options)
  welcome_provider_path: Utils.route([["locale",false],["id",true],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"providers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"welcome",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// wizard => (/:locale)/wizard(.:format)
  // function(options)
  wizard_path: Utils.route([["locale",false],["format",false]], {}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"wizard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);

