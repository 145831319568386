import { togglePanelCollapse } from '../../../helpers/views';
import { isDeepEmpty } from '../../../helpers/utils';
import checkboxesOrNone from 'ui/form/checkboxes_or_none';

const DISPLAY_SCREEN_VALUES = ['AUTO', 'PHONE'];

const CampaignDeviceTargetingView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '[name="campaign[display_screens][]"]': {
      observe: 'display_screens',
      afterUpdate($el) {
        $el.trigger('change');
      }
    },
    '[name="campaign[target_devices][]"]': {
      observe: 'target_devices',
      afterUpdate($el) {
        $el.trigger('change');
      }
    }
  },

  ui: {
    displayScreens: '.display-screens .check_boxes[data-or-none]',
    displayScreensOptions: '.display-screens input[type=checkbox]',
    targetDevices: '.target-devices .check_boxes[data-or-none]',
    autoLabel: 'label[for=campaign_display_screens_auto]'
  },

  initialize() {
    this.bindUIElements();
    this.stickit();
    this.renderViews();
    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get('target_devices')) ||
        (!isDeepEmpty(this.model.get('display_screens')) &&
          DISPLAY_SCREEN_VALUES.some(
            screen => !this.model.get('display_screens').includes(screen)
          ))
    );
  },

  renderViews() {
    this.ui.autoLabel.tooltip({
      title: I18n.t('campaigns.panels.device.in_car_tooltip')
    });
    checkboxesOrNone(this.ui.displayScreens);
    checkboxesOrNone(this.ui.targetDevices);
    // Rebind to capture 'All' checkboxes
    this.bindUIElements();
  }
});

export default CampaignDeviceTargetingView;
