import BudgetPlansCollection from 'collections/budget_plans';

import TableView from 'budget_plans/views/table';

class App {
  constructor(options) {
    new TableView({
      collection: new BudgetPlansCollection(gon.budget_plans, {
        parse: true
      }),
      el: options.el
    });
  }
}

export default App;
