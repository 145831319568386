var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, available_tags, tag, values_csv) {pug_html = pug_html + "\u003Ctd class=\"col-md-4\"\u003E\u003Cselect class=\"form-control required tag-id\"\u003E\u003Coption\u003E\u003C\u002Foption\u003E";
// iterate available_tags
;(function(){
  var $$obj = available_tags;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var available_tag = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", available_tag.id, true, true)+pug.attr("selected", (available_tag.id == tag), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = available_tag.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var available_tag = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", available_tag.id, true, true)+pug.attr("selected", (available_tag.id == tag), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = available_tag.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Ftd\u003E\u003Ctd class=\"col-md-6\"\u003E\u003Cinput" + (" class=\"form-control input tag-values\""+" type=\"text\""+pug.attr("value", values_csv, true, true)) + "\u003E\u003C\u002Ftd\u003E\u003Ctd class=\"col-md-2 remove-item\"\u003E\u003Ca href=\"#\"\u003E\u003Ci class=\"fa fa-remove\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = ' ' + I18n.t('actions.remove')) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"available_tags" in locals_for_with?locals_for_with.available_tags:typeof available_tags!=="undefined"?available_tags:undefined,"tag" in locals_for_with?locals_for_with.tag:typeof tag!=="undefined"?tag:undefined,"values_csv" in locals_for_with?locals_for_with.values_csv:typeof values_csv!=="undefined"?values_csv:undefined));;return pug_html;};
module.exports = template;