import { toCurrency } from 'helpers';
import { UNSPENT_COLOR } from 'campaigns/views/spent/colors';

import template from 'templates/spent/tooltip.pug';

const helpers = {
  toCurrency: v => toCurrency(v, { precision: 2 }),
  UNSPENT_COLOR
};

export default data => template(Object.assign({}, helpers, data));
