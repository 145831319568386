import DisableIf from 'ui/form/disable_if';
import NeighborhoodTargetingView from 'campaigns/views/targeting/campaign_neighborhood_targeting_view';
import UserInterestsTargetingView from 'campaigns/views/targeting/campaign_user_interests_targeting_view';
import DeviceTargetingView from 'campaigns/views/targeting/campaign_device_targeting_view';
import { DemographicsTargetView } from 'campaigns/views/targeting/campaign_demographics_targeting_view';
import { RouteLengthTargetView } from 'campaigns/views/targeting/campaign_route_length_targeting_view';
import { BrandCategoryPresenceTargetView } from 'campaigns/views/targeting/campaign_brand_category_presence_targeting_view';
import { NavigationHistoryTargetView } from 'campaigns/views/targeting/campaign_navigation_history_targeting_view';
import { BrandDestinationTargetView } from 'campaigns/views/targeting/campaign_brand_destination_targeting_view';
import { OracleSegmentsTargetView } from 'campaigns/views/targeting/campaign_oracle_segments_targeting_view';
import CarrierTargetingView from 'campaigns/views/targeting/campaign_carrier_targeting_view';
import OpeningHoursTargetingView from 'campaigns/views/targeting/campaign_opening_hours_targeting_view';
import ScheduleTargetingView from 'campaigns/views/targeting/campaign_schedule_targeting_view';
import DriveTargetingView from 'campaigns/views/targeting/campaign_drive_targeting_view';
import TrafficTargetingView from 'campaigns/views/targeting/campaign_traffic_targeting_view';
import WeatherTargetingView from 'campaigns/views/targeting/campaign_weather_targeting_view';
import LanguageTargetingView from 'campaigns/views/targeting/campaign_language_targeting_view';
import CustomTargetingView from 'campaigns/views/targeting/campaign_custom_targeting_view';
import collapsePanelCarets from 'ui/form/collapse_panel_carets';
import { OriginDestinationTargetView } from 'campaigns/views/targeting/campaign_origin_destination_targeting_view';
import { ProviderAudiencesTargetView } from 'campaigns/views/targeting/campaign_provider_audiences_targeting_view';

const CampaignTargetingLayout = Marionette.LayoutView.extend({
  template: false,

  ui: {
    neighborhoodPanel: 'div.neighbourhood-panel',
    userInterestsPanel: 'div.interests-panel',
    devicePanel: 'div.device-panel',
    demographicsPanel: 'div.demographics-panel',
    oracleSegmentsPanel: 'div.oracle_segments-panel',
    carrierPanel: 'div.carrier-panel',
    openingHoursPanel: 'div.opening-panel',
    schedulePanel: 'div.schedule-panel',
    drivePanel: 'div.drive-panel',
    trafficPanel: 'div.traffic-panel',
    weatherPanel: 'div.weather-panel',
    languagePanel: 'div.language-panel',
    routeLengthPanel: 'div.route_length-panel',
    navigationHistoryPanel: 'div.navigation_history-panel',
    brandDestinationPanel: 'div.brand_destination-panel',
    brandCategoryPresencePanel: 'div.brand_category_presence-panel',
    originDestinationPanel: 'div.origin_destination-panel',
    providerAudiencesPanel: 'div.audiences-panel',
    customTarget: '.campaign_custom_target',
    collapseGroups: '.collapse-group'
  },

  initialize() {
    this.bindUIElements();
    this.initializeViews();
    this.addCollapseCaretsEventListener();
  },

  initializeViews() {
    if (this.ui.neighborhoodPanel.length > 0) {
      new NeighborhoodTargetingView({
        el: this.ui.neighborhoodPanel,
        model: this.model
      });
    }

    if (this.ui.userInterestsPanel.length > 0) {
      new UserInterestsTargetingView({
        el: this.ui.userInterestsPanel,
        model: this.model
      });
    }

    if (this.ui.devicePanel.length > 0) {
      new DeviceTargetingView({
        el: this.ui.devicePanel,
        model: this.model
      });
    }

    if (this.ui.demographicsPanel.length > 0) {
      new DemographicsTargetView({
        el: this.ui.demographicsPanel,
        model: this.model
      });
    }

    if (this.ui.oracleSegmentsPanel.length > 0) {
      new OracleSegmentsTargetView({
        el: this.ui.oracleSegmentsPanel,
        model: this.model
      });

      DisableIf(this.ui.oracleSegmentsPanel, 'hide-if', {
        field: 'channel',
        value: ['ADS_LINE_SEARCH_INFO']
      });
    }

    if (this.ui.carrierPanel.length > 0) {
      new CarrierTargetingView({
        el: this.ui.carrierPanel,
        model: this.model
      });
    }

    if (this.ui.openingHoursPanel.length > 0) {
      new OpeningHoursTargetingView({
        el: this.ui.openingHoursPanel,
        model: this.model
      });
    }

    if (this.ui.schedulePanel.length > 0) {
      new ScheduleTargetingView({
        el: this.ui.schedulePanel,
        model: this.model,
        defaultAlwaysTargeting: true
      });
    }

    if (this.ui.drivePanel.length > 0) {
      new DriveTargetingView({
        el: this.ui.drivePanel,
        model: this.model
      });

      DisableIf(this.ui.drivePanel, 'hide-if', {
        field: 'channel',
        value: 'ADS_LINE_SEARCH_INFO'
      });
    }

    if (this.ui.trafficPanel.length > 0) {
      new TrafficTargetingView({
        el: this.ui.trafficPanel,
        model: this.model
      });
    }

    if (this.ui.weatherPanel.length > 0) {
      new WeatherTargetingView({
        el: this.ui.weatherPanel,
        model: this.model
      });

      DisableIf(this.ui.weatherPanel, 'hide-if', {
        field: 'channel',
        value: 'ADS_LINE_SEARCH_INFO'
      });
    }

    if (this.ui.languagePanel.length > 0) {
      new LanguageTargetingView({
        el: this.ui.languagePanel,
        model: this.model
      });
    }

    if (this.ui.routeLengthPanel.length > 0) {
      new RouteLengthTargetView({
        el: this.ui.routeLengthPanel,
        model: this.model,
        attributes: {
          attributeName: 'route_length_target'
        }
      });
    }

    if (this.ui.navigationHistoryPanel.length > 0) {
      new NavigationHistoryTargetView({
        el: this.ui.navigationHistoryPanel,
        model: this.model
      });
    }

    if (this.ui.brandDestinationPanel.length > 0) {
      new BrandDestinationTargetView({
        el: this.ui.brandDestinationPanel,
        model: this.model
      });
    }

    if (this.ui.brandCategoryPresencePanel.length > 0) {
      new BrandCategoryPresenceTargetView({
        el: this.ui.brandCategoryPresencePanel,
        model: this.model
      });
    }

    if (this.ui.originDestinationPanel.length > 0) {
      new OriginDestinationTargetView({
        el: this.ui.originDestinationPanel,
        model: this.model
      });
    }

    if (this.ui.providerAudiencesPanel.length > 0) {
      new ProviderAudiencesTargetView({
        el: this.ui.providerAudiencesPanel,
        model: this.model
      });
    }

    if (this.ui.customTarget.length > 0) {
      new CustomTargetingView({
        el: this.ui.customTarget,
        model: this.model
      });
    }
  },

  addCollapseCaretsEventListener() {
    collapsePanelCarets(this.ui.collapseGroups);
  }
});

export default CampaignTargetingLayout;
