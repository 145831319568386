import FlightsGrid from './flights_grid';
import { toCurrency } from '../../helpers';
import template from '../../templates/campaigns/flights.pug';
import { BUDGET_TO_HUNDREDTHS } from '../../models/flight';

class Flights extends Marionette.LayoutView {
  static initClass() {
    this.prototype.template = template;
    this.prototype.className = 'flights';

    this.prototype.regions = { gridRegion: '.flights-grid' };

    this.prototype.ui = { validation: '.flights-validation' };
  }

  initialize(options) {
    this.bindUIElements();

    this.budgetPlan = this.options.budget_plan;

    this._grid = new FlightsGrid({
      collection: this.collection,
      className: 'table table-hover',
      isFormEditable: this.options.isFormEditable,
      wasLive: this.budgetPlan.get('was_live'),
      earliestTimezoneOffsetMs: this.options.earliestTimezoneOffsetMs,
      latestTimezoneOffsetMs: this.options.latestTimezoneOffsetMs
    });
    this._grid.sort('start_date', 'ascending');

    this.listenTo(
      this.budgetPlan,
      'change:budget_type',
      this._updateFlightsGridColumns
    );
    this.listenTo(this.collection, 'validated', this._displayValidation);
    this.listenTo(this.collection, 'change:start_date', () =>
      // Sorting on change triggers backgrid bug https://git.io/vQ0Xo
      this.collection.once('backgrid:edited', () =>
        this._grid.sort('start_date', 'ascending')
      )
    );
    this.listenTo(this.collection, 'update', () => this._grid.render());

    this._bindValidation();
    this.render();
  }

  attachElContent(html) {
    if (!this.$content) {
      this.$content = $('<div>').addClass('flights').appendTo(this.$el);
    }
    this.$content.html(html);
    return this;
  }

  onRender() {
    this._displayValidation();
    this._updateFlightsGridColumns();
    this.gridRegion.show(this._grid);
  }

  _displayValidation() {
    this.$el.toggleClass('has-error', !!this.collection.validationError);
    if (this.collection.validationError) {
      this.ui.validation.show().text(
        I18n.t(this.collection.validationError, {
          scope: 'simple_form.flights.errors',
          budget_difference: toCurrency(
            (this.collection.getUnallocatedBudgetHundredths() * 1.0) /
              BUDGET_TO_HUNDREDTHS,
            {
              precision: 2
            }
          )
        })
      );
    } else {
      this.ui.validation.hide();
    }
  }

  _bindValidation() {
    this.$el.closest('form').on('submit', e => {
      if (this.collection.validationError) {
        this.$el.get(0).scrollIntoView();
        this.ui.validation.removeClass('shake');
        this.ui.validation.offset(); // trigger reflow
        this.ui.validation.addClass('shake');

        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
  }

  _updateFlightsGridColumns() {
    this._grid.toggleSpendableColumns(!this.budgetPlan.isSearch());
  }
}
Flights.initClass();

export default Flights;
