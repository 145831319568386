import _ from 'underscore';
import moment from 'moment';
import daterangepicker from 'bootstrap-daterangepicker';
import { monthLabel } from 'helpers';

export default function (elements, options = {}) {
  _.defaults(options, { alwaysShowCalendars: true });

  const fieldFormat = 'YYYY-MM-DD';
  const displayFormat = I18n.t('time.formats.momentjs');
  const labels = I18n.t('providers.reports_filter');

  return $(elements).each(function () {
    const $from = $(':input[data-daterange=from]', this);
    const $to = $(':input[data-daterange=to]', this);

    const today = moment();
    let from = moment($from.val(), fieldFormat);
    let to = moment($to.val(), fieldFormat);

    // Hide real input fields
    $('.daterange_wrap', this).hide();

    // Create picker button
    const $picker = $('<div/>').addClass('btn btn-default daterange_picker');
    $('<i/>').addClass('fa fa-calendar fa-lg icon').prependTo($picker);
    const $span = $('<span/>').appendTo($picker);
    $('<b/>').addClass('caret').appendTo($picker);
    $(this).append($picker);

    const setPickerLabel = function (fromD, toD) {
      from = moment(fromD).format(displayFormat);
      to = moment(toD).format(displayFormat);
      $span.text(`${from} - ${to}`);
    };

    // prepare preset ranges
    const ranges = {};
    ranges[labels.presets.last7] = [moment(today).subtract(6, 'days'), today];
    ranges[labels.presets.last_month] = [
      moment(today).subtract(1, 'month'),
      today
    ];
    ranges[monthLabel(today)] = [
      moment(today).startOf('month'),
      moment(today).endOf('month')
    ];
    const prevMonth = moment(today).subtract(1, 'month');
    ranges[monthLabel(prevMonth)] = [
      moment(prevMonth).startOf('month'),
      moment(prevMonth).endOf('month')
    ];

    options = {
      ranges,
      format: displayFormat,
      opens: $('body').css('direction') === 'rtl' ? 'left' : 'right',
      startDate: from,
      endDate: to,
      maxDate: today,
      applyClass: 'btn-primary',
      locale: {
        applyLabel: labels.apply,
        cancelLabel: labels.cancel,
        fromLabel: labels.from,
        toLabel: labels.to,
        customRangeLabel: labels.custom,
        daysOfWeek: _.values(I18n.t('date.abbr_day_names')),
        monthNames: _.filter(_.compact(I18n.t('date.month_names')), monthName =>
          _.isString(monthName)
        ),
        firstDay: gon.config.week_start
      }
    };

    const picker = new daterangepicker($picker, options, (start, end) => {
      const m_start = moment(start);
      const m_end = moment(end);
      $from.val(m_start.format(fieldFormat)).trigger('change');
      $to.val(m_end.format(fieldFormat)).trigger('change');

      setPickerLabel(m_start, m_end);
    });

    if (options.alwaysShowCalendars) {
      $picker.on('show.daterangepicker', () => {
        picker.container.find('.calendar').show();
      });
    }

    setPickerLabel(from, to);
  });
}
