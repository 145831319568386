export default function (element: JQuery) {
  $(element).each(function () {
    const $el = $(this);

    const hidden_input = $('<input>')
      .attr('type', 'hidden')
      .attr('name', $el.attr('name') || '')
      .insertAfter($el);

    $el.autoNumeric('init', JSON.parse($el.attr('data-autonumeric') || ''));

    const setHiddenValue = () => hidden_input.val(+$el.autoNumeric('get'));

    setHiddenValue();

    $el.on('keyup blur', setHiddenValue);
  });
}
