import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule, DevAppModule } from './app.module';

// Disable drag and drop events for the entire application.
// This prevents Chrome and other browsers from loading dropped assets natively.
// Context-specific DnD functionality is still enabled within the app.
window.addEventListener('dragover', event => event.preventDefault(), false);
window.addEventListener('drop', event => event.preventDefault(), false);

if (process.env.NODE_ENV === 'production') {
  enableProdMode();
  platformBrowserDynamic().bootstrapModule(AppModule, {
    preserveWhitespaces: false
  });
} else {
  platformBrowserDynamic().bootstrapModule(DevAppModule);
}
