import _ from 'underscore';
import '../../vendor/bootstrap-datepicker';

const DATE_FORMAT = 'yyyy-mm-dd';

export { DATE_FORMAT };

export default function (elements: JQuery) {
  $(elements).each(function () {
    const options = _.extend($(this).data('date') || {}, {
      autoclose: true,
      format: DATE_FORMAT,
      language: I18n.locale,
      todayHighlight: true,
      weekStart: gon.config.week_start
    });

    const $group = $(this).is('.input-group')
      ? $(this)
      : $(this).find('.input-group');

    $group.each(function () {
      $(this).addClass('date').datepicker(options);
    });
  });
}
