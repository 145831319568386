import _ from 'underscore';
import ScheduleInput from 'views/schedule/input';
import { togglePanelCollapse } from 'helpers/views';
import { isDeepEmpty } from 'helpers/utils';

const CampaignScheduleTargetingView = Marionette.ItemView.extend({
  template: false,

  ui: {
    scheduleForm: '.campaign_show_schedule'
  },

  ALWAYS_TARGETING: [
    { days: [0, 1, 2, 3, 4, 5, 6], from_hour: '00:00', to_hour: '24:00' }
  ],

  initialize(options) {
    this.bindUIElements();
    this.renderViews();
    togglePanelCollapse(
      this.$el,
      options.defaultAlwaysTargeting
        ? !_.isEqual(this.model.get('show_schedule'), this.ALWAYS_TARGETING)
        : !isDeepEmpty(this.model.get('show_schedule'))
    );
  },

  renderViews() {
    const scheduleInputView = new ScheduleInput({
      el: this.ui.scheduleForm,
      model: new Backbone.Model({
        schedule: this.model.get('show_schedule')
      }),
      weekStart: this.ui.scheduleForm.data('week-start')
    });

    this.listenTo(scheduleInputView, 'change', this.onScheduleInputChange);
  },

  onScheduleInputChange(scheduleArray) {
    this.model.set('show_schedule', scheduleArray);
  }
});

export default CampaignScheduleTargetingView;
