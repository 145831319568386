import 'backbone.stickit';
import moment from 'moment';
import { togglePanelCollapse } from '../../../helpers/views';
import Campaign, {
  ProviderAudiencesTargetAttributes
} from '../../../models/campaign';
import DisableIf from '../../../ui/form/disable_if';
import '../../../views/backgrid/button_cell';
import '../../../views/backgrid/html_cell';
import RadioToggleButtons from '../../../views/radio_toggle_buttons';

interface ProviderAudience {
  id: number;
  provider_id: number;
  audience_id: number;
  audience_name: string;
  total_audience_id_count: number;
  total_waze_id_count: number;
  ingested_id_count: number;
  valid_until_ts: number;
}

interface Select2Option {
  id: string | number;
  text: string;
  audience?: ProviderAudience;
}

const ALL_ID = 'all';

export class ProviderAudiencesTargetView extends Marionette.ItemView<Campaign> {
  public stickit!: Function;

  private gridCollection = new Backbone.Collection();
  private audiences = new Map<number, ProviderAudience>();

  static initClass() {
    this.prototype.ui = {
      alert: '.alert',
      audiencesList: '.select-list',
      audiencesGrid: '.audiences-grid .controls',
      audiencesGridContainer: '.audiences-grid',
      addButton: '[data-1p-add-list]',
      clearAllHeader: 'th.remove',
      targetingTypeContainer: '.targeting-type',
      targetingTypeRadio: '.targeting-type .radio_buttons'
    };

    this.prototype.modelEvents = {
      'change:provider_audiences_target': 'updateGridCollection'
    };
  }

  bindings() {
    return {
      '[name="campaign[provider_audiences_target_attributes][audienceIds]"]': {
        observe: 'provider_audiences_target',
        onGet: (value: ProviderAudiencesTargetAttributes) => {
          return value?.audienceIds.join(',') || '';
        }
      }
    };
  }

  events() {
    return {
      'click @ui.addButton': 'addToGroup',
      'click @ui.clearAllHeader': 'clearAll',
      'change @ui.audiencesList': 'selectChanged'
    };
  }

  async initialize() {
    await this.fetchAudiences();

    this.bindUIElements();

    this.stickit();

    this.renderSelect();
    this.renderTargetingType();
    this.renderGrid();

    DisableIf(this.ui.addButton, 'disable-if', {
      field: this.ui.audiencesList,
      value: ''
    });

    togglePanelCollapse(this.$el, this.model.has1pAudiences());
  }

  addToGroup() {
    const selectionValues: string[] = this.ui.audiencesList.select2('val');
    const values = selectionValues.map(value => parseInt(value, 10));

    const targeting: ProviderAudiencesTargetAttributes =
      this.model.get('provider_audiences_target') || {};
    const audienceIds = targeting?.audienceIds || [];

    this.model.set('provider_audiences_target', {
      ...targeting,
      audienceIds: [
        ...values.filter(value => !audienceIds.includes(value)),
        ...audienceIds
      ]
    });

    this.ui.audiencesList.select2('val', '').trigger('change');
  }

  removeFromGroup(row: Backbone.Model) {
    const idToRemove = row.get('audienceId');

    const targeting: ProviderAudiencesTargetAttributes =
      this.model.get('provider_audiences_target') || {};
    const audienceIds = targeting?.audienceIds || [];

    this.model.set('provider_audiences_target', {
      ...targeting,
      audienceIds: audienceIds.filter(id => id !== idToRemove)
    });
  }

  clearAll() {
    this.model.set('provider_audiences_target', {
      audienceIds: []
    });
  }

  selectChanged() {
    const selection: string[] = this.ui.audiencesList.select2('val');

    if (selection.includes(ALL_ID)) {
      // Get every fetched audience id
      const values = Array.from(this.audiences.values()).map(
        value => value.audience_id
      );

      // Set select value to every audience id. Removes "all" value.
      this.ui.audiencesList.select2('val', values);
      this.ui.audiencesList.select2('close');
    }
  }

  private async fetchAudiences() {
    const providerId = this.model.get('provider_id');
    const list: ProviderAudience[] = await $.get(
      `/api/providers/${providerId}/audiences`
    ).promise();

    this.audiences.clear();
    list.forEach(audience => {
      this.audiences.set(audience.audience_id, audience);
    });
  }

  private renderTargetingType() {
    // tslint:disable-next-line: no-unused-expression
    new RadioToggleButtons({ el: this.ui.targetingTypeRadio });
  }

  private renderSelect() {
    const audiences = Array.from(this.audiences.values());

    this.ui.audiencesList.select2({
      placeholder: I18n.t('campaigns.panels.audiences.select.placeholder'),
      multiple: true,
      closeOnSelect: false,
      dropdownCssClass: 'audience-select',
      data: [
        { id: ALL_ID, text: I18n.t('campaigns.panels.audiences.select.all') },
        ...audiences.map(audience => ({
          id: audience.audience_id,
          text: audience.audience_name,
          audience
        }))
      ],
      formatResult: this.formatSelectOption.bind(this),
      escapeMarkup: (markup: string) => markup
    });
  }

  private formatSelectOption(option: Select2Option) {
    if (ALL_ID === option.id) {
      return option.text;
    }

    const audience = option.audience!;

    let hint = '';
    if (!this.hasEnoughWazers(audience)) {
      hint = `
        <span class="warning">
          <i class="fa fa-sm fa-warning" />
          ${I18n.t('campaigns.panels.audiences.select.low_match_rate')}
        </span>
      `;
    } else if (this.isExpired(audience)) {
      hint = `
        <span class="warning">
          <i class="fa fa-sm fa-warning" />
          ${I18n.t('campaigns.panels.audiences.select.expired')}
        </span>
      `;
    } else {
      hint = I18n.t('campaigns.panels.audiences.select.match_rate', {
        rate: this.getMatchRate(audience)
      });
    }

    return `
      <div class="audience-select-option">
        <div class="name">${audience.audience_name}</div>
        <div class="hint">${hint}</div>
      </div>
    `;
  }

  private renderGrid() {
    if (this.ui.audiencesGrid.length === 0) {
      return;
    }

    const grid = new Backgrid.Grid({
      // tslint:disable-next-line: no-any
      columns: this.columns() as any[],
      collection: this.gridCollection
    });

    this.updateGridCollection();

    this.ui.audiencesGrid.append(grid.render().el);
  }

  private updateGridCollection() {
    const targeting: ProviderAudiencesTargetAttributes = this.model.get(
      'provider_audiences_target'
    );

    const audiences = targeting?.audienceIds
      .filter(id => this.audiences.has(id))
      .map(id => this.audiences.get(id)!);

    this.gridCollection.reset();
    if (audiences != null) {
      this.gridCollection.add(audiences?.map(this.createRow.bind(this)));
    }

    if (this.model.has1pAudiences()) {
      this.showAudiences();
    } else {
      this.hideAudiences();
    }
  }

  private createRow(audience: ProviderAudience) {
    return {
      audience,
      audienceId: audience.audience_id,
      name: audience.audience_name,
      matchRate: this.getMatchRate(audience),
      availableUsers: this.getAvailableUsers(audience),
      expirationDate: new Date(audience.valid_until_ts)
    };
  }

  private getMatchRate(audience: ProviderAudience) {
    const { ingested_id_count, total_audience_id_count } = audience;

    if (this.hasEnoughWazers(audience) && total_audience_id_count > 0) {
      const pct = (ingested_id_count / total_audience_id_count) * 100;
      return `${pct.toFixed(0)}%`;
    }

    return I18n.t('campaigns.panels.audiences.table.not_available');
  }

  private getAvailableUsers(audience: ProviderAudience) {
    const { ingested_id_count } = audience;

    return this.hasEnoughWazers(audience)
      ? `${ingested_id_count.toLocaleString()}`
      : '< 1,000';
  }

  private hasEnoughWazers(audience: ProviderAudience) {
    // AdManage will return 0 if ingested count is lower than the threshold.
    return audience.ingested_id_count > 0;
  }

  private isExpired(audience: ProviderAudience) {
    return audience.valid_until_ts < new Date().getTime();
  }

  private showAudiences() {
    this.ui.alert.hide();
    this.ui.audiencesGridContainer.show();
    this.ui.targetingTypeContainer.show();
  }

  private hideAudiences() {
    this.ui.alert.show();
    this.ui.audiencesGridContainer.hide();
    this.ui.targetingTypeContainer.hide();
  }

  private columns() {
    // Note: making this a property on the class doesn't work.
    // Most likely due to backbone not playing well with classes.
    return [
      {
        name: 'audienceId',
        label: I18n.t('campaigns.panels.audiences.table.id'),
        cell: Backgrid.IntegerCell.extend({
          orderSeparator: ''
        }),
        editable: false,
        sortable: false
      },
      {
        name: 'name',
        label: I18n.t('campaigns.panels.audiences.table.name'),
        cell: 'string',
        editable: false,
        sortable: false
      },
      {
        name: 'matchRate',
        label: I18n.t('campaigns.panels.audiences.table.match_rate'),
        cell: 'string',
        editable: false,
        sortable: false
      },
      {
        name: 'availableUsers',
        label: I18n.t('campaigns.panels.audiences.table.available_users'),
        cell: 'html',
        editable: false,
        sortable: false,
        formatter: {
          fromRaw: this.formatAvailableUsers.bind(this)
        }
      },
      {
        name: 'expirationDate',
        label: I18n.t('campaigns.panels.audiences.table.expiration_date'),
        cell: 'html',
        editable: false,
        sortable: false,
        formatter: {
          fromRaw: this.formatExpirationDate.bind(this)
        }
      },
      {
        name: 'remove',
        label: I18n.t('campaigns.panels.audiences.table.clear_all'),
        cell: 'button',
        editable: false,
        sortable: false,
        content: '<i class="fa fa-lg fa-times" />',
        action: this.removeFromGroup.bind(this)
      }
    ];
  }

  private formatAvailableUsers(value: string, model: Backbone.Model) {
    const audience: ProviderAudience = model.get('audience');
    const warning = this.hasEnoughWazers(audience)
      ? ''
      : `
        <div class="warning"><i class="fa fa-sm fa-warning" />
          ${I18n.t('campaigns.panels.audiences.table.warnings.low_match_rate')}
        </div>
        `;

    return warning || value;
  }

  private formatExpirationDate(value: Date, model: Backbone.Model) {
    const audience: ProviderAudience = model.get('audience');
    const warning = this.isExpired(audience)
      ? `
        <div class="warning"><i class="fa fa-sm fa-warning" />
          ${I18n.t('campaigns.panels.audiences.table.warnings.expired')}
        </div>
        `
      : '';

    return warning || moment(value).format(I18n.t('time.formats.momentjs'));
  }
}

ProviderAudiencesTargetView.initClass();
