var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, operation, operations) {pug_html = pug_html + "\u003Cselect class=\"form-control\" name=\"operation\"\u003E";
// iterate operations
;(function(){
  var $$obj = operations;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var key = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", key, true, true)+pug.attr("selected", operation==key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t(key, { scope: 'reports.advanced.filters.operations' })) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var key = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", key, true, true)+pug.attr("selected", operation==key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t(key, { scope: 'reports.advanced.filters.operations' })) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"operation" in locals_for_with?locals_for_with.operation:typeof operation!=="undefined"?operation:undefined,"operations" in locals_for_with?locals_for_with.operations:typeof operations!=="undefined"?operations:undefined));;return pug_html;};
module.exports = template;