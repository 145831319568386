import { togglePanelCollapse } from 'helpers/views';
import { isDeepEmpty } from 'helpers/utils';
const CampaignLanguageTargetingView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '#campaign_targeted_languages': 'targeted_languages'
  },

  ui: {
    languages: '#campaign_targeted_languages'
  },

  initialize() {
    this.bindUIElements();
    this.renderViews();
    this.stickit();
    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get('targeted_languages'))
    );
  },

  renderViews() {
    this.ui.languages.addClass('select2-stickit').select2();
  }
});

export default CampaignLanguageTargetingView;
