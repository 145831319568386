import _ from 'underscore';
import { togglePanelCollapse } from 'helpers/views';
import { isDeepEmpty } from 'helpers/utils';
const CampaignUserInterestsTargetingView = Marionette.ItemView.extend({
  template: false,

  bindings: {
    '#campaign_user_interests': {
      observe: 'user_interests',
      getVal($el, event, options) {
        let val = $el.select2('val');
        if (!_.isArray(val)) {
          val = [val];
        }
        return _.compact(val);
      }
    }
  },

  ui: {
    userInterests: '#campaign_user_interests'
  },

  initialize() {
    this.bindUIElements();
    this.renderViews();
    this.stickit();
    togglePanelCollapse(
      this.$el,
      !isDeepEmpty(this.model.get('user_interests'))
    );
  },

  renderViews() {
    const selectedInterests = this.model.get('user_interests') || [];
    this.ui.userInterests.addClass('select2-stickit').select2({
      data: this.ui.userInterests.data('interests').map(interest => ({
        id: interest.id,
        text: interest.name
      })),
      multiple: selectedInterests.length > 1,
      placeholder: ' ',
      allowClear: true
    });
  }
});

export default CampaignUserInterestsTargetingView;
