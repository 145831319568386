var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, field, queryFields) {pug_html = pug_html + "\u003Cspan class=\"field-container\"\u003E\u003Cselect class=\"form-control\" name=\"field\"\u003E\u003Coption" + (" value=\"\""+pug.attr("selected", !field, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.advanced.filters.field')) ? "" : pug_interp)) + "\u003C\u002Foption\u003E\u003Coption value=\"\" disabled\u003E" + (pug.escape(null == (pug_interp = '──────') ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
// iterate queryFields(field)
;(function(){
  var $$obj = queryFields(field);
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var queryField = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", queryField[0], true, true)+pug.attr("selected", field==queryField[0], true, true)) + "\u003E" + (pug.escape(null == (pug_interp = queryField[1]) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var queryField = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", queryField[0], true, true)+pug.attr("selected", field==queryField[0], true, true)) + "\u003E" + (pug.escape(null == (pug_interp = queryField[1]) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"operations-container\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"values-container\"\u003E\u003C\u002Fspan\u003E\u003Ca" + (" class=\"remove-item\""+" href=\"#\""+pug.attr("title", I18n.t('reports.advanced.filters.remove'), true, true)) + "\u003E\u003Ci class=\"fa fa-remove\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"sr-only\"\u003E" + (pug.escape(null == (pug_interp = I18n.t('reports.advanced.filters.remove')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"field" in locals_for_with?locals_for_with.field:typeof field!=="undefined"?field:undefined,"queryFields" in locals_for_with?locals_for_with.queryFields:typeof queryFields!=="undefined"?queryFields:undefined));;return pug_html;};
module.exports = template;