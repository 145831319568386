import moment from 'moment';
import Flight from '../../../models/flight';
import { getColor } from './colors';

const getNormalizedFlights = budgetPlan => {
  if (budgetPlan.hasFlights()) {
    return budgetPlan.get('flights');
  } else {
    return [toFakeFlight(budgetPlan)];
  }
};

const toFakeFlight = budgetPlan =>
  new Flight({
    name: budgetPlan.get('name'),
    budget: budgetPlan.get('budget'),
    spent: budgetPlan.get('spent'),
    start_date: moment.utc(budgetPlan.get('start_date')).format('x'),
    end_date: moment.utc(budgetPlan.get('end_date')).format('x'),
    actual_spent_per_campaign: budgetPlan.get('campaigns').reduce((acc, c) => {
      acc[c.id] = c.spent;
      return acc;
    }, {})
  });

const unspent = (budgetPlan, flight) => {
  if (flight.isEndDateInPast()) {
    return null;
  }

  const budget = +flight.get('budget');
  const effectiveBudget = budget + overflow(budgetPlan, flight);

  const spent = flight.get('spent') / 100;
  const leftover = effectiveBudget - spent;

  return Math.max(0, leftover);
};

const overflow = (budgetPlan, flight) => {
  const hasOverflow = budgetPlan.hasOverflow() && flight.isCurrent();
  return hasOverflow ? flight.get('overflow') / 100 : null;
};

const campaigns = (budgetPlan, flight, activeCampaignId) => {
  const spentByCampaign = flight.get('actual_spent_per_campaign') || {};
  const flightStarted = flight.isStartDateInPast();

  return budgetPlan.get('campaigns').map((campaign, i) => ({
    id: campaign.id,
    spentInFlight: flightStarted ? spentByCampaign[campaign.id] / 100 : null,
    name: campaign.name,
    color: getColor(i, !activeCampaignId || activeCampaignId === campaign.id)
  }));
};

const convertSingleFlight = (budgetPlan, flight, activeCampaignId) => {
  return {
    name: flight.get('name'),
    spent: flight.get('spent') / 100,
    unspent: unspent(budgetPlan, flight),
    budget: +flight.get('budget'),
    overflow: overflow(budgetPlan, flight),
    campaigns: campaigns(budgetPlan, flight, activeCampaignId),
    flightStarted: flight.isStartDateInPast()
  };
};

export default (budgetPlan, activeCampaignId) =>
  getNormalizedFlights(budgetPlan).map(flight =>
    convertSingleFlight(budgetPlan, flight, activeCampaignId)
  );
