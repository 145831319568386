import _ from 'underscore';
import ScheduleItems from '../../collections/schedule_items';
import Schedule from '../../models/schedule';
import Dialog from './dialog';
import Preview from './preview';

interface ScheduleInputOptions extends Backbone.ViewOptions<Backbone.Model> {
  allowEmpty?: boolean;
  texts?: {
    title?: string;
    intro?: string;
  };
  weekStart?: number;
}

class ScheduleInput extends Marionette.ItemView<Backbone.Model> {
  template!: boolean;
  options!: ScheduleInputOptions;

  preview!: Preview;
  dialog!: Dialog;

  private scheduleItems!: ScheduleItems;

  private weekStart!: number;
  private initialSchedule!: Schedule[];

  constructor(options: ScheduleInputOptions = {}) {
    super(options);
  }

  static initClass() {
    this.prototype.template = false;

    this.prototype.ui = {
      input: '.schedule-input',
      preview: 'ul.schedule-preview-list',
      dialog: '.schedule-dialog-container',
      showButton: '.schedule-button',
      resetButton: '.reset-schedule-button'
    };
  }

  events() {
    return {
      'click @ui.showButton': 'showDialog',
      'click @ui.resetButton': 'resetSchedule'
    };
  }

  initialize(options: ScheduleInputOptions = {}) {
    this.options = options;
    this.options.texts = _.defaults(this.options.texts || {}, {
      title: undefined,
      intro: undefined
    });

    this.weekStart = this.options.weekStart || 0;
    this.initialSchedule = this.model.get('schedule') || [];

    this.render();
  }

  onRender() {
    this.renderShowButton();
    this.initCollection();
    this.renderPreview();
    this.renderDialog();
  }

  renderShowButton() {
    this.ui.showButton.text(
      _.isEmpty(this.model.get('schedule'))
        ? I18n.t('schedule.button.new')
        : I18n.t('schedule.button.edit')
    );
  }

  initCollection() {
    const initialJSON = JSON.stringify(this.initialSchedule);
    const currentJSON = JSON.stringify(this.model.get('schedule'));

    this.toggleResetButton(currentJSON !== initialJSON);

    this.scheduleItems = new ScheduleItems(this.model.get('schedule') || []);

    this.scheduleItems.on('change add remove reset', () => {
      const scheduleItems = this.scheduleItems.toJSON();
      const json = JSON.stringify(scheduleItems);
      this.ui.input.val(json).trigger('change');
      this.trigger('change', scheduleItems);
      this.toggleResetButton(json !== initialJSON);
    });
  }

  renderPreview() {
    this.preview = new Preview({
      collection: this.scheduleItems,
      el: this.ui.preview,
      weekStart: this.weekStart
    });
  }

  renderDialog() {
    this.dialog = new Dialog({
      collection: this.scheduleItems,
      el: this.ui.dialog,
      weekStart: this.weekStart,
      texts: this.options.texts,
      allowEmpty: this.options.allowEmpty
    });
  }

  showDialog() {
    this.dialog.showModal();
  }

  resetSchedule() {
    this.scheduleItems.set(this.initialSchedule);
  }

  private toggleResetButton(enabled: boolean) {
    this.ui.resetButton.prop('disabled', !enabled);
  }
}
ScheduleInput.initClass();

export default ScheduleInput;
