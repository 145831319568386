import _ from 'underscore';
import moment from 'moment';
import template from 'templates/campaign_alerts/actions.pug';

class Actions extends Marionette.ItemView {
  static initClass() {
    this.prototype.template = template;

    this.prototype.ui = {
      snooze: '.snooze',
      snooze_option: '.snooze li',
      dropdown: '.snooze .dropdown-toggle',
      unsnooze: '.unsnooze',
      delete: '.delete'
    };

    this.prototype.events = {
      'click @ui.snooze': '_onSnoozeClick',
      'click @ui.snooze_option': '_onSnoozeOptionClick',
      'click @ui.unsnooze': '_onUnsnoozeClick',
      'click @ui.delete': '_onDeleteClick',
      'shown.bs.dropdown': '_onDropdownShown',
      'hidden.bs.dropdown': '_onDropdownHide'
    };
  }

  templateHelpers() {
    return {
      snoozeOptions: this.snoozeOptions,
      titles: this.titles,
      showSnooze: () => this.model.canSnooze(),
      showUnsnooze: () => this.model.canUnsnooze()
    };
  }

  initialize(options = {}) {
    this.end_date = options.end_date;
    this.snoozeOptions = this._getSnoozeOptions();
    this.titles = options.titles;
  }

  _getSnoozeOptions() {
    const now = moment();
    const end_date = moment(this.end_date);
    const I18n_scope = 'campaigns.form.alerts.snooze_until';

    const options = [
      {
        label: I18n.t('tomorrow', { scope: I18n_scope }),
        time: moment().add(1, 'days')
      },
      {
        label: I18n.t('in_x_days', { days: 7, scope: I18n_scope }),
        time: moment().add(7, 'days')
      },
      {
        label: I18n.t('in_x_days', { days: 30, scope: I18n_scope }),
        time: moment().add(30, 'days')
      },
      {
        label: I18n.t('x_days_before', { days: 7, scope: I18n_scope }),
        time: moment(end_date).subtract(7, 'days')
      },
      {
        label: I18n.t('x_days_before', { days: 30, scope: I18n_scope }),
        time: moment(end_date).subtract(30, 'days')
      }
    ];

    return _.chain(options)
      .filter(option => option.time.isValid() && option.time.isAfter(now))
      .each(option => (option.time = option.time.format('YYYY-MM-DD')))
      .value();
  }

  _onSnoozeClick(e) {
    e.stopPropagation();
    return this.ui.dropdown.dropdown('toggle');
  }

  _onSnoozeOptionClick(e) {
    e.stopPropagation();
    const time = $(e.currentTarget).data('time');
    return this.model.snooze(time);
  }

  _onUnsnoozeClick(e) {
    e.stopPropagation();
    return this.model.unsnooze();
  }

  _onDeleteClick(e) {
    e.stopPropagation();
    if (window.confirm(this.titles.delete_confirm)) {
      return this.model.destroy();
    }
  }

  _onDropdownShown(e) {
    this.dropdown_parent = $(e.target);
    this.dropdown_el = this.dropdown_parent.find('.dropdown-menu');

    const css = _.extend(
      { position: 'fixed', right: 'auto' },
      this.dropdown_el.offset()
    );
    css.top -= $(window).scrollTop();
    this.dropdown_el.css(css);

    this.scroll_listener = $(window).on(
      'scroll',
      this._onDropdownHide.bind(this)
    );
    return this.$el.on('scroll', this._onDropdownHide.bind(this));
  }

  _onDropdownHide() {
    $(window).off('scroll', this.scroll_listener);
    this.$el.off('scroll');
    this.dropdown_parent.removeClass('open');
    return this.dropdown_el.css({ top: '', left: '', right: '', position: '' });
  }
}
Actions.initClass();

export default Actions;
