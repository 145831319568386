import * as moment from 'moment';
import _ from 'underscore';

function nl2br(text: string, defValue: string) {
  if (text) {
    return `${text}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
  } else {
    return defValue;
  }
}

function imageUrl(filename: string, dir: string) {
  if (_.isEmpty(filename)) {
    return;
  }
  if (filename.match(/^https?:\/\//)) {
    return filename;
  }

  let path = `2x/${filename}`;
  if (!_.isEmpty(dir)) {
    path = `${dir}/${path}`;
  }

  return `${gon.config.assets_base_url}${path}.png`;
}

function clientImageUrl(filename: string, path = 'resources/images/1.0') {
  return imageUrl(filename, path);
}

function logoUrl(filename: string) {
  return clientImageUrl(filename, 'client_ads/logos');
}

function scheduleHourOption(hour: number, minutes: number) {
  const hh = hour < 10 ? `0${hour}` : hour;
  const mm = minutes < 10 ? `0${minutes}` : minutes;
  return `${hh}:${mm}`;
}

function toCurrency(value: number, options = {}) {
  return I18n.toCurrency(
    value,
    Object.assign(
      { unit: I18n.t(`currency.price.${gon.config.currency}.sign`) },
      options
    )
  );
}

function monthLabel(date: moment.Moment) {
  return `${I18n.t('date.month_names')[date.month() + 1]} ${date.year()}`;
}

export {
  nl2br,
  imageUrl,
  clientImageUrl,
  logoUrl,
  scheduleHourOption,
  toCurrency,
  monthLabel
};
