import 'backbone.stickit';
import 'bootstrap-sass';
import 'Select2';
import moment from 'moment';
import FlightsView from './flights';
import Routes from '../../helpers/routes';
import RadioToggleButtons from '../../views/radio_toggle_buttons';
import autonumeric from '../../ui/form/autonumeric';
import datePicker from '../../ui/form/date_picker';
import {
  BUDGET_TYPES,
  FIRST_LOOK_EDITABLE_FIELDS,
  INTENT_BUDGET_TYPES,
  SEARCH_BUDGET_TYPES,
  SEARCH_EDITABLE_FIELDS,
  STANDARD_BUDGET_TYPES
} from '../../models/budget_plan';

const DATE_FORMAT = 'YYYY-MM-DD';
const EVEN_DISTRIBUTION_MODE = 'even';

class Form extends Marionette.LayoutView {
  static initClass() {
    this.prototype.template = false;

    this.prototype.ui = {
      inputs: ':input:not(:disabled)',
      flights: '.flights-wrapper',
      flights_controls: '.flights-wrapper .controls',
      flights_enabler: '.enable-flights',
      flights_enabler_wrapper: '.enable-flights-wrapper',
      flights_input: '[name$="[flights]"]',
      flights_overflow: '.enable-flights-overflow',
      radio_toggles: '.radio_toggle',
      non_search: '.campaign_spread',
      autonumeric: 'input[data-autonumeric]',
      date_pickers: '.form-group.date_picker',
      split: '.split',
      monthly_flights: '.monthly-flights',
      budget_inputs_wrapper: '.budget_inputs_wrapper',
      campaign_budget_type_wrapper: '.campaign_budget_plan_budget_type',
      flights_overflow_hidden_alert: '.fixed-overflow-alert',
      distribution_mode: '[name$="[distribution_mode]"]',
      distribution_warning: '.distribution-warning',
      custom_spread: '.campaign_spread [value=CUSTOM]',
      io_id: '[name$="[io_id]"]',
      pacing_chart_link: '.pacing-chart-link'
    };

    this.prototype.modelEvents = {
      change: '_toggleFlightsEnabler',
      'change:flights': '_toggleFlights',
      'change:budget_type': '_onBudgetTypeChange'
    };

    this.prototype.events = {
      'click @ui.flights_enabler': '_addFlights',
      'click @ui.split': '_splitBudget',
      'click @ui.monthly_flights': '_generateMonthlyFlights',
      'change @ui.distribution_mode': '_onDistributionModeChange'
    };
  }

  bindings() {
    const disableUnlessEditable = field => [
      {
        name: 'disabled',
        observe: ['budget_type', 'first_look', 'id'],
        onGet: () => !(this._isFormEditable() && this._isFieldEditable(field))
      }
    ];

    return {
      '[name$="[spread_type]"]:not(:disabled)': {
        observe: 'spread_type',
        attributes: disableUnlessEditable('spread_type')
      },
      '[name$="[start_date]"]:not(:disabled)': {
        observe: 'start_date',
        onGet: '_getDate',
        onSet: '_setDate',
        update: ($el, value) => $el.val(value),
        attributes: disableUnlessEditable('start_date')
      },
      '[name$="[end_date]"]:not(:disabled)': {
        observe: 'end_date',
        onGet: '_getDate',
        onSet: '_setDate',
        attributes: disableUnlessEditable('end_date')
      },
      '[name$="[budget_type]"]:not(:disabled)': {
        observe: 'budget_type',
        attributes: disableUnlessEditable('budget_type')
      },
      '[name$="[budget]"]:not(:disabled)': {
        observe: 'budget',
        attributes: disableUnlessEditable('budget')
      },
      '[name$="[flights]"]:not(:disabled)': {
        observe: 'flights',
        onGet(value) {
          return JSON.stringify(value.toJSON());
        },
        updateModel: false,
        attributes: disableUnlessEditable('flights')
      },
      '[name$="[io_budget]"]': 'io_budget',
      '[name$="[io_id]"]:not(:disabled)': {
        observe: 'io_id',
        attributes: disableUnlessEditable('io_id')
      },
      '[name$="[flights_overflow]"]:not(:disabled)': {
        observe: 'flights_overflow',
        attributes: disableUnlessEditable('flights_overflow')
      },
      '[name$="[customer_line_item_name]"]': {
        observe: 'customer_line_item_name',
        attributes: disableUnlessEditable('customer_line_item_name')
      }
    };
  }

  _getDate(value, binding) {
    const momentValue = moment.utc(value);
    if (momentValue.isValid()) {
      return momentValue.format(DATE_FORMAT);
    }
  }

  _setDate(value, binding) {
    const momentValue = moment.utc(value, DATE_FORMAT);
    if (momentValue.isValid()) {
      return momentValue.format();
    }
  }

  initialize(options = {}) {
    this.bindUIElements();

    autonumeric(this.ui.autonumeric);
    datePicker(this.ui.date_pickers);

    this.stickit();

    this._toggleFlights();
    this._toggleFlightsEnabler();
    this._toggleOverflowEnabler();
    this._toggleSearchFields();

    // Only needs to be called on initialization, since the pacing chart link
    // only exists if the campaign was live and the campaign ad unit is
    // immutable after creation.
    this._togglePacingChartLink();

    this.ui.radio_toggles.each((i, el) => {
      new RadioToggleButtons({ el, model: this.model });
    });

    this.ui.budget_inputs_wrapper.toggleClass(
      'control-2cols',
      this.ui.budget_inputs_wrapper.children().length > 1
    );

    if (this.ui.flights.length > 0) {
      new FlightsView({
        budget_plan: this.model,
        collection: this.model.get('flights'),
        el: this.ui.flights_controls,
        isFormEditable: this._areFlightsEditable.bind(this),
        earliestTimezoneOffsetMs: options.earliestTimezoneOffsetMs,
        latestTimezoneOffsetMs: options.latestTimezoneOffsetMs
      });
    }

    this.listenTo(this.model.get('flights'), 'update', this._onFlightsUpdate);

    this.listenTo(this.options.campaign, 'change:channel', () => {
      this.model.updateChannelBasedFields(this.options.campaign);
      this._toggleSearchFields();
    });

    this.ui.flights_overflow.find('.controls').popover({
      content: I18n.t('simple_form.flights.flights_overflow_explain'),
      placement: 'bottom',
      trigger: 'hover focus',
      container: 'body'
    });

    this.ui.io_id.select2({
      minimumInputLength: 1,
      ajax: {
        url: Routes.api_invoice_orders_search_path(),
        dataType: 'json',
        quietMillis: 100,
        data: term => ({ query: term, limit: 5 }),
        results: ios => {
          return {
            more: false,
            results: ios.map(io => {
              return {
                id: io.id,
                text: String(io.id),
                customerName: io.customer_name,
                startDate: io.start_date,
                endDate: io.end_date
              };
            })
          };
        }
      },
      initSelection: ($el, callback) => {
        const value = $el.val();
        callback({ id: value, text: String(value) });
      },
      createSearchChoice: term => {
        // Add option for no IO ID when searching for 0.
        if (term === '0') {
          return { id: 0, text: '0' };
        }
      },
      formatResult: result => {
        // Special case for option for no IO ID.
        if (result.id === 0) {
          return '0';
        }

        const customerName = result.customerName || '';

        const dateFormat = I18n.t('time.formats.momentjs');
        const startDate = moment.utc(result.startDate).format(dateFormat);
        const endDate = moment.utc(result.endDate).format(dateFormat);

        return `${result.id} - ${customerName} (${startDate} - ${endDate})`;
      }
    });
  }

  _addFlights(e) {
    e.preventDefault();
    if (this._canAddFlights()) {
      this.model.get('flights').addInitial();
    }
  }

  _toggleFlightsEnabler() {
    this.ui.flights_enabler_wrapper.toggle(
      !this._hasFlights() && this._areFlightsEditable()
    );
    this.ui.flights_enabler
      .toggleClass('disabled', !this._canAddFlights())
      .tooltip(
        this._canAddFlights()
          ? 'destroy'
          : { title: this._getFlightsDisabledText() }
      );
    this.ui.split.toggle(this._hasFlights() && this._areFlightsEditable());
    this.ui.monthly_flights.toggle(this._canAddFlights() === true);
  }

  _canAddFlights() {
    return this.model.canAddFlights() && this._areFlightsEditable();
  }

  _toggleFlights() {
    this.ui.flights.toggle(this._hasFlights());
  }

  _hasFlights() {
    return this.model.hasFlights();
  }

  /**
   * Returns whether this budget is used for search.
   * This form can be part of campaign form, if so, use its `isSearch` method
   * which looks at the campaign channel. Otherwise, use `this.isSearch` which
   * doesn't account for 'internal' budget type.
   */
  isSearch() {
    return this.options.campaign
      ? this.options.campaign.isSearch()
      : this.model.isSearch();
  }

  isFirstLook() {
    return this.model.isFirstLook();
  }

  _toggleSearchFields() {
    this.ui.non_search.toggle(!this.isSearch());
    this._toggleCampaignBudgetTypeOptions();
  }

  /**
   * Show/hide the budget type select options based on the campaign channel.
   * - search: 'search', 'internal'
   * - intent: 'bonus', 'internal'
   * - all other channel: all but 'search'
   */
  _toggleCampaignBudgetTypeOptions() {
    if (!this.options.campaign) {
      this._showBudgetTypes(Object.values(BUDGET_TYPES));
    } else if (this.options.campaign.isSearch()) {
      this._showBudgetTypes(SEARCH_BUDGET_TYPES);
    } else if (this.options.campaign.isIntent()) {
      this._showBudgetTypes(INTENT_BUDGET_TYPES);
    } else {
      this._showBudgetTypes(STANDARD_BUDGET_TYPES);
    }
  }

  _showBudgetTypes(budgetTypes) {
    this.ui.campaign_budget_type_wrapper
      .find('option')
      .show()
      .filter((_, el) => !budgetTypes.includes(el.value))
      .hide();
  }

  _isFormEditable() {
    if (!this.options.campaign || !this.model.has('campaigns')) {
      return true;
    }

    return this.model
      .get('campaigns')
      .every(campaign => campaign.id == this.options.campaign.get('id'));
  }

  /**
   * Return whether a given field is editable based on the model's state.
   */
  _isFieldEditable(field) {
    if (this.isSearch()) {
      return SEARCH_EDITABLE_FIELDS.includes(field);
    } else if (this.isFirstLook()) {
      return FIRST_LOOK_EDITABLE_FIELDS.includes(field);
    } else {
      return true;
    }
  }

  _areFlightsEditable() {
    return this.ui.flights_input.is(':enabled') && this._isFormEditable();
  }

  _splitBudget(e) {
    e.preventDefault();
    this.model.splitBudgetToFlights();
  }

  _generateMonthlyFlights(e) {
    e.preventDefault();
    this.model.createMonthlyFlights();
  }

  _toggleOverflowEnabler() {
    this.ui.flights_overflow.toggle(
      this._hasMultipleFlights() && !this.model.isFixed()
    );
    this.ui.flights_overflow_hidden_alert.toggle(
      this._hasMultipleFlights() && this.model.isFixed()
    );
  }

  _hasMultipleFlights() {
    return this.model.get('flights').length > 1;
  }

  _onBudgetTypeChange() {
    this._updateFlightsDisabledText();
    this._toggleOverflowEnabler();
  }

  _onFlightsUpdate() {
    this._toggleFlights();
    this._toggleOverflowEnabler();
  }

  _updateFlightsDisabledText() {
    this.ui.flights_enabler.attr(
      'data-original-title',
      this._getFlightsDisabledText()
    );
  }

  _getFlightsDisabledText() {
    return I18n.t(this.isSearch() ? 'search' : 'not_search', {
      scope: 'simple_form.flights.explain_disabled'
    });
  }

  _onDistributionModeChange() {
    this.ui.distribution_warning.toggleClass(
      'hidden',
      this.ui.distribution_mode.val() !== EVEN_DISTRIBUTION_MODE
    );
  }

  _togglePacingChartLink() {
    const isCampaignWithoutPacing =
      this.options.campaign &&
      (this.options.campaign.isSearch() || this.options.campaign.isIntent());
    this.ui.pacing_chart_link.toggle(!isCampaignWithoutPacing);
  }
}

Form.initClass();

export default Form;
