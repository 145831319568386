import BudgetPlan from '../models/budget_plan';

class BudgetPlans extends Backbone.PageableCollection<BudgetPlan> {
  static initClass() {
    this.prototype.model = BudgetPlan;
    this.prototype.mode = 'client';
    this.prototype.state = { pageSize: 15 };
  }
}
BudgetPlans.initClass();

export default BudgetPlans;
